import React from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';
import ViewUserComponent from '../../components/users/ViewUserComponent';
import { useSingleCRMUser } from '../../shared/hooks/UseCRMUser';
import { useParams } from 'react-router-dom';

function ViewUser() {
  const params = useParams();
  const { data } = useSingleCRMUser(params.id);
  const { crmuser, crmuser_loading } = data;
  return (
    <div>
      <div>
        <Header />
        <AuthBreadCrum title={crmuser && crmuser.name} />

        {/* page-section */}

        <section className="  personal-information ptb-20 ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 mx-auto">
                {crmuser && <ViewUserComponent crmuser={crmuser} />}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ViewUser;
