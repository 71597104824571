import { Form, Formik } from 'formik/dist';
import React from 'react';
import * as yup from 'yup';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../Form/Form';
export const Form1 = () => {
  return (
    <div>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='logo-main'>
              <img src='/assets/images/logo-main.jpg' alt='logo' />
            </div>
          </div>
          <div className='col-md-12'>
            <div className='main-title text-center mb-3'>
              <h5 className='fw-bold'>Employee Joining Kit</h5>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='form-box border border-dark border-1 '>
              <div className='heading border-bottom border-dark border-1 bg-dark-subtle p-2'>
                <h6>Personal Information </h6>
              </div>
              <div className='row p-2 mt-3'>
                <div className='col-md-9'>
                  <div className='first-form'>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Employee Name:</label>
                        <input
                          type='text'
                          name='name'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Employee Code:</label>
                        <input
                          type='text'
                          name='employee-code'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Job Location:</label>
                        <input
                          type='text'
                          name='job-location'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Date of Joining:</label>
                        <input
                          type='text'
                          name='joining-date'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Designation:</label>
                        <input
                          type='text'
                          name='designation'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                    <div className='col-md-12 mb-3'>
                      <div className='d-flex '>
                        <label htmlFor='name'>Mobile Number:</label>
                        <input
                          type='text'
                          name='mobile'
                          className='border-bottom border-dotted border-dark border-1 w-75'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='border border-2 border-dark h-75 me-5'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
