import React from 'react';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

function ExportComponent({ handleOnExport }) {
  const user_data = useCheckAuthenticated();
  const { user } = user_data;
  return (
    <>
      <button className='btn btn-generate' onClick={handleOnExport}>
        <i className='fa fa-download'></i>
        Export Data
      </button>
    </>
  );
}

export default ExportComponent;
