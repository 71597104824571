import { Formik, Form } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  Form1,
  Form2,
  Form3,
  Form4,
  Form5,
  Form6,
  Form7,
  Form8,
  Form9,
  Form10,
  Form11,
  Form12,
  Form13,
  Form14,
  Form15,
  Form16,
  Form17,
  Form18,
} from '../../components/employee-form';

export const MainEmployeeForm = () => {
  return (
    <div>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          resetForm();
          setSubmitting(false);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className='container'>
                <div className='row'>
                  <Form1 />
                  <Form2 />
                  <Form3 />
                  <Form4 />
                  <Form5 />
                  <Form6 />
                  <Form7 />
                  <Form8 />
                  <Form9 />
                  <Form10 />
                  <Form11 />
                  <Form12 />
                  <Form13 />
                  <Form14 />
                  <Form15 />
                  <Form16 />
                  <Form17 />
                  <Form18 />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
