import React from 'react';
import { Link } from 'react-router-dom';

function ViewUserComponent({ crmuser }) {
  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-6">
          <div className="heading-single-education">
            <h2>Full Name</h2>
            <span>{crmuser.name}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="heading-single-education">
            <h2>Email</h2>
            <span>{crmuser.email}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="heading-single-education">
            <h2>Phone</h2>
            <span>{crmuser.phone}</span>
          </div>
        </div>
        <div className="save-button">
          <Link
            to={`/users/${crmuser._id}/edit`}
            className="btn primary-btn w-100"
          >
            Edit User
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ViewUserComponent;
