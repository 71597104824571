import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  PasswordInput,
  FileUpload,
} from '../../components/Form/Form';

import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddFormInput from '../../components/AddFormInput';
import TableFieldComponent from '../../components/table_field/TableFieldComponent';
import { useCreateBulk, useSaveBulk } from '../../shared/hooks/UseBulk';
import { TableErrorFiled } from '../../components/table_field/TableErrorFiled';
import AsyncSelect from 'react-select/async';
import { useGetAllClients } from '../../shared/hooks/UseClient';
export const Bulk = (props) => {
  const navigate = useNavigate();
  const { bulk_data, addBulkData } = useCreateBulk();
  const [updateBulkData] = useSaveBulk();
  const { getClients, clients } = useGetAllClients();

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <section className='main-table-form mt-3'>
        <div className='container-fluid'>
          <div className='row'>
            {(bulk_data && bulk_data.add_bulk_loading) ||
            bulk_data.edit_bulk_loading ? (
              <>
                <div className='col-md-12'>
                  <div className='text-center'>
                    <img
                      src='/loading.gif'
                      style={{ width: '100px', height: '100px' }}
                    />
                    <p>
                      Processing Please wait. It will take 1-2 mins to proceed
                      the data.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className='col-md-12'>
                <div>
                  <Formik
                    initialValues={{
                      file: '',
                      client: '',
                    }}
                    validationSchema={Yup.object({
                      file: Yup.string().required('Required'),
                      client: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      // loginUser(values);
                      addBulkData(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className='login-box'>
                            <div className='logo'>
                              <Link to='/'>
                                <img src='/assets/images/logo.jpg' />
                              </Link>
                            </div>
                            {/* <h3>Log In</h3> */}
                            <p>Please select the file </p>
                            <FileUpload
                              item='file'
                              formik={formik}
                              value={formik.values.file}
                              hideView={true}
                            />
                            <div>
                              <label> Client </label>
                              <AsyncSelect
                                defaultOptions={
                                  clients &&
                                  clients.clients &&
                                  clients.clients.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item._id,
                                    };
                                  })
                                }
                                onChange={(e) => {
                                  if (e) {
                                    formik.setFieldValue('client', e.value);
                                  }
                                }}
                              />
                              {/* <select
                           className="form-control"
                           onChange={(e) => {
                             handleSidebarChange({
                               name: item.field,
                               value: e.target.value,
                               type: "exact",
                               condition: item.condition,
                             });
                           }}
                           value={
                             getSidebarValue(item) ? getSidebarValue(item) : ""
                           }
                         >
                           <option value=""></option>
 
                           {dropdown_options &&
                             dropdown_options[item.field] &&
                             dropdown_options[item.field].map((option) => {
                               return (
                                 <option value={option.value}>
                                   {option.label}
                                 </option>
                               );
                             })}
                         </select> */}
                            </div>

                            <div className='create-button'>
                              <button
                                className='btn primary-btn w-100'
                                type='submit'
                              >
                                Import
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>

                {/* <p> {total_students} Records Found </p> */}
                {bulk_data &&
                  bulk_data.bulk_message &&
                  bulk_data.bulk_message.failed_data &&
                  bulk_data.bulk_message.failed_data.length > 0 && (
                    <div className='myapplication-table'>
                      <h3> Errors </h3>
                      <table className='table table-responsive table-sm  table-bordered table-striped  '>
                        <thead>
                          <tr>
                            <th scope='col '>Employee Number</th>
                            <th scope='col'>Employee Name</th>
                            <th scope='col'>Joining Date</th>
                            <th scope='col'>Date Of Birth</th>
                            <th scope='col'>Gender</th>
                            <th scope='col'>PAN Number</th>
                            <th scope='col'>Marital Status</th>
                            <th scope='col'>Blood Group</th>
                            <th scope='col'>Father's Name</th>
                            <th scope='col'>Emergency Contact Name</th>
                            <th scope='col'>Emergency Contact Number</th>
                            <th scope='col'>Bank Account Number</th>
                            <th scope='col'>IFSC Code</th>
                            <th scope='col'>Bank Name</th>
                            <th scope='col'>Salary Payment Mode</th>
                            <th scope='col'>Name (As on Aadhaar Card)</th>
                            <th scope='col'>Aadhaar Card Number </th>
                            <th scope='col'>Mobile Number</th>
                            <th scope='col'>Designation</th>
                            <th scope='col'>Location</th>
                            <th scope='col'>Client Name</th>
                            <th scope='col'>State</th>
                            <th scope='col'>District</th>
                            <th scope='col'>E Clinic Code</th>
                            <th scope='col'>Partner Name</th>
                            <th scope='col'>Job Location</th>
                            <th scope='col'>Field Ops Manager Name</th>
                            <th scope='col'>Field Ops Manager Contact No</th>
                            <th scope='col'>Field Ops Manager Email ID</th>
                            <th scope='col'>Branch Name</th>
                            <th scope='col'>Emp CSD</th>
                            <th scope='col'>Emp CED</th>
                            <th scope='col'>Reporting Head</th>
                            <th scope='col'>Reporting Head Email ID</th>
                            <th scope='col'>Client Old DOJ</th>
                            <th scope='col'>Communication Email</th>
                            <th scope='col'>Client Date Of Joining</th>
                            <th scope='col'>Zone</th>
                            <th scope='col'>Aadhar Link Mobile</th>
                            <th scope='col'>Status (PAN & AADHAR) Linked</th>
                            <th scope='col'>Created By</th>
                            <th scope='col'>Updated By</th>
                            <th scope='col'>PF Number</th>
                            <th scope='col'>Sourced By</th>
                            <th scope='col'>ESI Number</th>
                            <th scope='col'> Full Basic</th>
                            <th scope='col'> Full HRA </th>
                            <th scope='col'> Full Conveyance </th>
                            <th scope='col'> Full Consolidate Fee </th>
                            <th scope='col'> Full Statutory Bonus </th>
                            <th scope='col'>Full CCA </th>
                            <th scope='col'>Full Work Allowance </th>
                            <th scope='col'>Full Other Allowance </th>
                            <th scope='col'>Full Medical Reimbursement </th>
                            <th scope='col'>Full Leave Travel Allowance </th>
                            <th scope='col'>Full Mobile Allowance </th>
                            <th scope='col'>Full Child Education Allowance </th>
                            <th scope='col'>Employer Insurance</th>
                            <th scope='col'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bulk_data.bulk_message.failed_data.map((item) => {
                            return (
                              <tr key='01'>
                                <td>
                                  {/* {item.employee_number}{' '} */}
                                  <TableErrorFiled
                                    value={item.employee_name}
                                    field={'employee_name'}
                                    errors={item.errors}
                                  />
                                </td>

                                <td>
                                  <TableErrorFiled
                                    value={item.employee_number}
                                    field={'employee_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.joining_date}
                                    field={'joining_date'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.date_of_birth}
                                    field={'date_of_birth'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.gender}
                                    field={'gender'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.pan_number}
                                    field={'pan_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.marital_status}
                                    field={'marital_status'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td class='status-ic'>
                                  <TableErrorFiled
                                    value={item.blood_group}
                                    field={'blood_group'}
                                    errors={item.errors}
                                  />
                                  {/* <a class="badge badge inclass">{value}</a> */}
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.father_name}
                                    field={'father_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.emergency_contact_name}
                                    field={'emergency_contact_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.emergency_contact_number}
                                    field={'emergency_contact_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.bank_account_number}
                                    field={'bank_account_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.ifsc_code}
                                    field={'ifsc_code'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.bank_name}
                                    field={'bank_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.salary_payment_mode}
                                    field={'salary_payment_mode'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.name_as_on_aadhaar_card}
                                    field={'name_as_on_aadhaar_card'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.aadhaar_card_number}
                                    field={'aadhaar_card_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.mobile_number}
                                    field={'mobile_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.designation}
                                    field={'designation'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.location}
                                    field={'location'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.client_name}
                                    field={'client_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.state}
                                    field={'state'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.district}
                                    field={'district'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.e_clinic_code}
                                    field={'e_clinic_code'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.partner_name}
                                    field={'partner_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.job_location}
                                    field={'job_location'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.field_ops_manager_name}
                                    field={'field_ops_manager_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.field_ops_manager_contact_no}
                                    field={'field_ops_manager_contact_no'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.field_ops_manager_email_id}
                                    field={'field_ops_manager_email_id'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.branch_name}
                                    field={'branch_name'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.emp_csd}
                                    field={'emp_csd'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.emp_ced}
                                    field={'emp_ced'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.reporting_head}
                                    field={'reporting_head'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.reporting_head_email_id}
                                    field={'reporting_head_email_id'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.client_old_doj}
                                    field={'client_old_doj'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.communication_email}
                                    field={'communication_email'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.client_date_of_joining}
                                    field={'client_date_of_joining'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.zone}
                                    field={'zone'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  {' '}
                                  <TableErrorFiled
                                    value={item.aadhar_link_mobile}
                                    field={'aadhar_link_mobile'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.status_pan_and_aadhar}
                                    field={'status_pan_and_aadhar'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.created_by}
                                    field={'created_by'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.updated_by}
                                    field={'updated_by'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.pf_number}
                                    field={'pf_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.sourced_by}
                                    field={'sourced_by'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.esi_number}
                                    field={'esi_number'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_basic}
                                    field={'full_basic'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_hra}
                                    field={' full_hra'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_conveyance}
                                    field={' full_conveyance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_consolidated_fee}
                                    field={'full_consolidated_fee'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_cca}
                                    field={'full_cca'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_work_allowance}
                                    field={'full_work_allowance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_other_allowance}
                                    field={'full_other_allowance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_medical_reimbursement}
                                    field={'full_medical_reimbursement'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_leave_travel_allowance}
                                    field={'full_leave_travel_allowance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_mobile_allowance}
                                    field={'full_mobile_allowance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.full_child_eduction_allowance}
                                    field={'full_child_eduction_allowance'}
                                    errors={item.errors}
                                  />
                                </td>
                                <td>
                                  <TableErrorFiled
                                    value={item.employer_insurance}
                                    field={'employer_insurance'}
                                    errors={item.errors}
                                  />
                                </td>

                                <td className='status-ic'>
                                  <Link to='#' className='badge badge-view'>
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td colSpan={10}>No Data Found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                {bulk_data &&
                  bulk_data.bulk_message &&
                  bulk_data.bulk_message.failed_data &&
                  bulk_data.bulk_message.failed_data.length === 0 && (
                    <div>
                      <h3> Congratulations </h3>
                      <div>
                        All Data is ready to upload. click on the link below to
                        upload all the data
                      </div>
                      <div>
                        <div className='create-button'>
                          <button
                            onClick={() =>
                              updateBulkData(bulk_data.bulk_message.file._id)
                            }
                            className='btn primary-btn w-100'
                            type='submit'
                          >
                            Save All Changes
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
