import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addEmployee,
  getEmployees,
  getEmployee,
  editEmployee,
  deleteEmployee,
  getAllEmployees,
  deleteBulkEmployee,
  onboardEmployee,
  changeEmployeeDocumentStatus,
  onboardEmployeeSingleField,
} from '../../store/actions/employee_action';
import _debounce from 'lodash/debounce';
// import { useSelectAllIndustry } from "./UseIndustry";
import { useLocation } from 'react-router-dom';
import { api } from '../../domain/api';
import axios from 'axios';
// Get All Data
export const useAllEmployees = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employee);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    allQuery();
  }, [location]);
  const allQuery = useCallback(
    _debounce(() => {
      dispatch(getEmployees({}));
    }, 1000),
    []
  );

  return { data, setPageNumber };
};

export const useAllEmployeesReport = () => {
  const [loading_report, setLoading_report] = useState(false);
  const [reportData, setReportData] = useState(null);
  const getData = async () => {
    setLoading_report(true);
    const { data } = await api.get('/employees/bulk-data');
    setReportData(data);
    setLoading_report(false);
  };
  return {
    reportData,
    getData,
    loading_report,
  };
};

// Get Single Data
export const useSingleEmployee = (id) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(getEmployee(id));
  }, [id]);
  return [data];
};
// Add Data
export const useCreateEmployee = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.employee);
  const addData = async (data) => {
    await dispatch(addEmployee(data));
  };
  return { data, addData };
};
export const useUpdateEmployee = () => {
  const dispatch = useDispatch();

  // const data = useSelector((state) => state.employee);
  const updateData = async (id, data) => {
    await dispatch(editEmployee(id, data));
  };
  return [updateData];
};
export const useOnboardEmployee = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const updateData = async (id, data) => {
    await dispatch(onboardEmployee(id, data));
  };
  return [updateData];
};
// export const useOnboardEmployeeSinlgeField = () => {
//   const dispatch = useDispatch();
//   // const data = useSelector((state) => state.employee);
//   const updateDataSingleField = async (id, data) => {
//     await dispatch(onboardEmployeeSingleField(id, data));
//   };
//   return [updateDataSingleField];
// };
export const useUpdateSingleFieldEmployee = () => {
  const dispatch = useDispatch();
  const updateSingleFieldEmployee = async (id, formData) => {
    try {
      const res = await api.patch(
        `/employees/${id}/onboardingSingle`,
        formData
      );
      dispatch(getEmployee(id));
    } catch (error) {
      console.log(error, 'error');
    }
  };
  return { updateSingleFieldEmployee };
};
export const useChangeEmployeeStatus = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const updateData = async (id, data) => {
    await dispatch(changeEmployeeDocumentStatus(id, data));
  };
  return [updateData];
};
export const useDeleteEmployee = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const deleteData = async (id) => {
    await dispatch(deleteEmployee(id));
  };
  return { deleteData };
};
export const useDeleteBulkEmployee = () => {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.employee);
  const deleteBulkData = async (data) => {
    await dispatch(deleteBulkEmployee(data));
  };
  return { deleteBulkData };
};

export const useSelectAllEmployee = () => {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [value, setValue] = useState('');
  const data = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(getAllEmployees({ term, value }));
  }, [term, value]);
  return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
  //  const [client, setClientSearchField, setClientSearchValue] =
  // useSelectAllClient();

  const [dropdownOptions, setDropdownOptions] = useState({});
  // useEffect(() => {
  //   if (industry && industry.all_industrys) {
  //     const newData = industry.all_industrys.map((item) => {
  //       return { label: item.name, value: item.name };
  //     });
  //     setDropdownOptions({ ...dropdownOptions, industry: newData });
  //   }
  // }, [industry]);
  const loadOptions = async (inputValue, callback, field) => {
    // if (field == "parent_category") {
    //   await setCategorySearchField("name");
    //   await setCategorySearchValue(inputValue);
    //   callback(dropdownOptions.parent_category);
    // }
  };

  return [dropdownOptions, loadOptions];
};
