import React from 'react';

export const Form13 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex justify-content-end '>
              <h3 style={{ fontWeight: 'bold' }}>Form No. 11 (New)</h3>
            </div>
            <div className='d-flex justify-content-end '>
              <h3 style={{ fontWeight: 'bold' }}>Declaration Form</h3>
            </div>
            <div className='d-flex justify-content-end '>
              <p>(To be retained by the Employer for future reference)</p>
            </div>
          </div>
          <div className='col-md-2'>
            <div className='d-flex justify-content-center'>
              <img
                src='/assets/images/epfo-logo.png'
                alt=''
                style={{ height: '150px ', width: 'auto' }}
              />
            </div>
          </div>
          <div className='col-md-10 text-center'>
            <div>
              <h2 style={{ fontWeight: 'bold' }}>
                Employees’ Provident Fund Organization
              </h2>
            </div>
            <div>
              <h5 style={{ fontWeight: 'bold' }}>
                THE EMPLOYEES’ PROVIDENT FUNDS SCHEME, 1952 (PARAGRAPH-34 & 57){' '}
              </h5>
            </div>
            <div>
              <h5 style={{ fontWeight: 'bold' }}>&</h5>
            </div>
            <div>
              <h5 style={{ fontWeight: 'bold' }}>
                {' '}
                THE EMPLOYEES’ PENSION SCHEME, 1995 (PARAGRAPH-24)
              </h5>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div className=' text-center'>
              <h6 className=''>
                <u>
                  DECLARATION BY A PERSON TAKING UP EMPLOYMENT IN AN
                  ESTABLISHMENT ON WHICH EMPLOYEES’ PROVIDENT FUND SCHEME, 1952
                  AND/OR EMPLOYEES’ PENSION SCHEME, 1995 IS APPLICABLE.
                </u>
              </h6>
              <h5 className='mt-2'>(PLEASE GO THROUGH THE INSTRUCTIONS)</h5>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-2'>
            <h6>1) NAME</h6>
          </div>
          <div className='col-md-2'>
            <h6>(TITLE)</h6>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th className='border border-dark'>MR.</th>
                    <th className='border border-dark'>MS.</th>
                    <th className='border border-dark'>MRS.</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h6>(Please Tick)</h6>
            </div>
          </div>
          <div className='col-md-8'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-3'>
            <h6>2) DATE OF BIRTH</h6>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-3'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-3'>
            <h6> 3) FATHER’S/ HUSBAND’S NAME</h6>
          </div>
          <div className='col-md-1'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th className='border border-dark text-center'>MR.</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-8'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-4'>
            <h6>4) RELATIONSHIP IN RESPECT OF (3) ABOVE (PLEASE TICK)</h6>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-4'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark w-50'
                      style={{ width: '20px', height: '30px' }}
                    >
                      FATHER
                    </th>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <th
                      className='border border-dark w-50'
                      style={{ width: '20px', height: '30px' }}
                    >
                      Husband
                    </th>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-3'>
            <h6>
              5) GENDER <br />
              (PLEASE TICK)
            </h6>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-4'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th className='border border-dark text-center '>MALE</th>
                    <th className='border border-dark text-center'>FEMALE</th>
                    <th className='border border-dark text-center'>
                      TRANSGENDER
                    </th>
                  </tr>

                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-2'>
            <h6>
              6) MOBILE NUMBER <br />
              (IF ANY)
            </h6>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-5'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '50px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-2'>
            <h6>7) EMAIL ID (IF ANY)</h6>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-8'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '20px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <h6>
              8) WHETHER EARLIER A MEMBER OF THE EMPLOYEES’ PROVIDENT FUND
              SCHEME, 1952?
            </h6>
          </div>
          <div className='col-md-3'></div>
          <div className='col-md-2 mt-2'>
            <h6>(PLEASE TICK)</h6>
          </div>
          <div className='col-md-5 mt-2'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '20px', height: '30px' }}
                    >
                      {' '}
                      Yes
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '20px', height: '30px' }}
                    >
                      No
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <h6>
              9) WHETHER EARLIER A MEMBER OF THE EMPLOYEES’ PENSION SCHEME,
              1995?
            </h6>
          </div>
          <div className='col-md-3'></div>
          <div className='col-md-2 mt-2'>
            <h6>(PLEASE TICK)</h6>
          </div>
          <div className='col-md-5 mt-2'>
            <div>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '20px', height: '30px' }}
                    >
                      {' '}
                      Yes
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '20px', height: '30px' }}
                    >
                      No
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-12'>
            <p className='fw-bold text-dark text-center mt-3'>
              IF RESPONSE TO ANY OR BOTH OF (8) & (9) ABOVE IS YES, THEN
              MANDATORILY FILL UP THE PREVIOUS EMPLOYMENT DETAILS AT (10,11&12):
            </p>
          </div>
          <div className='col-md-12'>
            <div className='d-flex justify-content-center'>
              <p className='text-dark'>
                Page <span className='fw-bold text-dark'>1</span> of{' '}
                <span className='fw-bold text-dark'>3</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
