import React from 'react';

export const Form7 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center text-center mb-3'>
              <h5 className=''>Part B (EPS) (Para 18)</h5>
            </div>
            <div>
              <p>
                I hereby furnish below particulars of the members of my family
                who would be eligible to receive widow/children pension in the
                event of my death.
              </p>
            </div>
            <div>
              <table className='table mt-3 border-top border-bottom border-dark'>
                <thead className=''>
                  <tr className=''>
                    <th scope='col' className=''>
                      S.No.
                    </th>
                    <th scope='col' className=''>
                      Name of the family member
                    </th>
                    <th scope='col' className=''>
                      Address
                    </th>
                    <th scope='col' className=''>
                      Date of Birth
                    </th>
                    <th scope='col' className=''>
                      Relationship with the member
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=''>1</td>
                    <td className=''>2</td>
                    <td className=''>3</td>
                    <td className=''>4</td>
                    <td className=''>5</td>
                  </tr>
                  <tr>
                    <td className=''>1</td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='address' />
                    </td>
                    <td className=''>
                      <input type='text' name='dob' />
                    </td>
                    <td className=''>
                      <input type='text' name='relation' />
                    </td>
                  </tr>
                  <tr>
                    <td className=''>2</td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='address' />
                    </td>
                    <td className=''>
                      <input type='text' name='dob' />
                    </td>
                    <td className=''>
                      <input type='text' name='relation' />
                    </td>
                  </tr>
                  <tr>
                    <td className=''>3</td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='address' />
                    </td>
                    <td className=''>
                      <input type='text' name='dob' />
                    </td>
                    <td className=''>
                      <input type='text' name='relation' />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='mt-3'>
              <ul className='list-group-numbered'>
                <li class='list-group-item'>
                  ** Certified that I have no family, as defined in para 2(vii)
                  of Employees’ Pension Scheme, 1995 and should I acquire a
                  family hereafter I shall furnish particulars thereon in the
                  above form.
                </li>
                <li class='list-group-item'>
                  I hereby nominate the following person for receiving the
                  monthly widow pension (admissible under para 16 2(a)(i) and
                  (ii) in the event of my death without leaving any eligible
                  family member for receiving Pension.
                </li>
              </ul>
            </div>
            <div>
              <table className='table mt-3 border-top border-bottom border-dark'>
                <thead className=''>
                  <tr className=''>
                    <th scope='col' className=''>
                      Name and Address of the Nominee
                    </th>
                    <th scope='col' className=''>
                      Date of Birth
                    </th>
                    <th scope='col' className=''>
                      Relationship with the member
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=''>1</td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                  </tr>
                  <tr>
                    <td className=''>2</td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='family-name' />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='mt-5 d-flex justify-content-between'>
              <p>
                Date: <input type='text' name='date' />
              </p>
              <h6>Signature or thumb impression of the subscriber </h6>
            </div>
            <div>
              <p>**Strike out whichever is not applicable.</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center text-center mb-3'>
              <h5 className=''>CERTIFICATE BY EMPLOYER</h5>
            </div>
            <div>
              <p>
                Certified that the above declaration and nomination has been
                signed/thumb impressed before me by Shri/Smt./Kum.{' '}
                <input type='text' className='border-bottom border-dark' />
                employed in my establishment after he/she has read the
                entries/entries have been read over to him/her by me and got
                confirmed by him/her.
              </p>
            </div>

            <div className='mt-5 d-flex justify-content-between'>
              <p>
                Place:
                <input
                  type='text'
                  name='place'
                  className='border-bottom border-dark'
                />
              </p>
              <h6>Signature or thumb impression of the subscriber </h6>
            </div>
            <div className='mt-3 d-flex justify-content-between'>
              <p>
                Dated the:
                <input
                  type='text'
                  name='place'
                  className='border-bottom border-dark'
                />
              </p>
              <h6>
                Designation:
                <input type='text' name='place' />
              </h6>
            </div>
            <div className='mt-3 d-flex justify-content-end border-bottom border-dark pb-5'>
              <h6 className='w-25'>
                Name & Address of the Factory/ Establishment or Rubber Stamp
                Thereon
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
