import React, { useEffect } from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';

import { Alerts } from '../../components/common/Alerts';
import { EditClientComponent } from '../../components/clients/EditClientComponent';
import { useSingleClient } from '../../shared/hooks/UseClient';
import { useParams } from 'react-router-dom';
import { currentUser } from '../../store/actions/auth_action';

function EditClient() {
  const params = useParams();
  const { getSingleClient, client, loading } = useSingleClient();
  
  useEffect(() => {
    if (params.id) {
      getSingleClient(params.id);
    }
  }, [params.id]);
 
  return (
    <div>
      <Header />
      <AuthBreadCrum title={'Edit Client'} />
      {/* page-section */}
      <section className='  personal-information ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              {client && (
                <EditClientComponent client={client} loading={loading} />
              )}
            </div>
          </div>
        </div>
      </section>
      <Alerts />
    </div>
  );
}

export default EditClient;
