import React from 'react';

export const Form16 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='d-flex justify-content-center flex-column align-items-center'>
              <h2>Payment of Gratuity (Central) Rules </h2>
              <h2>FORM 'F'</h2>
              <p>See sub-rule (1) of Rule 6</p>
              <p className='fw-bold text-dark'>Nomination</p>
            </div>
          </div>
          <div className='col-md-12'>
            <p className='mb-1'>To,</p>
            <p className=''>
              (Give here name or description of the establishment with full
              address)
            </p>
            <div
              style={{ borderBottom: '1px solid #000', width: '100%' }}
              className=''
            ></div>
            <div
              style={{ borderBottom: '1px solid #000', width: '100%' }}
              className='mt-5'
            ></div>
            <div className='d-flex mt-4'>
              <p className='mb-0'>I, Shri/Shrimati/Kumari </p>
              <div
                style={{ borderBottom: '1px solid #000', width: '80%' }}
                className=''
              ></div>
            </div>
            <div className='d-flex justify-content-center'>
              <p>(Name in full here)</p>
            </div>
            <p className='mb-1'>
              whose particulars are given in the statement below, hereby
              nominate the person(s) mentioned below to receive the gratuity
              payable after my death as also the gratuity standing to my credit
              in the event of my death before that amount has become payable, or
              having become payable has not been paid and direct that the said
              amount of gratuity shall be paid in proportion indicated against
              the name(s) of the nominee(s).
            </p>
            <p className='mb-1'>
              2. I hereby certify that the person(s) mentioned is/are a
              member(s) of my family within the meaning of clause (h) of Section
              2 of the Payment of Gratuity Act, 1972.
            </p>
            <p className='mb-3'>
              3. I hereby declare that I have no family within the meaning of
              clause (h) of Section 2 of the said Act.
            </p>
            <p className='mb-1'>
              4 (a) My father/mother/parents is/are not dependent on me.
            </p>
            <p className='ms-3 mb-1'>
              (b) My husband&#39;s father/mother/parents is/are not dependent on
              my husband.
            </p>
            <p className='mb-1'>
              5. I have excluded my husband from my family by a notice dated the
              …………………………………………... to the controlling authority in terms of the
              proviso to clause (h) of Section 2 of the said Act.
            </p>
            <p>6. Nomination made herein invalidates my previous nomination.</p>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <div className='d-flex justify-content-center mb-3'>
              <h5>Nominee(s)</h5>
            </div>
            <div className='mb-5'>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th className='border border-dark text-center' colSpan={2}>
                      Name in full with full address of nominee(s)
                    </th>
                    <th className='border border-dark text-center'>
                      Relationship with the employee
                    </th>
                    <th className='border border-dark text-center'>
                      Age of nominee
                    </th>
                    <th className='border border-dark text-center'>
                      Proportion by which the gratuity will be shared
                    </th>
                  </tr>
                  <tr>
                    <td className='border border-dark text-center' colSpan={2}>
                      (1){' '}
                    </td>

                    <td className='border border-dark text-center'> (2)</td>
                    <td className='border border-dark text-center'> (3)</td>
                    <td className='border border-dark text-center'> (4)</td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '20px' }}
                    >
                      1.
                    </td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <td className='border border-dark text-center'>2.</td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <td className='border border-dark text-center'>3.</td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <td className='border border-dark text-center'>So on.</td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ border: '2px solid #000' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
