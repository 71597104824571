import React from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';

import { Alerts } from '../../components/common/Alerts';
import AddEmployeeComponent from '../../components/employee/AddEmployeeComponent';

function AddEmployee() {
  return (
    <div>
      <Header />
      <AuthBreadCrum title={'Add Employee'} />
      {/* page-section */}
      <section className="  personal-information ptb-20 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <AddEmployeeComponent />
            </div>
          </div>
        </div>
      </section>
      <Alerts />
    </div>
  );
}

export default AddEmployee;
