import React from 'react';
import { TextInput } from '../Form/Form';

export const Form18 = ({ formik }) => {
  return (
    <div>
      <div style={{ border: '2px solid #000' }}></div>
      <div
        className='text-center m-5'
        style={{ fontSize: '22px', fontWeight: 'bold', color: '#000' }}
      >
        Acknowledgement by the Employee
      </div>
      <div>
        <p>
          Received the duplicate copy of nomination in Form &#39;F&#39; filed by
          me and duly certified by the employer.
        </p>
      </div>
      <div className='d-flex justify-content-between mt-3 mb-3'>
        <div>
          <div>
            Date: <input type='date' className='border-bottom' />
          </div>
        </div>
        <div>Signature of the Employee</div>
      </div>
      <div style={{ border: '2px solid #000' }}></div>
      <div>
        <p>
          <strong>Note.—</strong> Strike out the words/paragraphs not
          applicable.{' '}
        </p>
      </div>
    </div>
  );
};
