import React from "react";

import { Formik, Form } from "formik";
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from "../Form/Form";
import * as Yup from "yup";
import { EmployeeFormComponent } from "./EmployeeFormComponent";
import {
  useUpdateEmployee,
  useSingleEmployee,
} from "../../shared/hooks/UseEmployee";
import { useParams } from "react-router-dom";
function EditEmployeeComponent() {
  const params = useParams();
  const [data] = useSingleEmployee(params.id);
  const [updateData] = useUpdateEmployee();
  const { employee } = data;
  return (
    <div>
      <div className="personal-information-box clearfix">
        <div className="main-sec-heading">
          <h1>Edit Employee</h1>
        </div>
        {employee && (
          <Formik
            initialValues={{
              employee_title:
                employee && employee.employee_title
                  ? employee.employee_title
                  : "",
              employee_name:
                employee && employee.employee_name
                  ? employee.employee_name
                  : "",
              employee_number:
                employee && employee.employee_number
                  ? employee.employee_number
                  : "",
              joining_date:
                employee && employee.joining_date ? employee.joining_date : "",
              date_of_birth:
                employee && employee.date_of_birth
                  ? employee.date_of_birth
                  : "",
              gender: employee && employee.gender ? employee.gender : "",
              pan_number:
                employee && employee.pan_number ? employee.pan_number : "",
              marital_status:
                employee && employee.marital_status
                  ? employee.marital_status
                  : "",
              blood_group:
                employee && employee.blood_group ? employee.blood_group : "",
              father_name:
                employee && employee.father_name ? employee.father_name : "",
              father_dob : employee && employee.father_dob ? employee.father_dob : "",
              mother_name:
                employee && employee.mother_name ? employee.mother_name : "",
              mother_dob:
                employee && employee.mother_dob ? employee.mother_dob : "",
              spouse_name:
                employee && employee.spouse_name ? employee.spouse_name : "",
              spouse_dob:
                employee && employee.spouse_dob ? employee.spouse_dob : "",
              child_1_name:
                employee && employee.child_1_name ? employee.child_1_name : "",
              child_1_dob:
                employee && employee.child_1_dob ? employee.child_1_dob : "",
              child_2_name:
                employee && employee.child_2_name ? employee.child_2_name : "",
              child_2_dob:
                employee && employee.child_2_dob ? employee.child_2_dob : "",
              reference_1_name:
                employee && employee.reference_1_name
                  ? employee.reference_1_name
                  : "",
              reference_1_employee_code:
                employee && employee.reference_1_employee_code
                  ? employee.reference_1_employee_code
                  : "",
              reference_1_designation:
                employee && employee.reference_1_designation
                  ? employee.reference_1_designation
                  : "",
              reference_1_contact_number:
                employee && employee.reference_1_contact_number
                  ? employee.reference_1_contact_number
                  : "",
              reference_2_name:
                employee && employee.reference_2_name
                  ? employee.reference_2_name
                  : "",
              reference_2_employee_code:
                employee && employee.reference_2_employee_code
                  ? employee.reference_2_employee_code
                  : "",
              reference_2_designation:
                employee && employee.reference_2_designation
                  ? employee.reference_2_designation
                  : "",
              reference_2_contact_number:
                employee && employee.reference_2_contact_number
                  ? employee.reference_2_contact_number
                  : "",
              nominee_name:
                employee && employee.nominee_name ? employee.nominee_name : "",
              nominee_relation:
                employee && employee.nominee_relation
                  ? employee.nominee_relation
                  : "",
              nominee_phone:
                employee && employee.nominee_phone
                  ? employee.nominee_phone
                  : "",
              nominee_address:
                employee && employee.nominee_address
                  ? employee.nominee_address
                  : "",

              emergency_contact_name:
                employee && employee.emergency_contact_name
                  ? employee.emergency_contact_name
                  : "",
              emergency_contact_number:
                employee && employee.emergency_contact_number
                  ? employee.emergency_contact_number
                  : "",
              bank_account_number:
                employee && employee.bank_account_number
                  ? employee.bank_account_number
                  : "",
              ifsc_code:
                employee && employee.ifsc_code ? employee.ifsc_code : "",
              bank_name:
                employee && employee.bank_name ? employee.bank_name : "",
              salary_payment_mode:
                employee && employee.salary_payment_mode
                  ? employee.salary_payment_mode
                  : "",
              employee_address:
                employee && employee.employee_address
                  ? employee.employee_address
                  : "",
              consolidated_salary:
                employee && employee.consolidated_salary
                  ? employee.consolidated_salary
                  : "",
              name_as_on_aadhaar_card:
                employee && employee.name_as_on_aadhaar_card
                  ? employee.name_as_on_aadhaar_card
                  : "",
              aadhaar_card_number:
                employee && employee.aadhaar_card_number
                  ? employee.aadhaar_card_number
                  : "",
              mobile_number:
                employee && employee.mobile_number
                  ? employee.mobile_number
                  : "",
              designation:
                employee && employee.designation ? employee.designation : "",
              location: employee && employee.location ? employee.location : "",
              client_name:
                employee && employee.client_name._id
                  ? employee.client_name._id
                  : "",
              state: employee && employee.state ? employee.state : "",
              district: employee && employee.district ? employee.district : "",
              e_clinic_code:
                employee && employee.e_clinic_code
                  ? employee.e_clinic_code
                  : "",
              partner_name:
                employee && employee.partner_name ? employee.partner_name : "",
              job_location:
                employee && employee.job_location ? employee.job_location : "",
              field_ops_manager_name:
                employee && employee.field_ops_manager_name
                  ? employee.field_ops_manager_name
                  : "",
              field_ops_manager_contact_no:
                employee && employee.field_ops_manager_contact_no
                  ? employee.field_ops_manager_contact_no
                  : "",
              field_ops_manager_email_id:
                employee && employee.field_ops_manager_email_id
                  ? employee.field_ops_manager_email_id
                  : "",
              branch_name:
                employee && employee.branch_name ? employee.branch_name : "",
              emp_csd: employee && employee.emp_csd ? employee.emp_csd : "",
              emp_ced: employee && employee.emp_ced ? employee.emp_ced : "",
              reporting_head:
                employee && employee.reporting_head
                  ? employee.reporting_head
                  : "",
              reporting_head_email_id:
                employee && employee.reporting_head_email_id
                  ? employee.reporting_head_email_id
                  : "",
              client_old_doj:
                employee && employee.client_old_doj
                  ? employee.client_old_doj
                  : "",
              communication_email:
                employee && employee.communication_email
                  ? employee.communication_email
                  : "",
              client_date_of_joining:
                employee && employee.client_date_of_joining
                  ? employee.client_date_of_joining
                  : "",
              insurance_no:
                employee && employee.insurance_no ? employee.insurance_no : "",
              aadhar_link_mobile:
                employee && employee.aadhar_link_mobile
                  ? employee.aadhar_link_mobile
                  : "",
              status_pan_and_aadhar:
                employee && employee.status_pan_and_aadhar
                  ? employee.status_pan_and_aadhar
                  : "",
                  full_cca : employee && employee.full_cca ? employee.full_cca : "",
              pf_number:
                employee && employee.pf_number ? employee.pf_number : "",
              sourced_by:
                employee && employee.sourced_by ? employee.sourced_by : "",
              esi_number:
                employee && employee.esi_number ? employee.esi_number : "",
              full_basic:
                employee && employee.full_basic ? employee.full_basic : "",
              full_hra: employee && employee.full_hra ? employee.full_hra : "",
              full_conveyance:
                employee && employee.full_conveyance
                  ? employee.full_conveyance
                  : "",
              full_consolidated_fee:
                employee && employee.full_consolidated_fee
                  ? employee.full_consolidated_fee
                  : "",
              full_statutory_bonus:
                employee && employee.full_statutory_bonus
                  ? employee.full_statutory_bonus
                  : "",
              full_work_allowance:
                employee && employee.full_work_allowance
                  ? employee.full_work_allowance
                  : "",
              full_other_allowance:
                employee && employee.full_other_allowance
                  ? employee.full_other_allowance
                  : "",
              full_medical_reimbursement:
                employee && employee.full_medical_reimbursement
                  ? employee.full_medical_reimbursement
                  : "",
              full_leave_travel_allowance:
                employee && employee.full_leave_travel_allowance
                  ? employee.full_leave_travel_allowance
                  : "",
              full_mobile_allowance:
                employee && employee.full_mobile_allowance
                  ? employee.full_mobile_allowance
                  : "",
              full_child_eduction_allowance:
                employee && employee.full_child_eduction_allowance
                  ? employee.full_child_eduction_allowance
                  : "",
              employer_insurance:
                employee && employee.employer_insurance
                  ? employee.employer_insurance
                  : "",

              employee_insurance:
                employee && employee.employee_insurance
                  ? employee.employee_insurance
                  : "",

              monthly_ctc:
                employee && employee.monthly_ctc ? employee.monthly_ctc : "",
              annual_ctc:
                employee && employee.annual_ctc ? employee.annual_ctc : "",
              special_allowance:
                employee && employee.special_allowance
                  ? employee.special_allowance
                  : "",

              gross_salary:
                employee && employee.gross_salary ? employee.gross_salary : "",

              in_hand_salary:
                employee && employee.in_hand_salary
                  ? employee.in_hand_salary
                  : "",

              employer_pf:
                employee && employee.employer_pf ? employee.employer_pf : "",
              employee_pf:
                employee && employee.employee_pf ? employee.employee_pf : "",
              employee_esic:
                employee && employee.employee_esic
                  ? employee.employee_esic
                  : "",

              employer_esic:
                employee && employee.employer_esic
                  ? employee.employer_esic
                  : "",

              lwf: employee && employee.lwf ? employee.lwf : "",
              pt: employee && employee.pt ? employee.pt : "",
              ta: employee && employee.ta ? employee.ta : "",
              da: employee && employee.da ? employee.da : "",
            }}
            validationSchema={Yup.object({
              employee_number: Yup.string().required("Required"),
              da: Yup.number()
                .default(0)
                .optional(employee && employee.da ? employee.da : 0),
              pt: Yup.number()
                .default(0)
                .optional(employee && employee.pt ? employee.pt : 0),
              employer_esic: Yup.number()
                .default(0)
                .optional(
                  employee && employee.employer_esic
                    ? employee.employer_esic
                    : 0
                ),
              ta: Yup.number()
                .default(0)
                .optional(employee && employee.ta ? employee.ta : 0),
              lwf: Yup.number()
                .default(0)
                .optional(employee && employee.lwf ? employee.lwf : 0),
              employee_esic: Yup.number()
                .default(0)
                .optional(
                  employee && employee.employee_esic
                    ? employee.employee_esic
                    : null
                ),
              employee_pf: Yup.number()
                .default(0)
                .optional(
                  employee && employee.employee_pf ? employee.employee_pf : 0
                ),
              employer_pf: Yup.number()
                .default(0)
                .optional(
                  employee && employee.employer_pf ? employee.employer_pf : 0
                ),
              in_hand_salary: Yup.number()
                .default(0)
                .optional(
                  employee && employee.in_hand_salary
                    ? employee.in_hand_salary
                    : 0
                ),
              gross_salary: Yup.number()
                .default(0)
                .optional(
                  employee && employee.gross_salary ? employee.gross_salary : 0
                ),
              special_allowance: Yup.number()
                .default(0)
                .optional(
                  employee && employee.special_allowance
                    ? employee.special_allowance
                    : 0
                ),
              annual_ctc: Yup.number()
                .default(0)
                .optional(
                  employee && employee.annual_ctc ? employee.annual_ctc : 0
                ),
              employee_insurance: Yup.number()
                .default(0)
                .optional(employee && !employee.employee_insurance && 0),
              employer_insurance: Yup.number()
                .default(0)
                .optional(employee && !employee.employer_insurance && 0),
              monthly_ctc: Yup.number()
                .default(0)
                .optional(employee && !employee.monthly_ctc && 0),
            })}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // values.email = values.email.toLowerCase();
              setSubmitting(true);
              await updateData(employee._id, values);
              // resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return <EmployeeFormComponent />;
            }}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default EditEmployeeComponent;
