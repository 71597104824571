import React from 'react';

export const Form4 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 '>
            <div className='heading '>
              <h6>AADHAAR DETAILS (BLOCK LETTERS):</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <tbody>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NAME OF AADHAAR CARD
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='aadhar-name' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    AADHAAR CARD NUMBER
                  </th>
                  <td className='border border-dark'>
                    <input type='number' name='aadhar-no' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='heading '>
              <h6>BANK ACCOUNT DETAILS:</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <thead>
                <tr className='bg-dark-subtle'>
                  <th scope='col' className='border border-dark'>
                    Name of the Bank
                  </th>
                  <th scope='col' className='border border-dark'>
                    Account Holder Name
                  </th>
                  <th scope='col' className='border border-dark'>
                    Account Number
                  </th>
                  <th scope='col' className='border border-dark'>
                    IFSC Code
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='bank-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='holder-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='account-number' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='ifsc' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='bank-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='holder-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='account-number' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='ifsc' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='heading text-center'>
              <h6>INSURANCE DETAILS FORM</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <tbody>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NAME (IN BLOCK LETTERS)
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='name' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    FATHER / HUSBAND NAME
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='husband-name' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    DATE OF BIRTH
                  </th>
                  <td className='border border-dark'>
                    <input type='number' name='dob' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    GENDER (MALE / FEMALE)
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='gender' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    MARITAL STATUS
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='marital' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NAME OF NOMINEE
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='nominee-name' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NOMINEE RELATIONSHIP WITH PERSON
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='nominee-relationship' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NOMINEE CONTACT NUMBER
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='nominee-contact' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    ADDRESS WITH PINCODE
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='address' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
