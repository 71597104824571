import React from 'react';
import { ClientForm } from './ClientForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useUpdateClient } from '../../shared/hooks/UseClient';
import { useHistory, useLocation, useNavigate } from 'react-router-dom';

export const EditClientComponent = ({ client }) => {
  console.log(client, 'client');
  const navigate = useNavigate();
  const { handleUpdateClient } = useUpdateClient();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Edit Client</h1>
        </div>
        <Formik
          initialValues={{
            name: client?.name || '',

            agreement_start_date: client?.agreement_start_date || '',
            agreement_end_date: client?.agreement_end_date || '',
            service_type: client?.service_type || '',
            document_name: client?.document_name || '',
            mark_up_for_additional_invoice:
              client?.mark_up_for_additional_invoice || '',
            agreement_attachment: client?.agreement_attachment || [],
            documents_attachment: client?.documents_attachment || [],
            agreement_signed_by: client?.agreement_signed_by || {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            onboarding_fields: {
              pan_number: client?.onboarding_fields?.pan_number || false,
              bank_account_number:
                client?.onboarding_fields?.bank_account_number || false,
              ifsc_code: client?.onboarding_fields?.ifsc_code || false,
              aadhaar_card_number:
                client?.onboarding_fields?.aadhaar_card_number || false,
              passport_size_photo:
                client?.onboarding_fields?.passport_size_photo || false,
              signed_appointment_letter:
                client?.onboarding_fields?.signed_appointment_letter || false,
              bank_passbook_or_cancelled_cheque:
                client?.onboarding_fields?.bank_passbook_or_cancelled_cheque ||
                false,
              pan_picture: client?.onboarding_fields?.pan_picture || false,
              adhar_card_front:
                client?.onboarding_fields?.adhar_card_front || false,
              adhar_card_back:
                client?.onboarding_fields?.adhar_card_back || false,
              signed_scanned_signature:
                client?.onboarding_fields?.signed_scanned_signature || false,
              driving_license:
                client?.onboarding_fields?.driving_license || false,
              tenth_certificate:
                client?.onboarding_fields?.tenth_certificate || false,
              twelfth_certificate:
                client?.onboarding_fields?.twelfth_certificate || false,
              graduation_certificate:
                client?.onboarding_fields?.graduation_certificate || false,
              post_graduation_certificate:
                client?.onboarding_fields?.post_graduation_certificate || false,
              previous_company_experience_letter:
                client?.onboarding_fields?.previous_company_experience_letter ||
                false,
              last_three_month_salary_slip:
                client?.onboarding_fields?.last_three_month_salary_slip ||
                false,
              other_client_specific_form_upload:
                client?.onboarding_fields?.other_client_specific_form_upload ||
                false,
              other_client_specific_form_upload_2:
                client?.onboarding_fields
                  ?.other_client_specific_form_upload_2 || false,
            },
            poc_1: client?.poc_1 || {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            poc_2: client?.poc_2 || {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            poc_3: client?.poc_3 || {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            staffing_mark_up: client?.staffing_mark_up || '',
            one_time_setup_fee: client?.one_time_setup_fee || '',
            id_cards: client?.id_cards || '',
            insurance: client?.insurance || '',
            reimbursement_fee: client?.reimbursement_fee || '',
            temp_sourcing_markup: client?.temp_sourcing_markup || '',
            temp_replacement_period: client?.temp_replacement_period || '',
            temp_payment_terms: client?.temp_payment_terms || '',
            absorption_fee: client?.absorption_fee || '',
            perm_sourcing_markup: client?.perm_sourcing_markup || '',
            perm_replacement_period: client?.perm_replacement_period || '',
            perm_payment_terms: client?.perm_payment_terms || '',
            bgv_crc: client?.bgv_crc || '',
            bgv_employment: client?.bgv_employment || '',
            bgv_address: client?.bgv_address || '',
            attendance_cycle: client?.attendance_cycle || '',
            payout_cycle: client?.payout_cycle || '',
            inputs_from_client: client?.inputs_from_client || '',
            draft_invoice_by_teamup_hr:
              client?.draft_invoice_by_teamup_hr || '',
            draft_invoice_approval_by_client:
              client?.draft_invoice_approval_by_client || '',
            payment_from_client: client?.payment_from_client || '',
            salary_release: client?.salary_release || '',
            collect_pay_or_funding: client?.collect_pay_or_funding || '',
            notice_period_client: client?.notice_period_client || '',
            notice_period_employee: client?.notice_period_employee || '',
            associate_contract_duration:
              client?.associate_contract_duration || '',
            bgv_fee: client?.bgv_fee || '',
            other_1: client?.other_1 || '',
            other_2: client?.other_2 || '',
            other_3: client?.other_3 || '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values, 'values');
            // values.email = values.email.toLowerCase();
            setSubmitting(true);
            await handleUpdateClient(client._id, values);
            navigate('/clients');
            // resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <ClientForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
};
