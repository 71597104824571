import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import {
  useSingleEmployee,
  useChangeEmployeeStatus,
  useUpdateSingleFieldEmployee,
} from '../../shared/hooks/UseEmployee';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { URI } from '../../domain/constant';
import moment from 'moment';
import { Edit } from 'lucide-react';

const ActionButtons = ({ employee, handleSubmitData, field }) => {
  return (
    <div className='col-md-4 '>
      {employee && employee[field] && (
        <div className='d-flex justify-content-end '>
          {employee[field]?.document_status === 'pending' ? (
            <>
              <button
                type='button'
                className='btn btn-success'
                onClick={() => handleSubmitData(field, 'approved')}
              >
                Accept
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => handleSubmitData(field, 'rejected')}
              >
                Reject
              </button>
            </>
          ) : (
            <>
              <div
                className='d-flex align-items-center text-uppercase'
                style={{
                  color:
                    employee[field]?.document_status === 'approved'
                      ? 'green'
                      : 'red',
                }}
              >
                <i
                  className={
                    employee[field]?.document_status === 'approved'
                      ? 'fa fa-check'
                      : 'fa fa-close'
                  }
                ></i>
                {employee[field]?.document_status}
              </div>
              <button
                type='button'
                className='btn btn-sm btn-secondary'
                onClick={() => handleSubmitData(field, 'pending')}
              >
                <i className='fa fa-edit'></i>
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export const VerifyDetails = () => {
  const params = useParams();
  const [data] = useSingleEmployee(params.id);
  const [updateData] = useChangeEmployeeStatus();
  // const [updateDataSingleField] = useOnboardEmployeeSinlgeField()
  const { updateSingleFieldEmployee } = useUpdateSingleFieldEmployee();

  const { employee } = data;
  const navigate = useNavigate();
  const [editingField, setEditingField] = useState(null);
  const handleSubmitData = async (field, status) => {
    await updateData(params.id, { field, status });
    // window.location.href = '/';
  };
  const handleSave = async (values, field) => {
    await updateSingleFieldEmployee(employee._id, {
      field: field,
      value: values[field],
    });
    setEditingField(null);
  };

  return (
    <div>
      <section className='main-table-form mt-3'>
        <div className='container'>
          <Formik
            initialValues={{
              joining_date: moment(employee?.joining_date).format('YYYY-MM-DD'),
              job_location: employee?.job_location || '',
              employee_name: employee?.employee_name || '',
              pan_number: employee?.pan_number || '',
              name_as_on_aadhaar_card: employee?.name_as_on_aadhaar_card || '',
              aadhaar_card_number: employee?.aadhaar_card_number || '',
              bank_name: employee?.bank_name || '',
              ifsc_code: employee?.ifsc_code || '',
              bank_account_number: employee?.bank_account_number || '',
            }}
            // validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => handleSave(values, editingField)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className='row'>
                  <div className='col-md-8'>
                    <div className='logo'>
                      <Link to='/' className='navbar-brand logo' href='#'>
                        <img src='/logo.png' />
                      </Link>
                    </div>
                    <button onClick={() => navigate(-1)}>Go back</button>
                    <h2> User Onboarding </h2>
                    <hr />

                    {/* <div className="d-flex ">
                  <input
                    type="date"
                    onChange={(e) => SetJoiningDate(e.target.value)}
                    value={joiningDate}
                  />
                  <button
                    type="button"
                    className="btn btn-success"
                    // onClick={() => handleSubmitData(field, "approved")}
                  >
                    Update
                  </button>
                </div> */}

                    {editingField === 'joining_date' ? (
                      <div className='d-flex'>
                        <Field type='date' name='joining_date' />
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <h3>
                          Joining Date:{' '}
                          {moment(employee?.joining_date).format('DD/MMM/YYYY')}
                        </h3>
                        <Edit onClick={() => setEditingField('joining_date')} />
                      </div>
                    )}
                    {editingField === 'job_location' ? (
                      <div className='d-flex'>
                        <Field type='text' name='job_location' />
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <h3>Job Location: {employee?.job_location}</h3>
                        <Edit onClick={() => setEditingField('job_location')} />
                      </div>
                    )}

                    <hr />

                    {editingField === 'employee_name' ? (
                      <div className='d-flex'>
                        <Field type='text' name='employee_name' />
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <h1>
                          {' '}
                          {employee?.employee_name} (Employee ID :{' '}
                          {employee?.employee_number})
                        </h1>
                        <Edit
                          onClick={() => setEditingField('employee_name')}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                {(employee?.client_name?.onboarding_fields?.pan_number ||
                  employee?.client_name?.onboarding_fields?.pan_picture) && (
                  <div className='row border-bottom d-flex align-items-center'>
                    <div className='col-md-4'>
                      {editingField === 'pan_number' ? (
                        <div className='d-flex'>
                          <Field type='text' name='pan_number' />
                          <button type='submit' className='btn btn-success'>
                            Update
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex align-items-center gap-3'>
                          PAN
                          {employee?.client_name?.onboarding_fields
                            .pan_number && (
                            <>
                              ({employee?.pan_number})
                              <Edit
                                onClick={() => setEditingField('pan_number')}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {employee?.client_name?.onboarding_fields?.pan_picture && (
                      <div className='col-md-4'>
                        {employee && employee?.pan_picture && (
                          <a
                            href={`${URI}/${employee?.pan_picture}`}
                            target='_blank'
                          >
                            <img
                              src={`${URI}/${employee?.pan_picture}`}
                              style={{ width: '200px', height: 'auto' }}
                            />
                          </a>
                        )}
                      </div>
                    )}

                    <ActionButtons
                      employee={employee}
                      handleSubmitData={handleSubmitData}
                      field={'pan_picture_timeline'}
                    />
                  </div>
                )}
                {(employee?.client_name?.onboarding_fields
                  ?.aadhaar_card_number ||
                  employee?.client_name?.onboarding_fields?.adhar_card_front ||
                  employee?.client_name?.onboarding_fields
                    ?.adhar_card_back) && (
                  <div className='row border-bottom  d-flex align-items-center'>
                    <div className='col-md-4'>
                      {editingField === 'aadhaar_card_number' ? (
                        <div className='d-flex'>
                          <Field type='text' name='aadhaar_card_number' />
                          <button type='submit' className='btn btn-success'>
                            Update
                          </button>
                        </div>
                      ) : (
                        <div className='d-flex align-items-center gap-3'>
                          Adhaar
                          {employee?.client_name?.onboarding_fields
                            .aadhaar_card_number && (
                            <>
                              ({employee?.name_as_on_aadhaar_card} -{' '}
                              {employee?.aadhaar_card_number})
                              <Edit
                                onClick={() =>
                                  setEditingField('aadhaar_card_number')
                                }
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className='col-md-4'>
                      {employee &&
                        employee.adhar_card_front &&
                        employee?.client_name?.onboarding_fields
                          ?.adhar_card_front && (
                          <a
                            href={`${URI}/${employee?.adhar_card_front}`}
                            target='_blank'
                          >
                            <img
                              src={`${URI}/${employee?.adhar_card_front}`}
                              style={{ width: '200px', height: 'auto' }}
                            />
                          </a>
                        )}
                      {employee &&
                        employee.adhar_card_back &&
                        employee?.client_name?.onboarding_fields
                          ?.adhar_card_back && (
                          <a
                            href={`${URI}/${employee?.adhar_card_back}`}
                            target='_blank'
                          >
                            <img
                              src={`${URI}/${employee?.adhar_card_back}`}
                              style={{ width: '200px', height: 'auto' }}
                            />
                          </a>
                        )}
                    </div>
                    <ActionButtons
                      employee={employee}
                      handleSubmitData={handleSubmitData}
                      field={'adhar_card_timeline'}
                    />
                  </div>
                )}
                {employee?.client_name?.onboarding_fields
                  ?.signed_appointment_letter && (
                  <div className='row border-bottom d-flex align-items-center'>
                    <div className='col-md-4'>Signed Appointment Letter</div>
                    <div className='col-md-4'>
                      {employee && employee.signed_appointment_letter && (
                        <a
                          href={`${URI}/${employee?.signed_appointment_letter}`}
                          target='_blank'
                        >
                          <img
                            src={`${URI}/${employee?.signed_appointment_letter}`}
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </a>
                      )}
                    </div>
                    <ActionButtons
                      employee={employee}
                      handleSubmitData={handleSubmitData}
                      field={'signed_appointment_letter_timeline'}
                    />
                  </div>
                )}
                {employee?.client_name?.onboarding_fields
                  ?.passport_size_photo && (
                  <div className='row border-bottom d-flex align-items-center'>
                    <div className='col-md-4'>Passport Size Photo</div>
                    <div className='col-md-4'>
                      {employee && employee.passport_size_photo && (
                        <a
                          href={`${URI}/${employee?.passport_size_photo}`}
                          target='_blank'
                        >
                          <img
                            src={`${URI}/${employee?.passport_size_photo}`}
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </a>
                      )}
                    </div>
                    <ActionButtons
                      employee={employee}
                      handleSubmitData={handleSubmitData}
                      field={'passport_size_photo_timeline'}
                    />
                  </div>
                )}

                <div className='row border-bottom d-flex align-items-center '>
                  <div className='col-md-4'>
                    <p>Bank Passbook / Cancelled Cheque Leaf</p>

                    <p>
                      Bank : {employee?.bank_name}
                      {/* <Edit /> */}
                    </p>
                    {editingField === 'ifsc_code' ? (
                      <div className='d-flex'>
                        <Field type='text' name='ifsc_code' />
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <p> IFSC Code :{employee?.ifsc_code}</p>
                        <Edit onClick={() => setEditingField('ifsc_code')} />
                      </div>
                    )}
                    {editingField === 'bank_account_number' ? (
                      <div className='d-flex'>
                        <Field type='text' name='bank_account_number' />
                        <button type='submit' className='btn btn-success'>
                          Update
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center gap-3'>
                        <p>Account Number : {employee?.bank_account_number} </p>
                        <Edit
                          onClick={() => setEditingField('bank_account_number')}
                        />
                      </div>
                    )}
                  </div>
                  <div className='col-md-4'>
                    {employee &&
                      employee?.bank_passbook_or_cancelled_cheque && (
                        <a
                          href={`${URI}/${employee?.bank_passbook_or_cancelled_cheque}`}
                          target='_blank'
                        >
                          <img
                            src={`${URI}/${employee?.bank_passbook_or_cancelled_cheque}`}
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </a>
                      )}
                  </div>
                  <ActionButtons
                    employee={employee}
                    handleSubmitData={handleSubmitData}
                    field={'bank_passbook_or_cancelled_cheque_timeline'}
                  />
                </div>
              </Form>
            )}
          </Formik>
          {employee?.client_name?.onboarding_fields
            ?.signed_scanned_signature && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Signed scanned signature</div>
              <div className='col-md-4'>
                {employee && employee.signed_scanned_signature && (
                  <a
                    href={`${URI}/${employee?.signed_scanned_signature}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.signed_scanned_signature}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'signed_scanned_signature_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields?.driving_license && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Driving License</div>
              <div className='col-md-4'>
                {employee && employee?.driving_license && (
                  <a
                    href={`${URI}/${employee?.driving_license}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.driving_license}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'driving_license_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields?.tenth_certificate && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>10th Certificate</div>
              <div className='col-md-4'>
                {employee && employee?.tenth_certificate && (
                  <a
                    href={`${URI}/${employee?.tenth_certificate}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.tenth_certificate}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'tenth_certificate_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields?.twelfth_certificate && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>12th Certificate</div>
              <div className='col-md-4'>
                {employee && employee.twelfth_certificate && (
                  <a
                    href={`${URI}/${employee?.twelfth_certificate}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.twelfth_certificate}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'twelfth_certificate_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields?.graduation_certificate && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Graduation Certificate</div>
              <div className='col-md-4'>
                {employee && employee.graduation_certificate && (
                  <a
                    href={`${URI}/${employee?.graduation_certificate}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.graduation_certificate}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'graduation_certificate_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields
            ?.post_graduation_certificate && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Post Graduation Certificate</div>
              <div className='col-md-4'>
                {employee && employee?.post_graduation_certificate && (
                  <a
                    href={`${URI}/${employee?.post_graduation_certificate}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.post_graduation_certificate}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'post_graduation_certificate_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields
            ?.previous_company_experience_letter && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Previous Company Experience Letter</div>
              <div className='col-md-4'>
                {employee && employee?.previous_company_experience_letter && (
                  <a
                    href={`${URI}/${employee?.previous_company_experience_letter}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.previous_company_experience_letter}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'previous_company_experience_letter_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields
            ?.last_three_month_salary_slip && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>Last 3 month Salary slip</div>
              <div className='col-md-4'>
                {employee &&
                  employee?.last_three_month_salary_slip &&
                  employee?.last_three_month_salary_slip?.map((item) => {
                    return (
                      <a href={`${URI}${item}`} target='_blank'>
                        <img
                          src={`${URI}${item}`}
                          style={{ width: '200px', height: 'auto' }}
                        />
                      </a>
                    );
                  })}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'last_three_month_salary_slip_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields
            ?.other_client_specific_form_upload && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>
                Other Client Specific form upload – 1
              </div>
              <div className='col-md-4'>
                {employee && employee?.other_client_specific_form_upload && (
                  <a
                    href={`${URI}/${employee?.other_client_specific_form_upload}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.other_client_specific_form_upload}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'other_client_specific_form_upload_timeline'}
              />
            </div>
          )}
          {employee?.client_name?.onboarding_fields
            ?.other_client_specific_form_upload_2 && (
            <div className='row border-bottom d-flex align-items-center'>
              <div className='col-md-4'>
                Other Client Specific form upload – 2
              </div>
              <div className='col-md-4'>
                {employee && employee?.other_client_specific_form_upload_2 && (
                  <a
                    href={`${URI}/${employee?.other_client_specific_form_upload_2}`}
                    target='_blank'
                  >
                    <img
                      src={`${URI}/${employee?.other_client_specific_form_upload_2}`}
                      style={{ width: '200px', height: 'auto' }}
                    />
                  </a>
                )}
              </div>
              <ActionButtons
                employee={employee}
                handleSubmitData={handleSubmitData}
                field={'other_client_specific_form_upload_2_timeline'}
              />
            </div>
          )}
          <div className='row'>
            <div className='col-md-12 text-center'>
              <button
                onClick={() => window.history.back()}
                className='btn btn-success'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
