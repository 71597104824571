import React from 'react';

export const Form9 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='mb-4'>
              <h5 className='fst-italic'>
                <u>Para 2(g) : Family Means :-</u>
              </h5>
            </div>
            <div>
              <ul class=' list-group-numbered'>
                <li class='list-group-item mb-2'>
                  in the case of a male member, his wife, his children, whether
                  married or unmarried, his dependent parents and his deceased
                  son’s widow and children;
                  <br />
                  <span className='fw-bold text-dark fst-italic'>
                    Provided that
                  </span>{' '}
                  if a member proves that his wife has ceased, under the
                  personal law governing him or the customary law of the
                  community to which the spouses belong, to be entitled to
                  maintenance she shall no longer be deemed to be a part of the
                  member’s family for the purpose of this scheme, unless the
                  member subsequently intimates by express notice in writing to
                  the commissioner that she shall continue to be so regarded;
                  and
                </li>
                <li class='list-group-item mb-2'>
                  In the case of a female member, her husband, her children,
                  whether married or unmarried, her dependent parents, her
                  husband’s, dependent parents, her deceased sons’s widow and
                  children;
                  <br />
                  <span className='fw-bold text-dark fst-italic'>
                    Provided that
                  </span>{' '}
                  if a member by notice in writing to the commissioner expresses
                  her desire to exclude her husband from the family, the husband
                  and his dependent parents shall no longer be deemed to be a
                  part of the member’s family for the purpose of this scheme,
                  unless the member subsequently cancels in writing any such
                  notice.
                </li>
              </ul>
            </div>
            <div>
              <p className=''>
                <u className='text-dark fw-bold fst-italic'> Explanation :-</u>{' '}
                In either of the above two cases, if the child of a member [or
                as the case may be, the child of a deceased son of the member ]
                has been adopted by another person and if, under the personal
                law of the adopter, adoption is legally recognised, such a child
                shall be considered as excluded from the family of the member.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='mb-1 d-flex justify-content-center'>
              <h4 className='fst-italic'>EMPLOYEES PENSION SCHEME, 1995</h4>
            </div>
            <div className='mb-2 d-flex justify-content-center'>
              <h5 className='fst-italic'>( E P S )</h5>
            </div>
            <div className='mb-2 '>
              <h5 className='fst-italic'>
                <u>
                  Para 18 : Particulars to be supplied by the Employees already
                  employed at the time of commencement of the Employees Pension
                  Scheme.
                </u>
              </h5>
            </div>
            <div className='mb-4'>
              <p>
                Every person who is entitled to become a member of the Employees
                Pension Fund shall be asked forthwith by his employer to furnish
                and that person shall, on such demand, furnish to him for
                communication to the Commissioner particulars concerning himself
                and his family in the form prescribed by the Central Provident
                Fund Commissioner.
              </p>
            </div>
            <div className='mb-2 '>
              <h5 className='fst-italic'>
                <u>Para 2(vii) :- Family means :-</u>
              </h5>
            </div>
            <div>
              <ul class=' list-group-numbered'>
                <li class='list-group-item mb-2'>
                  Wife in the case of male member of the Employees’ Pension
                  Fund;
                </li>
                <li class='list-group-item mb-2'>
                  Husband in the case of a female member of the Employees’
                  Pension fund;and
                </li>
                <li class='list-group-item mb-2'>
                  Sons and daughters of a member of the Employees Pension fund;
                </li>
              </ul>
            </div>
            <div>
              <p className=''>
                <span className='text-dark fst-italic fw-bold'>
                  Explanation –{' '}
                </span>{' '}
                The expression “Sons” and “daughters” shall include children [
                Legally adopted by the member]
              </p>
            </div>
            <div>
              <p className=''>
                <span className='text-dark  fw-bold'>NOTE : </span> Members can
                nominate a person to receive benefits under the Employees’
                Pension Scheme 1995 where a member is unmarried or does not have
                any family. Such nominee shall be paid pension equal to widow
                pension in case of death of member.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
