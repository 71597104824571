import React from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';

import { Alerts } from '../../components/common/Alerts';
import { AddClientComponent } from '../../components/clients/AddClientComponent';

function AddClient() {
  return (
    <div>
      <Header />
      <AuthBreadCrum title={'Add Client'} />
      {/* page-section */}
      <section className='  personal-information ptb-20 '>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <AddClientComponent />
            </div>
          </div>
        </div>
      </section>
      <Alerts />
    </div>
  );
}

export default AddClient;
