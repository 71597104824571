export const links = {
  homePage: '/',
  bulk: '/bulks',
  bulkDelete: '/bulk-delete',
  users: '/users',
  addUser: '/users/add',
  clients: '/clients',
  addClient: '/clients/add',
  editClient: '/clients/:id/edit',
  addEmployee: '/employee/add',
  editEmployee: '/employee/:id/edit',
  editUser: '/users/:id/edit',
  singleUser: '/users/:id/view',
  onboardingTool: '/onboarding/employee/:id/view',
  onboardingError: '/onboarding/error',
  onboardingToolVerify: '/onboarding/employee/:id/verify',
  thankYouPage: '/onboarding/employee/:id/thank-you',
  dashboard: '/dashboard',
  register: '/register',
  uploadform: '/uploadform',
  login: '/login',
};
