import React from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';
import EditUserComponent from '../../components/users/EditUserComponent';
import { useSingleCRMUser } from '../../shared/hooks/UseCRMUser';
import { useParams } from 'react-router-dom';
import { Alerts } from '../../components/common/Alerts';

function EditUser() {
  const params = useParams();
  const { data } = useSingleCRMUser(params.id);
  const { crmuser, crmuser_loading } = data;

  return (
    <div>
      <Header />
      <AuthBreadCrum title={'Edit User'} />

      {/* page-section */}

      <section className="  personal-information ptb-20 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {crmuser && <EditUserComponent crmuser={crmuser} />}
            </div>
          </div>
        </div>
      </section>
      <Alerts />
    </div>
  );
}

export default EditUser;
