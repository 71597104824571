import React from 'react';
import { FieldArray, Form } from 'formik';
import { CheckBox, GalleryUpload, SelectBox, TextInput } from '../Form/Form';

export const ClientForm = ({ formik }) => {
  return (
    <Form>
      <div className='row'>
        <div className='col-md-4'>
          <TextInput label='Name' name='name' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Agreement Start Date'
            name='agreement_start_date'
            type='date'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Agreement End Date'
            name='agreement_end_date'
            type='date'
          />
        </div>
        <div className='col-md-4'>
          <SelectBox label='Service Type' name='service_type'>
            <option value=''> Select Service Type </option>
            <option value='Staffing'>Staffing</option>
            <option value='Payroll Processing'>Payroll Processing</option>
            <option value='Perm Hiring'>Perm Hiring</option>
            <option value='Temp Hiring'>Temp Hiring</option>
            <option value='Compliances'>Compliances</option>
            <option value='HTD'>HTD</option>
          </SelectBox>
        </div>

        {/* <div className='col-md-4'>
          <TextInput label='Document Name' name='document_name' type='text' />
        </div>
        <div className='col-md-4'>
         
          <GalleryUpload
            title='Documents'
            item='agreement_attachment'
            formik={formik}
            hideView={true}
            value={formik?.values?.agreement_attachment}
          />
        </div> */}
      </div>
      <hr />
      <div className='border p-2 rounded'>
        <div> Agreement Attachments </div>
        <FieldArray
          name='documents_attachment'
          render={(arrayHelpers) => {
            return (
              <div>
                {formik.values.documents_attachment &&
                formik.values.documents_attachment.length > 0
                  ? formik.values.documents_attachment.map((user, index) => (
                      <div key={index} className='row'>
                        <div className='col-md-4'>
                          <TextInput
                            label='Document Name'
                            name={`documents_attachment.[${index}].title`}
                          />
                        </div>
                        <div className='col-md-4'>
                          <GalleryUpload
                            title='Documents'
                            item={`documents_attachment.[${index}].documents`}
                            formik={formik}
                            hideView={true}
                            value={
                              formik?.values?.documents_attachment?.[index]
                                ?.documents
                            }
                          />
                        </div>
                        <div className='col-md-4'>
                          <button
                            type='button'
                            className='btn btn-sm btn-danger'
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            -
                          </button>
                        </div>
                      </div>
                    ))
                  : null}
                <button
                  type='button'
                  onClick={() =>
                    arrayHelpers.push({
                      title: '',
                      documents: [],
                    })
                  } // insert an empty string at a position
                  className='btn btn-sm btn-secondary'
                >
                  Add Document
                </button>
              </div>
            );
          }}
        />
      </div>
      <div className='row'>
        <div className='p-3'>
          <strong> Agreement Signed By Client </strong>
        </div>
        <div className='col-md-4'>
          <TextInput label='Name' name='agreement_signed_by.name' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Designation'
            name='agreement_signed_by.designation'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Email'
            name='agreement_signed_by.email'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Phone'
            name='agreement_signed_by.phone'
            type='text'
          />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='p-3'>
          <strong> POC 1</strong>
        </div>
        <div className='col-md-4'>
          <TextInput label='name' name='poc_1.name' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='designation' name='poc_1.designation' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='email' name='poc_1.email' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='phone' name='poc_1.phone' type='text' />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='p-3'>
          <strong> POC 2</strong>
        </div>
        <div className='col-md-4'>
          <TextInput label='Name' name='poc_2.name' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Designation' name='poc_2.designation' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Email' name='poc_2.email' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Phone' name='poc_2.phone' type='text' />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='p-3'>
          <strong> POC 3 </strong>
        </div>

        <div className='col-md-4'>
          <TextInput label='Name' name='poc_3.name' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Phone' name='poc_3.designation' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Email' name='poc_3.email' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Phone' name='poc_3.phone' type='text' />
        </div>
      </div>
      <hr />
      <div className='card '>
        <div className='card-header '>
          <strong> Onboarding Form Fields For Employees </strong>
        </div>
        <div className='card-body'>
          <div className='d-flex flex-wrap'>
            {Object.entries(formik?.values?.onboarding_fields).map(
              ([fieldName, isChecked], index) => (
                <div key={index} className=' col-md-3'>
                  <CheckBox name={`onboarding_fields.${fieldName}`}>
                    {fieldName && fieldName.split('_').join(' ')}
                  </CheckBox>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='p-3'>
          <strong> Client Service Level Agreement </strong>
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Staffing Mark Up'
            name='staffing_mark_up'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='One Time Setup Fee'
            name='one_time_setup_fee'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label='ID Cards' name='id_cards' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Insurance' name='insurance' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Reimbursement Fee'
            name='reimbursement_fee'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Temp Sourcing Markup'
            name='temp_sourcing_markup'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Temp Replacement Period'
            name='temp_replacement_period'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Temp Payment Terms'
            name='temp_payment_terms'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label='Absorption Fee' name='absorption_fee' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Perm Sourcing Markup'
            name='perm_sourcing_markup'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Perm Replacement Period'
            name='perm_replacement_period'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Perm Payment Terms'
            name='perm_payment_terms'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label='BGV – CRC' name='bgv_crc' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' BGV – Employment '
            name='bgv_employment'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label='BGV - Address' name='bgv_address' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' Attendance Cycle'
            name='attendance_cycle'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label=' Payout cycle' name='payout_cycle' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' Inputs from client'
            name='inputs_from_client'
            type='text'
          />
        </div>

        <div className='col-md-4'>
          <TextInput
            label=' Draft invoice by Teamup HR'
            name='draft_invoice_by_teamup_hr'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' Draft Invoice approval by client '
            name='draft_invoice_approval_by_client'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' Payment from Client  '
            name='payment_from_client'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Salary Release  '
            name='salary_release'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Collect & Pay OR Funding   '
            name='collect_pay_or_funding'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Client Notice Period'
            name='notice_period_client'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Employee Notice Period'
            name='notice_period_employee'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label=' Associate contract duration '
            name='associate_contract_duration'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput
            label='Mark up for Additional Invoice'
            name='mark_up_for_additional_invoice'
            type='text'
          />
        </div>
        <div className='col-md-4'>
          <TextInput label='Other 1' name='other_1' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Other 2' name='other_2' type='text' />
        </div>
        <div className='col-md-4'>
          <TextInput label='Other 3' name='other_3' type='text' />
        </div>
      </div>
      <div className='save-button'>
        <button type='submit' className='btn primary-btn w-100'>
          Save & Continue
        </button>
      </div>
    </Form>
  );
};
