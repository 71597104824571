import React from 'react';

export const Form14 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='bg-dark-subtle px-2'>
              <p className='fw-bold text-dark '>
                {' '}
                A. PREVIOUS EMPLOYMENT DETAILS
              </p>
            </div>
          </div>
        </div>
        <div className='row '>
          <div className='col-md-12'>
            <h6>
              10) THE DETAILS OF THE UNIVERSAL ACCOUNT NUMBER (UAN) OR PREVIOUS
              PF MEMBER ID:
            </h6>
          </div>
          <div className='col-md-3 mt-2'>
            <h5>UAN</h5>
            <h6>OR</h6>
            <h5>PREVIOUS PF MEMBER ID</h5>
          </div>
          <div className='col-md-8 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
              <table class='table border border-dark mb-0'>
                <tbody>
                  <tr>
                    <td className='border border-dark'>REGION CODE</td>
                    <td className='border border-dark'> OFFICE CODE </td>
                    <td className='border border-dark'> ESTABLISHMENT ID </td>
                    <td className='border border-dark'>EXTENSION</td>
                    <td className='border border-dark'> ACCOUNT NUMBER </td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ height: '30px' }}
                    >
                      {' '}
                    </td>
                    <td
                      className='border border-dark'
                      style={{ height: '30px' }}
                    >
                      {' '}
                    </td>
                    <td
                      className='border border-dark'
                      style={{ height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ height: '30px' }}
                    >
                      {' '}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-3 mt-3'>
            <h6>11) DATE OF EXIT FOR PREVIOUS MEMBER ID (DD/MM/YYYY)</h6>
          </div>

          <div className='col-md-5 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      D
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      D
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      M
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      M
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      Y
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      Y
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      Y
                    </td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      Y
                    </td>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-12'>
            <h6>
              12) (A) IF SCHEME CERTIFICATE ISSUED FOR PREVIOUS EMPLOYMENT, THEN
              SCHEME CERTIFICATE NUMBER:
            </h6>
            <h6 className='ms-4'>
              (B) IF PENSION PAYMENT ORDER (PPO) ISSUED FOR PREVIOUS EMPLOYMENT,
              THEN PPO NUMBER:
            </h6>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <div className='bg-dark-subtle px-2'>
              <p className='fw-bold text-dark '>B. OTHER DETAILS</p>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-3 mt-3'>
            <h6>
              13) INTERNATIONAL WORKER <br />
              (PLEASE TICK)
            </h6>
          </div>

          <div className='col-md-5 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      Yes
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      No
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-12 ms-4 mt-3'>
            <p className='fw-bold text-dark'>
              IF THE REPLY TO (13) ABOVE IS YES, THEN ENTER THE DETAILS IN
              13(A), 13(B) &amp; 13(C):
            </p>
          </div>
          <div className='col-md-6 ms-4'>
            <h6>13(A) COUNTRY OF ORIGIN (Please Tick)</h6>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      INDIA
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      OTHER THAN INDIA (IF YES, PLEASE MENTION NAME OF THE
                      COUNTRY)
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-8 ms-4 mt-3'>
            <div className='d-flex '>
              <h6>13(B) PASSPORT NUMBER</h6>
              <div
                style={{
                  borderBottom: '1px solid #000',
                  height: '20px',
                  width: '300px',
                }}
                className='ms-2'
              ></div>
            </div>
          </div>
          <div className='col-md-12 ms-4 mt-3'>
            <div className='d-flex'>
              <h6>13(C) PASSPORT VALID FROM</h6>
              <div>
                <div className='ms-5'>
                  <table class='table border border-dark mb-1'>
                    <tbody>
                      <tr>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          D
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          D
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          M
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          M
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                      </tr>
                      <tr>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='d-flex' style={{ marginLeft: '205px' }}>
              <h6>To</h6>
              <div>
                <div className='ms-5'>
                  <table class='table border border-dark mb-1'>
                    <tbody>
                      <tr>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          D
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          D
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          M
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          M
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        >
                          Y
                        </td>
                      </tr>
                      <tr>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                        <td
                          className='border border-dark text-center'
                          style={{ width: '30px', height: '30px' }}
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-3 mt-3'>
            <h6>
              14) EDUCATIONAL QUALIFICATION
              <br />
              (PLEASE TICK)
            </h6>
          </div>

          <div className='col-md-8 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      ILLITERATE
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      NON- MATRIC
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      MATRIC
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      SENIOR SECONDARY
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      GRADUATE
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      POST GRADUATE
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      DOCTOR
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      TECHNICAL/ PROFESSIONAL
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-3 mt-3'>
            <h6>
              15) MARITAL STATUS
              <br />
              (PLEASE TICK)
            </h6>
          </div>

          <div className='col-md-8 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      MARRIED
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      UNMARRIED
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      WIDOW/ WIDOWER
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      DIVORCEE
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>

                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-3 mt-3'>
            <h6>
              16) SPECIALLY ABLED
              <br />
              (PLEASE TICK)
            </h6>
          </div>

          <div className='col-md-4 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      YES
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      NO
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-4 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                      colSpan={3}
                    >
                      IF YES, TICK THE CATEGORY
                    </th>
                  </tr>
                  <tr>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      {' '}
                      LOCOMOTIVE
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      {' '}
                      VISUAL
                    </th>
                    <th
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    >
                      {' '}
                      HEARING
                    </th>
                  </tr>
                  <tr>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                    <td
                      className='border border-dark text-center'
                      style={{ width: '25px', height: '30px' }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center'>
              <p className='text-dark'>
                Page <span className='fw-bold text-dark'>2</span> of{' '}
                <span className='fw-bold text-dark'>3</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
