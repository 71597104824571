import React from 'react';
import Header from '../../components/common/Header';
import { AuthBreadCrum } from '../../components/common/AuthBreadCrum';
import AddUserComponent from '../../components/users/AddUserComponent';
import { Alerts } from '../../components/common/Alerts';

 function AddUser() {
  return (
    <div>
      <Header />
      <AuthBreadCrum title={'Add User'} />
      {/* page-section */}
      <section className="  personal-information ptb-20 ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <AddUserComponent />
            </div>
          </div>
        </div>
      </section>
      <Alerts />
    </div>
  );
}

export default AddUser;
