import React from 'react';

export const Form15 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row '>
          <div className='col-md-2 mt-3'>
            <h6> 17) KYC DETAILS</h6>
          </div>

          <div className='col-md-9 mt-3'>
            <div>
              <table class='table border border-dark mb-1'>
                <tbody>
                  <tr>
                    <th className='border border-dark text-center'>
                      KYC DOCUMENT TYPE
                    </th>
                    <th className='border border-dark text-center'>
                      NAME AS ON KYC DOCUMENT
                    </th>
                    <th className='border border-dark text-center'>NUMBER</th>
                    <th className='border border-dark text-center'>
                      REMARKS, IF ANY
                    </th>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      BANK ACCOUNT-1*
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      NPR/AADHAAR
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      PERMANENT ACCOUNT NUMBER (PAN)
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>PASSPORT</th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      DRIVING LICENCE
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      ELECTION CARD
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      RATION CARD
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <th className='border border-dark text-center'>
                      ESIC CARD
                    </th>

                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                    <td className='border border-dark text-center'></td>
                  </tr>
                  <tr>
                    <td className='border border-dark text-center' colSpan={4}>
                      *{' '}
                      <span className='fw-bold text-dark'>
                        Mandatory Field (NOTE: BANK ACCOUNT NUMBER (ALONG WITH
                        IFSC CODE) IS MANDATORY.
                      </span>{' '}
                      YOU ARE HOWEVER ADVISED TO PROVIDE ALL KYC DOCUMENTS
                      AVAILABLE WITH YOU IN ADDITION TO MANDATORY KYCS TO AVAIL
                      BETTER SERVICES.{' '}
                      <span className='fw-bold text-dark'>
                        SELF-ATTESTED PHOTOCOPIES OF THE DOCUMENTS{' '}
                      </span>{' '}
                      MUST BE ATTACHED WITH THIS FORM.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <div className='bg-dark-subtle px-2'>
              <p className='fw-bold text-dark'> C. UNDERTAKING:</p>
            </div>
          </div>
          <div className='col-md-12'>
            <p className='fw-bold text-dark mb-0'>
              A. I CERTIFY THAT ALL THE INFORMATION GIVEN ABOVE IS TRUE TO THE
              BEST OF MY KNOWLEDGE AND BELIEF.
            </p>
            <p className='fw-bold text-dark mb-0'>
              B. IN CASE, EARLIER A MEMBER OF EPF SCHEME, 1952 AND/OR EPS, 1995,
            </p>
            <p className='fw-bold text-dark ms-3 mb-0'>
              (I) I HAVE ENSURED THE CORRECTNESS OF MY UAN/ PREVIOUS PF MEMBER
              ID.{' '}
            </p>
            <p className='fw-bold text-dark ms-3 mb-0'>
              (II) THIS MAY ALSO BE TREATED AS MY REQUEST FOR TRANSFER OF FUNDS
              AND SERVICE DETAILS IF APPLICABLE FROM THE PREVIOUS ACCOUNT AS
              DECLARED ABOVE TO THE PRESENT P.F. ACCOUNT. (THE TRANSFER WOULD BE
              POSSIBLE ONLY IF THE IDENTIFIED KYC DETAILS APPROVED BY PREVIOUS
              EMPLOYER HAS BEEN VERIFIED BY PRESENT EMPLOYER USING HIS DIGITAL
              SIGNATURE CERTIFICATE).
            </p>
            <p className='fw-bold text-dark ms-3 mb-0'>
              (III) I AM AWARE THAT I CAN SUBMIT MY NOMINATION FORM THROUGH UAN
              BASED MEMBER PORTAL.
            </p>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-between'>
              <div>
                <p className='fw-bold text-black'>DATE:</p>
                <p className='fw-bold text-black'>PLACE:</p>
              </div>
              <p className='fw-bold text-black'>SIGNATURE OF MEMBER</p>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-md-12'>
            <div className='bg-dark-subtle px-2 d-flex justify-content-center'>
              <h3 className='fw-bold text-dark'>
                DECLARATION BY PRESENT EMPLOYER
              </h3>
            </div>
            <div className='mt-3'>
              <p>
                A. THE MEMBER Mr./Ms./Mrs. ………………………….. HAS JOINED ON AND HAS
                BEEN ALLOTTED PF MEMBER ID …………………………………………...
              </p>
              <div>
                <p className='mb-1'>
                  B. IN CASE THE PERSON WAS EARLIER NOT A MEMBER OF EPF SCHEME,
                  1952 AND EPS, 1995:
                </p>
                <li className='text-dark fw-bold ms-3'>
                  (POST ALLOTMENT OF UAN) <span></span>THE UAN ALLOTTED FOR THE
                  MEMBER IS …………………………
                </li>{' '}
                <li className='text-dark fw-bold ms-3'>
                  PLEASE TICK THE APPROPRIATE OPTION:
                </li>{' '}
                <p className='ms-5 mb-0'>
                  THE KYC DETAILS OF THE ABOVE MEMBER IN THE UAN DATABASE
                </p>
                <div className='ms-5'>
                  <img
                    src='/assets/images/checkbox.png'
                    alt=''
                    style={{ width: '25px', height: '25px' }}
                  />
                  HAVE NOT BEEN UPLOADED
                </div>
                <div className='ms-5'>
                  <img
                    src='/assets/images/checkbox.png'
                    alt=''
                    style={{ width: '25px', height: '25px' }}
                  />
                  HAVE BEEN UPLOADED BUT NOT APPROVED
                </div>
                <div className='ms-5'>
                  <img
                    src='/assets/images/checkbox.png'
                    alt=''
                    style={{ width: '25px', height: '25px' }}
                  />
                  HAVE BEEN UPLOADED AND APPROVED WITH DSC{' '}
                </div>
              </div>
              <div className='mt-3'>
                <p className='mb-1'>
                  C. IN CASE THE PERSON WAS EARLIER A MEMBER OF EPF SCHEME, 1952
                  AND EPS, 1995:
                </p>
                <li className='text-dark fw-bold ms-3'>
                  THE ABOVE MEMBER ID OF THE MEMBER AS MENTIONED IN (A) ABOVE
                  HAS BEEN TAGGED WITH HIS/HER UAN/PREVIOUS MEMBER ID AS
                  DECLARED BY MEMBER.{' '}
                </li>{' '}
                <li className='text-dark fw-bold ms-3'>
                  PLEASE TICK THE APPROPRIATE OPTION:-
                </li>{' '}
                <div className='ms-5'>
                  <img
                    src='/assets/images/checkbox.png'
                    alt=''
                    style={{ width: '25px', height: '25px' }}
                  />
                  THE KYC DETAILS OF THE ABOVE MEMBER IN THE UAN DATABASE HAVE
                  BEEN APPROVED WITH DIGITAL SIGNATURE CERTIFICATE{' '}
                  <span className='ms-4'>
                    AND TRANSFER REQUEST HAS BEEN GENERATED ON PORTAL.
                  </span>
                </div>
                <div className='ms-5'>
                  <img
                    src='/assets/images/checkbox.png'
                    alt=''
                    style={{ width: '25px', height: '25px' }}
                  />
                  AS THE DSC OF ESTABLISHMENT ARE NOT REGISTERED WITH EPFO, THE
                  MEMBER HAS BEEN INFORMED TO FILE PHYSICAL CLAIM{' '}
                  <span className='ms-4'>
                    (FORM-13) FOR TRANSFER OF FUNDS FROM HIS PREVIOUS
                    ESTABLISHMENT.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-between'>
              <div>
                <p className='fw-bold text-black'>DATE:</p>
              </div>
              <p className='fw-bold text-black'>
                SIGNATURE OF EMPLOYER WITH SEAL OF ESTABLISHMENT
              </p>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center'>
              <p className='text-dark'>
                Page <span className='fw-bold text-dark'>3</span> of{' '}
                <span className='fw-bold text-dark'>3</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
