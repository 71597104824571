import React from 'react';

export const Form11 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 '>
            <div className='text-center d-flex justify-content-center'>
              <h5 className='w-75'>
                (C) Details of Nominee u/s 71 of ESI Act 1948/Rule 56(2) of ESI
                (Central) Rules, 1950 for payment of cash benefit in the event
                of death.
              </h5>
            </div>
          </div>
          <div className='col-md-12 mt-4'>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark '>Name</td>
                    <td className='border border-dark'>Relationship</td>
                    <td className='border border-dark'>Address</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <p>
                I hereby declare that the particulars given by me are correct to
                the best of my knowledge and belief. I undertake to intimate the
                Corporation any changes in the membership of my family within 15
                days of such change.
              </p>
            </div>
            <div>
              <p>Counter signature by the employer</p>
            </div>
            <div className='d-flex justify-content-between mt-4'>
              <p>Signature with Seal</p>
              <p>Signature/T.I.of IP</p>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <div className=''>
              <h6 className=''>(D) FAMILY PARTICULARS OF INSURED PERSON</h6>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark '>S.No.</td>
                    <td className='border border-dark'>Name</td>
                    <td className='border border-dark text-center'>
                      Date of Birth/ Age as on date of filling form
                    </td>
                    <td className='border border-dark text-center'>
                      Relationship with the Employee{' '}
                    </td>
                    <td className='border border-dark text-center' colSpan={2}>
                      Whether residing with him/her?
                    </td>
                    <td className='border border-dark text-center ' colSpan={2}>
                      If’No’, state place of Residence{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>1</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>2</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>3</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>4</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
          </div>
          <div className='col-md-12'>
            <div className='d-flex justify-content-between'>
              <p> ESI Corporation Temporary Identity Card</p>
              <p>(Valid for 3 months from the date of appointment)</p>
            </div>
          </div>
          <div className='col-md-8 mt-1'>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark '>Name</td>
                    <td className='border border-dark' colSpan={2}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>Ins.No.</td>
                    <td className='border border-dark w-25'></td>
                    <td className='border border-dark'>Date of appointment</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>Branch Office</td>
                    <td className='border border-dark w-25'></td>
                    <td className='border border-dark'>Dispensary</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>
                      Employer’s Code No. & Address
                    </td>
                    <td className='border border-dark' colSpan={2}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-1'></div>
          <div className='col-md-3'>
            <div
              className='border-1 border border-dark d-flex justify-content-center align-items-center'
              style={{ height: '180px', width: '200px' }}
            >
              <p>Space For Photograph</p>
            </div>
          </div>
          <div className='col-md-12'>
            <p>Validity:</p>
            <div className='d-flex justify-content-between mt-5'>
              <p>Dated:</p>
              <p>Signature/T.I. of I.P</p>
              <p>Signature of B.M. with Seal</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
