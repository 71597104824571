export const GET_CRMUSERS_STATED = "GET_CRMUSERS_STATED";
export const GET_CRMUSERS = "GET_CRMUSERS";
export const GET_CRMUSERS_ENDED = "GET_CRMUSERS_ENDED";
export const ADD_CRMUSER_STATED = "ADD_CRMUSER_STARTED";
export const ADD_CRMUSER = "ADD_CRMUSER";
export const ADD_CRMUSER_ENDED = "ADD_CRMUSER_ENDED";
export const EDIT_CRMUSER_STATED = "EDIT_CRMUSER_STATED";
export const EDIT_CRMUSER = "EDIT_CRMUSER";
export const EDIT_CRMUSER_ENDED = "EDIT_CRMUSER_ENDED";
export const GET_CRMUSER = "GET_CRMUSER";
export const GET_CRMUSER_STATED = "GET_CRMUSER_STATED";
export const GET_CRMUSER_ENDED = "GET_CRMUSER_ENDED";
export const RESET_CRMUSER = "RESET_CRMUSER";
export const ERROR_CRMUSER = "ERROR_CRMUSER";
export const GET_ALL_CRMUSERS_STATED = "GET_ALL_CRMUSERS_STATED";
export const GET_ALL_CRMUSERS = "GET_ALL_CRMUSERS";
export const GET_ALL_CRMUSERS_ENDED = "GET_ALL_CRMUSERS_ENDED";
