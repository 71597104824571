import React from 'react';

export const Form8 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center text-center mb-3'>
              <h4 className=''>GUIDANCE FOR FILLING THE FORM No - 2</h4>
            </div>
            <div className='d-flex justify-content-center text-center fst-italic'>
              <h5 className=''>Employee’s Provident Fund Scheme, 1952:-</h5>
            </div>
            <div className='d-flex justify-content-center text-center'>
              <h4 className=''>( E P F )</h4>
            </div>
            <div className='mb-4'>
              <h5 className='fst-italic'>
                Para 33 :- Declaration by persons already employed at the time
                of institution of the fund :-
              </h5>
            </div>
            <div className='mb-4'>
              <p>
                Every person who is required or entitled to become a member of
                the Fund shall be asked forthwith by his employer to furnish and
                shall, on such demand, furnish to him, for communication to the
                Commissioner, particulars concerning himself and his nominee
                required for the declaration form in Form 2. Such employer shall
                enter the particulars in the declaration form and obtain the
                signature or thumb impression of the person concerned.
              </p>
            </div>
            <div className='mb-4'>
              <h5 className='fst-italic'>
                <u>Para 61 : Nomination</u>
              </h5>
            </div>
            <div>
              <ul class=' list-group-numbered'>
                <li class='list-group-item mb-2'>
                  Each member shall make in his declaration in Form 2, a
                  nomination conferring the right to receive the amount that may
                  stand to his credit in the Fund in the event of his death
                  before the amount standing to his credit has become payable,
                  or where the amount has become payable before payment has been
                  made.
                </li>
                <li class='list-group-item mb-2'>
                  A member may in this nomination distribute the amount that may
                  stand to his credit in the Fund amongst his nominees at his
                  own discretion.
                </li>
                <li class='list-group-item mb-2'>
                  If a member has a family at the time of making a nomination,
                  the nomination shall be in favour of one or more persons
                  belonging to his family. Any nomination made by such member in
                  favour of a person not belonging to his family shall be
                  invalid.
                  <br />
                  Provided that a fresh nomination shall be made by the member
                  on his marriage and any nomination made before such marriage
                  shall be deemed to be invalid.
                </li>
                <li class='list-group-item mb-2'>
                  If at the time of making a nomination the member has no
                  family, the nomination may be in favour of any person or
                  persons but if the member subsequently acquires a family, such
                  nomination shall forthwith be deemed to be invalid and the
                  member shall make a fresh nomination in favour of one or more
                  persons belonging to his family.
                </li>
                <li class='mb-2'>
                  <span className='fw-bold'>4A</span> Where the nomination is
                  wholly or partly in favour of a minor, the member may, for the
                  purposes of this scheme appoint a major person of his family,
                  as defined in clause (g) of paragraph 2, to be the guardian of
                  the minor nominee in the event of the member predeceasing the
                  nominee and the guardian so appointed.
                  <br />
                  Provided that where there is no major person in the family,
                  the member may, at his discretion, appoint any other person to
                  be a guardian of the minor nominee.
                </li>
                <li class='list-group-item mb-2'>
                  A nomination made under sub-paragraph(1) may at any time be
                  modified by a member after giving a written notice of his
                  intention of doing so in form 2. If the nominee predeceases
                  the member, the interest of the nominee shall revert to the
                  member who may make a fresh nomination in respect of such
                  interest.
                </li>
                <li class='list-group-item mb-2'>
                  A nomination or its modification shall take effect to the
                  extent that it is valid on the date on which it is received by
                  the commissioner.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
