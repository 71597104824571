import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from "../Form/Form";
import { useGetAllClients } from "../../shared/hooks/UseClient";
import { useCheckAuthenticated, useLogout } from "../../shared/hooks/UseAuth";
export const EmployeeFormComponent = ({ hidepassword }) => {
  const { getClients, clients } = useGetAllClients();
  const userData = useCheckAuthenticated();

  useEffect(() => {
    getClients();
  }, []);
  return (
    <Form>
      <div className="row">
        <div className="col-md-4">
          <SelectBox label="Client Name" name="client_name">
            <option value=""></option>
            {userData &&
            userData.user &&
            userData.user.role &&
            userData.user.role === "ADMIN"
              ? clients &&
                clients.clients &&
                clients.clients.map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })
              : userData.user.clients.map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
            {/* <option value='Male'>Male </option>
            <option value='Female'>Female </option>
            <option value='Others'>Others </option> */}
          </SelectBox>
        </div>

        <div className="col-md-4">
          <SelectBox label="Employee Title"  name="employee_title">
            <option value=""></option>
            <option value="Mr">Mr </option>
            <option value="Ms">Ms </option>
          </SelectBox>
        </div>
        <div className="col-md-4">
          <TextInput label="Employee Name" name="employee_name" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Employee Number"
            name="employee_number"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Joining Data" name="joining_date" type="date" />
        </div>
        <div className="col-md-4">
          <TextInput label="Date Of Birth" name="date_of_birth" type="date" />
        </div>

        <div className="col-md-4">
          <SelectBox label="Gender" name="gender">
            <option value=""></option>
            <option value="Male">Male </option>
            <option value="Female">Female </option>
            <option value="Others">Others </option>
          </SelectBox>
        </div>
        <div className="col-md-4">
          <TextInput label="Pan Number" name="pan_number" type="text" />
        </div>
        <div className="col-md-4">
          <SelectBox label="Marital Status" name="marital_status">
            <option value=""></option>
            <option value="Single">Single </option>
            <option value="Married">Married </option>
          </SelectBox>
        </div>
        <div className="col-md-4">
          <SelectBox label="Blood Group" name="blood_group">
            <option value=""></option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
          </SelectBox>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <TextInput label="Father Name" name="father_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Father Date of Birth"
            name="father_dob"
            type="date"
          />
        </div>
        <div className="col-md-4"></div>

        <div className="col-md-4">
          <TextInput label="Mother Name" name="mother_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Mother Date of Birth"
            name="mother_dob"
            type="date"
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <TextInput label="Spouse Name" name="spouse_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Spouse Date of Birth"
            name="spouse_dob"
            type="date"
          />
        </div>
        <div className="col-md-4"></div>

        <div className="col-md-4">
          <TextInput label="Child 1 Name" name="child_1_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Child 1 Date of Birth"
            name="child_1_dob"
            type="date"
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <TextInput label="Child 2 Name" name="child_2_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Child 2 Date of Birth"
            name="child_2_dob"
            type="date"
          />
        </div>
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <TextInput
            label="Reference 1 Name"
            name="reference_1_name"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 1 Employee Code"
            name="reference_1_employee_code"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 1 Designation"
            name="reference_1_designation"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 1 Contact Number"
            name="reference_1_contact_number"
            type="text"
          />
        </div>
        <div className="col-md-8"></div>

        <div className="col-md-4">
          <TextInput
            label="Reference 2 Name"
            name="reference_2_name"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 2 Employee Code"
            name="reference_2_employee_code"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 2 Designation"
            name="reference_2_designation"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Reference 2 Contact Number"
            name="reference_2_contact_number"
            type="text"
          />
        </div>
        <div className="col-md-8"></div>
        <div className="col-md-4">
          <TextInput label="Nominee Name" name="nominee_name" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Nominee Relation"
            name="nominee_relation"
            type="text"
          />
        </div>

        <div className="col-md-4">
          <TextInput label="Nominee Phone" name="nominee_phone" type="text" />
        </div>

        <div className="col-md-4">
          <TextInput
            label="Nominee Address"
            name="nominee_address"
            type="text"
          />
        </div>
        <div className="col-md-8"></div>
        <div className="col-md-4">
          <TextInput
            label="Emergency Contact Name"
            name="emergency_contact_name"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Emergency Contact Number"
            name="emergency_contact_number"
            type="text"
          />
        </div>
        <div className="col-md-12">
          <TextArea
            label="Employee Address"
            name="employee_address"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Bank Account Number"
            name="bank_account_number"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="IFSC Code"
            maxlength="11"
            name="ifsc_code"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Bank Name" name="bank_name"></TextInput>
        </div>
        <div className="col-md-4">
          <SelectBox label="Salary Payment Mode" name="salary_payment_mode">
            <option value="SELECT">Select</option>
            <option value="ONLINEs">Online</option>
            <option value="CASH">Cash</option>
            <option value="CHEQUE">Cheque</option>
          </SelectBox>
        </div>
        <div className="col-md-4">
          <TextInput
            label="Name As on Aadhaar Card"
            name="name_as_on_aadhaar_card"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Aadhaar Card Number"
            name="aadhaar_card_number"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Mobile Number" name="mobile_number" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="Designation" name="designation" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Location" name="location" type="text" />
        </div>

        <div className="col-md-4">
          <SelectBox label="State" name="state">
            <option value="">Select state</option>
            <option value="AN">Andaman and Nicobar Islands</option>
            <option value="AP">Andhra Pradesh</option>
            <option value="AR">Arunachal Pradesh</option>
            <option value="AS">Assam</option>
            <option value="BR">Bihar</option>
            <option value="CH">Chandigarh</option>
            <option value="CT">Chhattisgarh</option>
            <option value="DN">Dadra and Nagar Haveli</option>
            <option value="DD">Daman and Diu</option>
            <option value="DL">Delhi</option>
            <option value="GA">Goa</option>
            <option value="GJ">Gujarat</option>
            <option value="HR">Haryana</option>
            <option value="HP">Himachal Pradesh</option>
            <option value="JK">Jammu and Kashmir</option>
            <option value="JH">Jharkhand</option>
            <option value="KA">Karnataka</option>
            <option value="KL">Kerala</option>
            <option value="LA">Ladakh</option>
            <option value="LD">Lakshadweep</option>
            <option value="MP">Madhya Pradesh</option>
            <option value="MH">Maharashtra</option>
            <option value="MN">Manipur</option>
            <option value="ML">Meghalaya</option>
            <option value="MZ">Mizoram</option>
            <option value="NL">Nagaland</option>
            <option value="OR">Odisha</option>
            <option value="PY">Puducherry</option>
            <option value="PB">Punjab</option>
            <option value="RJ">Rajasthan</option>
            <option value="SK">Sikkim</option>
            <option value="TN">Tamil Nadu</option>
            <option value="TG">Telangana</option>
            <option value="TR">Tripura</option>
            <option value="UP">Uttar Pradesh</option>
            <option value="UT">Uttarakhand</option>
            <option value="WB">West Bengal</option>
          </SelectBox>
        </div>
        <div className="col-md-4">
          <TextInput label="District" name="district" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="E Clinic Code" name="e_clinic_code" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Partner Name" name="partner_name" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Job Location" name="job_location" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Field Ops Manager Name"
            name="field_ops_manager_name"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Field Ops Manager Contact Number"
            name="field_ops_manager_contact_no"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Field Ops Manager Email Address"
            name="field_ops_manager_email_id"
            type="email"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Branch Name" name="branch_name" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Emp CSD" name="emp_csd" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Emp CED" name="emp_ced" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label="Reported Head" name="reporting_head" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Reported Head Email Address"
            name="reporting_head_email_id"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Client Old Date OF Joining"
            name="client_old_doj"
            type="date"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Communication Email"
            name="communication_email"
            type="email"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Client Date OF Joining"
            name="client_date_of_joining"
            type="date"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Insurance Number" name="insurance_no" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Aadhaar Link Mobile"
            name="aadhar_link_mobile"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Status Pan And Adhar"
            name="status_pan_and_aadhar"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Consolidated Fees"
            name="consolidated_salary"
            type="text"
          />
        </div>

        {/* 26/3/24 */}

        <div className="col-md-4">
          <TextInput label=" PF Number" name="pf_number" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label=" Sourced By" name="sourced_by" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label=" ESI Number" name="esi_number" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label=" Full Basic " name="full_basic" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput label=" Full HRA " name="full_hra" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label=" Full Conveyance "
            name="full_conveyance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label=" Full Consolidate Fee "
            name="full_consolidated_fee"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Statutory Bonus "
            name="full_statutory_bonus"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Full CCA " name="full_cca" type="text" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Work Allowance
           "
            name="full_work_allowance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Other Allowance"
            name="full_other_allowance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Medical Reimbursement"
            name="full_medical_reimbursement"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Leave Travel Allowance"
            name="full_leave_travel_allowance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Mobile Allowance"
            name="full_mobile_allowance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Full Child Education Allowance"
            name="full_child_eduction_allowance"
            type="text"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Monthly Ctc" name="monthly_ctc" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="Annual Ctc" name="annual_ctc" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Special Allowance"
            name="special_allowance"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Gross Salary" name="gross_salary" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="In Hand Salary"
            name="in_hand_salary"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="Employer PF" name="employer_pf" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="Employee PF" name="employee_pf" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="Employer ESIC" name="employer_esic" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="Employee ESIC" name="employee_esic" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Employee Insurance"
            name="employee_insurance"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput
            label="Employer Insurance"
            name="employer_insurance"
            type="number"
          />
        </div>
        <div className="col-md-4">
          <TextInput label="LWF" name="lwf" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="PT" name="pt" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="TA" name="ta" type="number" />
        </div>
        <div className="col-md-4">
          <TextInput label="DA" name="da" type="number" />
        </div>

        {/* 26/3/24 */}

        {/* <div className="col-md-4">
          <TextInput label="Created By" name="createdBy" type="date" />
        </div>
        <div className="col-md-4">
          <TextInput label="Updated By" name="updatedBy" type="date" />
        </div> */}
      </div>

      {/* {!hidepassword && (
        <TextInput label="password" name="password" type="password" />
      )} */}

      <div className="save-button">
        <button type="submit" className="btn primary-btn w-100">
          Save & Continue
        </button>
      </div>
    </Form>
  );
};
