import React from 'react';

export const Form3 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='heading '>
              <h6>Educational Details:</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <thead>
                <tr className='bg-dark-subtle'>
                  <th scope='col' className='border border-dark'>
                    Qualification
                  </th>
                  <th scope='col' className='border border-dark'>
                    Name of Board / University
                  </th>
                  <th scope='col' className='border border-dark'>
                    Marks Obtained (%)
                  </th>
                  <th scope='col' className='border border-dark'>
                    Passing year
                  </th>
                  <th scope='col' className='border border-dark'>
                    Stream
                  </th>
                  <th scope='col' className='border border-dark'>
                    Grade
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='border border-dark'>10th</td>
                  <td className='border border-dark'>
                    <input type='text' name='board-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='marks-obtained' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='passing-year' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='stream' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='grade' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>12th</td>
                  <td className='border border-dark'>
                    <input type='text' name='board-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='marks-obtained' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='passing-year' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='stream' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='grade' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>Graduation</td>
                  <td className='border border-dark'>
                    <input type='text' name='board-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='marks-obtained' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='passing-year' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='stream' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='grade' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>Post-Graduation</td>
                  <td className='border border-dark'>
                    <input type='text' name='board-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='marks-obtained' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='passing-year' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='stream' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='grade' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>Others</td>
                  <td className='border border-dark'>
                    <input type='text' name='board-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='marks-obtained' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='passing-year' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='stream' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='grade' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='heading '>
              <h6>Professional Details:</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <thead>
                <tr className='bg-dark-subtle'>
                  <th scope='col' className='border border-dark'>
                    Name of the Organization
                  </th>
                  <th scope='col' className='border border-dark'>
                    Designation
                  </th>
                  <th scope='col' className='border border-dark'>
                    Period From
                  </th>
                  <th scope='col' className='border border-dark'>
                    Period To
                  </th>
                  <th scope='col' className='border border-dark'>
                    Reason for Leaving
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='organization-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='designation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-from' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-to' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='leaving-reason' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='organization-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='designation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-from' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-to' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='leaving-reason' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='organization-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='designation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-from' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='period-to' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='leaving-reason' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='heading '>
              <h6>Reference Details:</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <thead>
                <tr className='bg-dark-subtle'>
                  <th scope='col' className='border border-dark'>
                    Name of the person
                  </th>
                  <th scope='col' className='border border-dark'>
                    Occupation / Organization
                  </th>
                  <th scope='col' className='border border-dark'>
                    Contact Number
                  </th>
                  <th scope='col' className='border border-dark'>
                    Email ID
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-occupation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-contact' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-email' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-occupation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-contact' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-email' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-name' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-occupation' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-contact' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='refrence-email' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='col-md-12 mt-5'>
            <div className='heading '>
              <h6>PERMANENT ACCOUNT NUMBER (PAN) DETAILS (BLOCK LETTERS):</h6>
            </div>
            <table class='table mt-3 border border-dark'>
              <tbody>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    NAME OF PAN CARD
                  </th>
                  <td className='border border-dark'>
                    <input type='text' name='pan-name' />
                  </td>
                </tr>
                <tr>
                  <th scope='col' className='border border-dark bg-dark-subtle'>
                    PAN CARD NUMBER
                  </th>
                  <td className='border border-dark'>
                    <input type='number' name='pan-number' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
