import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { useAppSelector } from "../../state/hooks";
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

const PrivateRoutes = ({ children }) => {
  const user = useCheckAuthenticated();
  const location = useLocation();

  return user.user_loading ? (
    <div> Loading...</div>
  ) : !user.isAuthenticated ? (
    <Navigate to={'/login'} state={{ from: location }} replace />
  ) : (
    <div
      style={{ zIndex: 0, position: 'relative' }}
      // onClick={() => toggleNotificationData(false)}
    >
      {children}
    </div>
  );
};

export default PrivateRoutes;
