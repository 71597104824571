import React from 'react';

export const ThankYou = () => {
  return (
    <section className='main-table-form mt-3'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-3'></div>
          <div className='col-md-6'>
            <div className='border card '>
              <div className='card-body'>
                <div className='text-center'>
                  <img
                    src='/assets/images/logo.jpg'
                    style={{ height: '70px' }}
                  />
                </div>
                <hr />
                <h3 className='text-center'> Thank You </h3>
                <p className='text-center'>
                  Congratulations! You have Successfully updated the details.
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-3'></div>
        </div>
      </div>
    </section>
  );
};
