export const PAGE_TITLE = 'Employees';
export const PAGE_SINGLE_TITLE = 'Employee';
export const LINK_URL = 'employees';
export const inputFields = {
  name: {
    type: 'string',
    required: true,
    title: 'Name',
    inputType: 'text',
  },
};
export const initialValues = {
  name: '',
};

export const view_all_table = [{ name: 'Name', value: 'name' }];

export const SIDEBAR_OPTIONS = [
  {
    id: 'client_name',
    field: 'client_name',
    label: 'Client Name',
    type: 'related',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'employee_number',
    field: 'employee_number',
    label: 'Employee No',
    type: 'string',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
  {
    id: 'employee_name',
    field: 'employee_name',
    label: 'Employee name',
    type: 'string',
    search_type: 'search',
    inputType: 'text',
    condition: '',
  },

  {
    id: 'mobile_number',
    field: 'mobile_number',
    label: 'Phone',
    type: 'string',
    search_type: 'exact',
    inputType: 'text',
    condition: '',
  },
];
export const newfieldsToExport = {
  employee_name: { type: 'string', required: true, title: 'employee_name' },
  employee_number: { type: 'string', required: true, title: 'employee_number' },
  joining_date: { type: 'string', required: true, title: 'joining_date' },
  date_of_birth: { type: 'string', required: true, title: 'date_of_birth' },
  gender: { type: 'string', required: true, title: 'gender' },
  pan_number: { type: 'string', required: true, title: 'pan_number' },
  marital_status: { type: 'string', required: true, title: 'marital_status' },
  blood_group: { type: 'string', required: true, title: 'blood_group' },
  father_name: { type: 'string', required: true, title: 'father_name' },
  emergency_contact_name: {
    type: 'string',
    required: true,
    title: 'emergency_contact_name',
  },
  emergency_contact_number: {
    type: 'string',
    required: true,
    title: 'emergency_contact_number',
  },
  bank_account_number: {
    type: 'string',
    required: true,
    title: 'bank_account_number',
  },
  ifsc_code: { type: 'string', required: true, title: 'ifsc_code' },
  bank_name: { type: 'string', required: true, title: 'bank_name' },
  salary_payment_mode: {
    type: 'string',
    required: true,
    title: 'salary_payment_mode',
  },
  consolidated_fee: {
    type: 'string',
    required: true,
    title: 'consolidated_fee',
  },
  name_as_on_aadhaar_card: {
    type: 'string',
    required: true,
    title: 'name_as_on_aadhaar_card',
  },
  aadhaar_card_number: {
    type: 'string',
    required: true,
    title: 'aadhaar_card_number',
  },
  mobile_number: { type: 'string', required: true, title: 'mobile_number' },
  designation: { type: 'string', required: true, title: 'designation' },
  address: { type: 'string', required: true, title: 'address' },
  location: { type: 'string', required: true, title: 'location' },
  client_name: {
    type: 'related',
    required: true,
    title: 'client_name',
    field: 'name',
  },
  state: { type: 'string', required: true, title: 'state' },
  district: { type: 'string', required: true, title: 'district' },
  e_clinic_code: { type: 'string', required: true, title: 'e_clinic_code' },
  partner_name: { type: 'string', required: true, title: 'partner_name' },
  job_location: { type: 'string', required: true, title: 'job_location' },
  field_ops_manager_name: {
    type: 'string',
    required: true,
    title: 'field_ops_manager_name',
  },
  field_ops_manager_contact_no: {
    type: 'string',
    required: true,
    title: 'field_ops_manager_contact_no',
  },
  field_ops_manager_email_id: {
    type: 'string',
    required: true,
    title: 'field_ops_manager_email_id',
  },
  branch_name: { type: 'string', required: true, title: 'branch_name' },
  emp_csd: { type: 'string', required: true, title: 'emp_csd' },
  emp_ced: { type: 'string', required: true, title: 'emp_ced' },
  reporting_head: { type: 'string', required: true, title: 'reporting_head' },
  reporting_head_email_id: {
    type: 'string',
    required: true,
    title: 'reporting_head_email_id',
  },
  client_old_doj: { type: 'string', required: true, title: 'client_old_doj' },
  communication_email: {
    type: 'string',
    required: true,
    title: 'communication_email',
  },
  client_date_of_joining: {
    type: 'string',
    required: true,
    title: 'client_date_of_joining',
  },
  zone: { type: 'string', required: true, title: 'zone' },
  aadhar_link_mobile: {
    type: 'string',
    required: true,
    title: 'aadhar_link_mobile',
  },
  status_pan_and_aadhar: {
    type: 'string',
    required: true,
    title: 'status_pan_and_aadhar',
  },
};
