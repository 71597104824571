import React from "react";
import Header from "../../components/common/Header";
import { Footer } from "../../components/common/Footer";
import { AuthBreadCrum } from "../../components/common/AuthBreadCrum";
import { Link } from "react-router-dom";
import { useAllCRMUsers } from "../../shared/hooks/UseCRMUser";
import Pagination from "../../components/common/Pagination";
import { useCheckAuthenticated } from "../../shared/hooks/UseAuth";

function User() {
  const userData = useCheckAuthenticated();
  const [data] = useAllCRMUsers();
  const { crmusers, pages, page, total_crmusers, crmusers_loading } = data;
  return (
    <div>
      <Header />
      <AuthBreadCrum title={"Users"} />

      <section className="profile-main ptb-30">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-6">
              <div className="main-sec-heading">
                <h1>Total Users</h1>
              </div>
            </div>
            <div className="col-md-6 col-6">
              <div className="add-student-btn">
                {userData &&
                  userData.user &&
                  userData.user.role &&
                  userData.user.role === "ADMIN" && (
                    <Link to="/users/add" className="btn primary-btn-h ">
                      Add User
                    </Link>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <FilterComponent /> */}

      <section className="students-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p> {total_crmusers} Records Found </p>
              <div className="myapplication-table">
                <table className="table table-responsive table-sm  table-bordered table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col ">Name</th>

                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      {userData &&
                        userData.user &&
                        userData.user.role &&
                        userData.user.role === "ADMIN" && (
                          <th scope="col">Actions</th>
                        )}
                    </tr>
                  </thead>
                  <tbody>
                    {crmusers &&
                      crmusers.map((e) => {
                        return (
                          <tr key={e._id}>
                            <td>{e.name} </td>
                            <td>{e.email} </td>
                            <td>{e.phone} </td>
                            {userData &&
                              userData.user &&
                              userData.user.role &&
                              userData.user.role === "ADMIN" && (
                                <td className="status-ic">
                                  <Link
                                    to={`/users/${e._id}/view`}
                                    className="badge badge-edit "
                                  >
                                    View
                                  </Link>
                                  <Link
                                    to={`/users/${e._id}/edit`}
                                    className="badge badge-draft"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>

              <Pagination
                count={total_crmusers}
                pages={pages}
                loading={crmusers_loading}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
}

export default User;
