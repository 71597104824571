import React from 'react';

export const Form5 = ({ formik }) => {
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 '>
            <div className='heading text-center'>
              <h5 className='fw-bold mb-4'>Declaration</h5>
            </div>
            <div>
              <p>
                I also confirm that all the information I have given in the form
                is correct and to the best of my knowledge.
              </p>
              <p>
                I,
                <input
                  type='text'
                  name='name'
                  className='border-bottom border-dark'
                />
                hereby declare the following information in connection with my
                joining your organization:
              </p>
              <ul class=' list-group-numbered'>
                <li class='list-group-item'>
                  AI confirm that all the details provided in my application,
                  resume, and other supporting documents are true, accurate, and
                  complete to the best of my knowledge.
                </li>
                <li class='list-group-item'>
                  I understand and agree to comply with all the policies, rules,
                  and regulations of the organization as communicated to me
                  during the onboarding process and throughout my employment.
                </li>
                <li class='list-group-item'>
                  I understand that any misrepresentation, falsification, or
                  omission of information in my application or during the
                  onboarding process may result in the withdrawal of any job
                  offer or termination of employment, even after joining the
                  organization.
                </li>
                <li class='list-group-item'>
                  I understand that my employment is subject to successful
                  background verification, reference checks, and any other
                  applicable pre-employment screening processes as required by
                  the organization.
                </li>
                <li class='list-group-item'>
                  I agree to maintain the confidentiality of any sensitive
                  information, proprietary knowledge, or trade secrets to which
                  I may have access during my employment with the organization.
                </li>
                <li class='list-group-item'>
                  I authorize the organization to process my personal data and
                  share it with relevant third parties for legitimate
                  employment-related purposes, in accordance with applicable
                  privacy laws and the organization's privacy policy.
                </li>
                <li class='list-group-item'>
                  I declare that I am not subject to any restrictive covenants,
                  non-compete agreements, or other legal obligations that could
                  prevent me from fully engaging in my role at the organization.
                </li>
              </ul>
              <p className='mt-4'>
                I hereby affirm that I have read and understood the above
                declaration, and I voluntarily provide this information as part
                of my joining process.
              </p>

              <div>
                <div className='d-flex'>
                  <label className='fw-bold '>Signature of Associate:</label>
                  <input type='text' name='' className='ms-3' />
                </div>
                <div className='d-flex'>
                  <label className='fw-bold'>Name:</label>
                  <input type='text' name='name' className='ms-3' />
                </div>
                <div className='d-flex'>
                  <label className='fw-bold'> Location:</label>
                  <input type='text' name='loaction' className='ms-3' />
                </div>
                <div className='d-flex'>
                  <label className='fw-bold'>Date:</label>
                  <input type='text' name='date' className='ms-3' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
