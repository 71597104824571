import moment from 'moment';
import { useState } from 'react';
import * as XLSX from 'xlsx';

export const UseFilter = () => {
  const exportXLSXData = (data, sheet_name, export_name) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, sheet_name);
    XLSX.writeFile(wb, `${export_name}.xlsx`);
  };
  return [exportXLSXData];
};

export const UseDataForExcelPrograms = () => {
  const [exportData, setExportData] = useState([]);
  const convertToReadable = (rawData, inputFields) => {
    if (inputFields) {
      let freshData = [];
      const newFreshData = rawData.map((data, index) => {
        let newItem = {};
        newItem['SR No'] = index + 1;
        newItem['employee_name'] = data.employee_name;
        newItem['employee_number'] = data.employee_number;
        newItem['joining_date'] = data.joining_date;
        newItem['date_of_birth'] = data.date_of_birth
          ? moment(data.date_of_birth).format('DD-MMM-YYYY')
          : '';
        newItem['gender'] = data.gender;
        newItem['pan_number'] = data.pan_number;
        newItem['marital_status'] = data.marital_status;
        newItem['blood_group'] = data.blood_group;
        newItem['father_name'] = data.father_name;
        newItem['emergency_contact_name'] = data.emergency_contact_name;
        newItem['emergency_contact_number'] = data.emergency_contact_number;
        // check if account no starts with 0
        if (
          data.bank_account_number &&
          data.bank_account_number.toString().startsWith('0')
        ) {
          newItem['bank_account_number'] = `'${data.bank_account_number}`;
        } else {
          newItem['bank_account_number'] = data.bank_account_number;
        }
        newItem['ifsc_code'] = data.ifsc_code;
        newItem['bank_name'] = data.bank_name;
        newItem['salary_payment_mode'] = data.salary_payment_mode;
        newItem['consolidated_salary'] = data.consolidated_salary;
        newItem['name_as_on_aadhaar_card'] = data.name_as_on_aadhaar_card;
        newItem['aadhaar_card_number'] = data.aadhaar_card_number;
        newItem['mobile_number'] = data.mobile_number;
        newItem['designation'] = data.designation;
        newItem['employee_address'] = data.employee_address;
        newItem['location'] = data.location;
        newItem['client_name'] = data.client_name?.name;
        newItem['state'] = data.state;
        newItem['district'] = data.district;
        newItem['e_clinic_code'] = data.e_clinic_code;
        newItem['partner_name'] = data.partner_name;
        newItem['job_location'] = data.job_location;
        newItem['field_ops_manager_name'] = data.field_ops_manager_name;
        newItem['field_ops_manager_contact_no'] =
          data.field_ops_manager_contact_no;
        newItem['field_ops_manager_email_id'] = data.field_ops_manager_email_id;
        newItem['branch_name'] = data.branch_name;
        newItem['emp_csd'] = data.emp_csd;
        newItem['emp_ced'] = data.emp_ced;
        newItem['reporting_head'] = data.reporting_head;
        newItem['reporting_head_email_id'] = data.reporting_head_email_id;
        newItem['client_old_doj'] = data.client_old_doj;
        newItem['communication_email'] = data.communication_email;
        newItem['client_date_of_joining'] = data.client_date_of_joining;
        newItem['zone'] = data.zone;
        newItem['aadhar_link_mobile'] = data.aadhar_link_mobile;
        newItem['status_pan_and_aadhar'] = data.status_pan_and_aadhar;
        newItem['pf_number'] = data.pf_number;
        newItem['sourced_by'] = data.sourced_by;
        newItem['esi_number'] = data.esi_number;
        newItem['full_basic'] = data.full_basic;
        newItem['full_hra'] = data.full_hra;
        newItem['full_conveyance'] = data.full_conveyance;
        newItem['full_consolidated_fee'] = data.full_consolidated_fee;
        newItem['full_statutory_bonus'] = data.full_statutory_bonus;
        newItem['full_cca'] = data.full_cca;
        newItem['full_work_allowance'] = data.full_work_allowance;
        newItem['full_other_allowance'] = data.full_other_allowance;
        newItem['full_medical_reimbursement'] = data.full_medical_reimbursement;
        newItem['full_leave_travel_allowance'] =
          data.full_leave_travel_allowance;
        newItem['full_mobile_allowance'] = data.full_mobile_allowance;
        newItem['full_child_eduction_allowance'] =
          data.full_child_eduction_allowance;
        newItem['employer_insurance'] = data.employer_insurance;
newItem['employee_insurance'] = data.employee_insurance;
newItem['employer_esic'] = data.employer_esic;
newItem['employee_esic'] = data.employee_esic;
newItem['employer_pf'] = data.employer_pf;
newItem['employee_pf'] = data.employee_pf;
newItem['monthly_ctc'] = data.monthly_ctc;
newItem['in_hand_salary'] = data.in_hand_salary;
newItem['pf_number'] = data.pf_number;
newItem['sourced_by'] = data.sourced_by;
newItem['esi_number'] = data.esi_number;
newItem['full_basic'] = data.full_basic;
newItem['full_hra'] = data.full_hra;
newItem['full_conveyance'] = data.full_conveyance;
newItem['full_consolidated_fee'] = data.full_consolidated_fee;
newItem['full_statutory_bonus'] = data.full_statutory_bonus;
newItem['full_cca'] = data.full_cca;
newItem['full_work_allowance'] = data.full_work_allowance;
newItem['full_other_allowance'] = data.full_other_allowance;
newItem['full_medical_reimbursement'] = data.full_medical_reimbursement;
newItem['full_leave_travel_allowance'] = data.full_leave_travel_allowance;
newItem['full_mobile_allowance'] = data.full_mobile_allowance;
newItem['full_child_eduction_allowance'] = data.full_child_eduction_allowance;
newItem['annual_ctc'] = data.annual_ctc;
newItem['lwf'] = data.lwf;
newItem['pt'] = data.pt;
newItem['ta'] = data.ta;
newItem['da'] = data.da;
newItem['special_allowance'] = data.special_allowance;
newItem['gross_salary'] = data.gross_salary;

newItem['father_dob'] = data.father_dob;
newItem['mother_name'] = data.mother_name;
newItem['mother_dob'] = data.mother_dob;
newItem['spouse_name'] = data.spouse_name;
newItem['spouse_dob'] = data.spouse_dob;
newItem['child_1_name'] = data.child_1_name;
newItem['child_1_dob'] = data.child_1_dob;
newItem['child_2_name'] = data.child_2_name;
newItem['child_2_dob'] = data.child_2_dob;
newItem['reference_1_name'] = data.reference_1_name;
newItem['reference_1_employee_code'] = data.reference_1_employee_code;
newItem['reference_1_designation'] = data.reference_1_designation;
newItem['reference_1_contact_number'] = data.reference_1_contact_number;
newItem['reference_2_name'] = data.reference_2_name;
newItem['reference_2_employee_code'] = data.reference_2_employee_code;
newItem['reference_2_designation'] = data.reference_2_designation;
newItem['reference_2_contact_number'] = data.reference_2_contact_number;
newItem['nominee_name'] = data.nominee_name;
newItem['nominee_relation'] = data.nominee_relation;
newItem['nominee_phone'] = data.nominee_phone;
newItem['nominee_address'] = data.nominee_address;

        return newItem;
      });
      setExportData(newFreshData);
    }
  };
  return [convertToReadable, exportData];
};
