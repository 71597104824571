import {
  GET_CRMUSERS_STATED,
  GET_CRMUSERS,
  GET_CRMUSERS_ENDED,
  ADD_CRMUSER_STATED,
  ADD_CRMUSER,
  ADD_CRMUSER_ENDED,
  EDIT_CRMUSER_STATED,
  EDIT_CRMUSER,
  EDIT_CRMUSER_ENDED,
  GET_CRMUSER_STATED,
  GET_CRMUSER,
  GET_CRMUSER_ENDED,
  GET_ALL_CRMUSERS_STATED,
  GET_ALL_CRMUSERS,
  GET_ALL_CRMUSERS_ENDED,
} from '../types/crmuser_type';

const initialState = {
  crmusers_loading: true,
  crmusers: null,
  page: null,
  pages: null,
  total_crmusers: 0,

  crmuser: null,
  crmuser_loading: null,

  loading: true,

  crmuser_message: null,
  all_crmusers: null,
  all_crmusers_loading: null,
  add_crmuser_loading: true,
  edit_crmuser_loading: true,
};

export const crmuser_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CRMUSERS_STATED:
      return {
        ...state,
        crmusers: null,
        pages: null,
        page: null,
        total_crmusers: 0,
        crmusers_loading: true,
      };
    case GET_CRMUSERS:
      return {
        ...state,
        crmusers: payload.crmusers,
        pages: payload.pages,
        page: payload.page,
        crmusers_loading: false,
        total_crmusers: payload.total_crmusers,
      };
    case GET_CRMUSERS_ENDED:
      return {
        ...state,
        crmusers_loading: false,
      };
    case GET_ALL_CRMUSERS_STATED:
      return {
        ...state,
        all_crmusers_loading: true,
        all_crmusers: null,
      };
    case GET_ALL_CRMUSERS:
      return {
        ...state,
        all_crmusers: payload,
      };
    case GET_ALL_CRMUSERS_ENDED:
      return {
        ...state,
        all_crmusers_loading: false,
      };

    case ADD_CRMUSER_STATED:
      return {
        ...state,
        crmuser_message: null,
        add_crmuser_loading: true,
      };
    case ADD_CRMUSER:
      return {
        ...state,
        crmuser_message: payload,
      };
    case ADD_CRMUSER_ENDED:
      return {
        ...state,
        add_crmuser_loading: false,
      };
    case GET_CRMUSER_STATED:
      return {
        ...state,
        crmuser: null,
        crmuser_loading: true,
      };
    case GET_CRMUSER:
      return {
        ...state,
        crmuser: payload,
      };
    case GET_CRMUSER_ENDED:
      return {
        ...state,
        crmuser_loading: false,
      };
    case EDIT_CRMUSER_STATED:
      return {
        ...state,
        crmuser_message: null,
        edit_crmuser_loading: true,
      };
    case EDIT_CRMUSER:
      return {
        ...state,
        crmuser_message: payload,
      };
    case EDIT_CRMUSER_ENDED:
      return {
        ...state,
        edit_crmuser_loading: false,
      };

    default:
      return state;
  }
};
