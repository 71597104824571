import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as qs from 'qs';
export default function LimitComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const [page_size, setPage_size] = useState(
    queryParams.page_size ? queryParams.page_size : 20
  );
  const handlePageSizeChange = (value) => {
    queryParams.page_size = value;
    const query = qs.stringify(queryParams, {
      encodeValuesOnly: true, // prettify url
    });
    setPage_size(value);
    navigate(`${location.pathname}?${query}`);
  };
  return (
    <div className='pb-2 pr-2'>
      Showing
      <select
        onChange={(e) => handlePageSizeChange(e.target.value)}
        value={page_size}
        className='border p-1 m-2 inline-block'
      >
        <option value={20}> 20 </option>
        <option value={50}> 50 </option>
        <option value={100}> 100 </option>
        <option value={200}> 200 </option>
      </select>
    </div>
  );
}
