import React, { useState, useEffect } from 'react';

import { Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddFormInput from '../../components/AddFormInput';
import TableFieldComponent from '../../components/table_field/TableFieldComponent';

import Sidebar from '../../components/common/Sidebar';
import {
  useAllEmployees,
  useAllEmployeesReport,
  useDeleteBulkEmployee,
  useDeleteEmployee,
} from '../../shared/hooks/UseEmployee';
import Pagination from '../../components/common/Pagination';
import {
  UseFilter,
  UseDataForExcelPrograms,
} from '../../shared/hooks/UseExcel';
import {
  newfieldsToExport,
  SIDEBAR_OPTIONS,
} from '../../shared/enums/employees_enum';
import ExportComponent from '../../components/common/ExportComponent';
import FilterComponent from '../../components/filter/FilterComponent';
import SidebarFilter from '../../components/common/SidebarFilter';
import Header from '../../components/common/Header';
import { Alerts } from '../../components/common/Alerts';
import moment from 'moment';
import { useGetAllClients } from '../../shared/hooks/UseClient';
import { api } from '../../domain/api';
import { useCheckAuthenticated, useLogout } from '../../shared/hooks/UseAuth';
import LimitComponent from '../../components/LimitComponent';
import * as qs from 'qs';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alert_action';
export const Home = (props) => {
  const [showExportButton, setShowExportButton] = useState(false);
  const queryParams = qs.parse(window.location.search.replace('?', ''));
  const { getClients, clients } = useGetAllClients();
  const userData = useCheckAuthenticated();

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const navigate = useNavigate();

  const { reportData, getData, loading_report } = useAllEmployeesReport();
  const { deleteBulkData } = useDeleteBulkEmployee();

  const ToggleSidebar = () => {
    sidebarOpen === true ? setsidebarOpen(false) : setsidebarOpen(true);
  };
  const { data, setPageNumber } = useAllEmployees();
  const { employees, employees_loading, pages, page, total_employees } = data;
  const dispatch = useDispatch();
  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForExcelPrograms();
  const [message, showMessage] = useState('');
  const sentEmail = async (type, id) => {
    try {
      if (type == 'welcome') {
        console.log('welcome');
        const { data } = await api.post(`/employees/welcome/sent/${id}`);
        showMessage(data.message);
        dispatch(setAlert(data.message, 'success'));
      } else if (type === 'form') {
        const { data } = await api.post(`/employees/onboarding/sent/${id}`);
        showMessage(data.message);
        dispatch(setAlert(data.message, 'success'));
      } else if (type === 'followup') {
        const { data } = await api.post(`/employees/followup/sent/${id}`);
        showMessage(data.message);
        dispatch(setAlert(data.message, 'success'));
      }
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (reportData) {
      convertToReadable(reportData, newfieldsToExport);
    }
  }, [reportData]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Employees', 'employees');
  };
  // Each Column Definition results in one Column.
  const [deleteEmployeeID, setDeleteEmployeeID] = useState(null);
  const { deleteData } = useDeleteEmployee();

  const deleteBtnClicked = () => {
    deleteData(deleteEmployeeID);
    setDeleteEmployeeID(null);
  };
  const [checkedEmployees, setCheckedEmployees] = useState([]);

  const selectAllEmployees = () => {
    if (checkedEmployees.length === employees.length) {
      setCheckedEmployees([]);
    } else {
      const employeeIDs = employees.map((employee) => employee._id);
      setCheckedEmployees(employeeIDs);
    }
  };
  const deleteBulkFunction = () => {
    deleteBulkData({ employees: checkedEmployees });
  };

  useEffect(() => {
    getClients();
  }, []);
  // TODO: Do Something
  return (
    <>
      <Header />

      <section className='main-table-form mt-3'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-3'>
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={{
                  client_name:
                    userData &&
                    userData.user &&
                    userData.user.role &&
                    userData.user.role === 'ADMIN'
                      ? clients &&
                        clients?.clients &&
                        clients?.clients.map((item) => {
                          return {
                            label: item.name,
                            value: item._id,
                          };
                        })
                      : userData?.user?.clients.map((item) => {
                          return {
                            label: item.name,
                            value: item._id,
                          };
                        }),
                }}
              />
            </div>
            <div className='col-md-9'>
              <div className='button-flex-im-ex'>
                <div>
                  <Link to='/bulks' className='button-5'>
                    Import Data
                  </Link>
                </div>
                <div>
                  <Link to='/bulk-delete' className='btn btn-danger'>
                    Delete Data
                  </Link>
                </div>
                <div>
                  {showExportButton ? (
                    !loading_report ? (
                      <ExportComponent handleOnExport={handleOnExport} />
                    ) : (
                      <button className='btn btn-generate'>
                        Generating...{' '}
                      </button>
                    )
                  ) : (
                    <button
                      className='btn btn-generate'
                      onClick={() => {
                        setShowExportButton(true);
                        getData();
                      }}
                    >
                      Generate Report
                    </button>
                  )}
                </div>
                <div>
                  <a href='/MasterHeader.xlsx' className='button-5'>
                    Download Sample
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <p> {total_employees} Records Found </p>
              <LimitComponent />

              {checkedEmployees && checkedEmployees.length > 0 && (
                <div>
                  <button
                    className='btn btn-danger'
                    onClick={() => deleteBulkFunction()}
                  >
                    Delete
                  </button>
                </div>
              )}
              <div className='myapplication-table team-hr-table'>
                <table className='table table-responsive table-sm  table-bordered table-striped  '>
                  <thead>
                    <tr>
                      <th scope='col'>
                        <input
                          type='checkbox'
                          onClick={() => selectAllEmployees()}
                        />
                      </th>
                      <th scope='col '>Action</th>
                      <th scope='col '>Sr No</th>
                      <th scope='col '>Status</th>
                      <th scope='col '>Employee Number</th>
                      <th scope='col '>Employee Title</th>
                      <th scope='col'>Employee Name</th>
                      <th scope='col'>Joining Date</th>
                      <th scope='col'>Date Of Birth</th>
                      <th scope='col'>Gender</th>
                      <th scope='col'>PAN Number</th>
                      <th scope='col'>Marital Status</th>
                      <th scope='col'>Blood Group</th>
                      <th scope='col'>Father's Name</th>
                      <th scope='col'>Emergency Contact Name</th>
                      <th scope='col'>Emergency Contact Number</th>
                      <th scope='col'>Bank Account Number</th>
                      <th scope='col'>IFSC Code</th>
                      <th scope='col'>Bank Name</th>
                      <th scope='col'>Salary Payment Mode</th>
                      <th scope='col'>Consolidated Fee </th>
                      <th scope='col'>Name (As on Aadhaar Card)</th>
                      <th scope='col'>Aadhaar Card Number </th>
                      <th scope='col'>Mobile Number</th>
                      <th scope='col'>Designation</th>
                      <th scope='col'>Address</th>
                      <th scope='col'>Location</th>
                      <th scope='col'>Client Name</th>
                      <th scope='col'>State</th>
                      <th scope='col'>District</th>
                      <th scope='col'>E Clinic Code</th>
                      <th scope='col'>Partner Name</th>
                      <th scope='col'>Job Location</th>
                      <th scope='col'>Field Ops Manager Name</th>
                      <th scope='col'>Field Ops Manager Contact No</th>
                      <th scope='col'>Field Ops Manager Email ID</th>
                      <th scope='col'>Branch Name</th>
                      <th scope='col'>Emp CSD</th>
                      <th scope='col'>Emp CED</th>
                      <th scope='col'>Reporting Head</th>
                      <th scope='col'>Reporting Head Email ID</th>
                      <th scope='col'>Client Old DOJ</th>
                      <th scope='col'>Communication Email</th>
                      <th scope='col'>Client Date Of Joining</th>
                      <th scope='col'>Insurance Number</th>
                      <th scope='col'>Aadhar Link Mobile</th>
                      <th scope='col'>Status (PAN & AADHAR) Linked</th>
                      <th scope='col'>Created By</th>
                      <th scope='col'>Updated By</th>
                      <th scope='col'>PF Number</th>
                      <th scope='col'>Sourced By</th>
                      <th scope='col'>ESI Number</th>
                      <th scope='col'> Full Basic</th>
                      <th scope='col'> Full HRA </th>
                      <th scope='col'> Full Conveyance </th>
                      <th scope='col'> Full Consolidate Fee </th>
                      <th scope='col'> Full Statutory Bonus </th>
                      <th scope='col'>Full CCA </th>
                      <th scope='col'>Full Work Allowance </th>
                      <th scope='col'>Full Other Allowance </th>
                      <th scope='col'>Full Medical Reimbursement </th>
                      <th scope='col'>Full Leave Travel Allowance </th>
                      <th scope='col'>Full Mobile Allowance </th>
                      <th scope='col'>Full Child Education Allowance </th>

                      <th scope='col'>Monthly Ctc</th>
                      <th scope='col'>Annual Ctc</th>
                      <th scope='col'>Special Allowance</th>
                      <th scope='col'>Gross Salary</th>
                      <th scope='col'>In Hand Salary</th>
                      <th scope='col'>Employer PF</th>
                      <th scope='col'>Employee PF</th>
                      <th scope='col'>Employer ESIC</th>
                      <th scope='col'>Employee ESIC</th>
                      <th scope='col'>Employer Insurance</th>
                      <th scope='col'>Employee Insurance</th>
                      <th scope='col'>LWF</th>
                      <th scope='col'>PT</th>
                      <th scope='col'>TA</th>
                      <th scope='col'>DA</th>
                      <th> {'father_dob'.split('_').join(' ')} </th>
                      <th> {'mother_name'.split('_').join(' ')} </th>
                      <th> {'mother_dob'.split('_').join(' ')} </th>
                      <th> {'spouse_name'.split('_').join(' ')} </th>
                      <th> {'spouse_dob'.split('_').join(' ')} </th>
                      <th> {'child_1_name'.split('_').join(' ')} </th>
                      <th> {'child_1_dob'.split('_').join(' ')} </th>
                      <th> {'child_2_name'.split('_').join(' ')} </th>
                      <th> {'child_2_dob'.split('_').join(' ')} </th>
                      <th> {'reference_1_name'.split('_').join(' ')} </th>
                      <th>
                        {' '}
                        {'reference_1_employee_code'.split('_').join(' ')}{' '}
                      </th>
                      <th>
                        {' '}
                        {'reference_1_designation'.split('_').join(' ')}{' '}
                      </th>
                      <th>
                        {' '}
                        {'reference_1_contact_number'.split('_').join(' ')}{' '}
                      </th>
                      <th> {'reference_2_name'.split('_').join(' ')} </th>
                      <th>
                        {' '}
                        {'reference_2_employee_code'.split('_').join(' ')}{' '}
                      </th>
                      <th>
                        {' '}
                        {'reference_2_designation'.split('_').join(' ')}{' '}
                      </th>
                      <th>
                        {' '}
                        {'reference_2_contact_number'.split('_').join(' ')}{' '}
                      </th>
                      <th> {'nominee_name'.split('_').join(' ')} </th>
                      <th> {'nominee_relation'.split('_').join(' ')} </th>
                      <th> {'nominee_phone'.split('_').join(' ')} </th>
                      <th> {'nominee_address'.split('_').join(' ')} </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees &&
                      employees.map((item, j) => {
                        return (
                          <tr key={item._id}>
                            <td style={{ minWidth: '50px' }}>
                              <input
                                type='checkbox'
                                checked={checkedEmployees.includes(item._id)}
                                onClick={(e) => {
                                  if (e.target.checked) {
                                    setCheckedEmployees([
                                      ...checkedEmployees,
                                      item._id,
                                    ]);
                                  } else {
                                    setCheckedEmployees(
                                      checkedEmployees.filter(
                                        (id) => id !== item._id
                                      )
                                    );
                                  }
                                }}
                              />
                            </td>

                            <td>
                              <div className='dropdown dropdown-1'>
                                <button
                                  className='btn btn-secondary dropdown-toggle'
                                  type='button'
                                  id='dropdownMenuButton'
                                  data-toggle='dropdown'
                                  aria-haspopup='true'
                                  aria-expanded='false'
                                >
                                  <i className='fa fa-cog'></i> Action
                                </button>
                                <div
                                  className='dropdown-menu dropdown-menu-1'
                                  aria-labelledby='dropdownMenuButton'
                                >
                                  <Link
                                    className='dropdown-item'
                                    to={`/onboarding/employee/${item._id}/view`}
                                  >
                                    <i className='fa fa-pencil'></i> Onboarding
                                    Form
                                  </Link>
                                  <Link
                                    className='dropdown-item'
                                    to={`/onboarding/employee/${item._id}/verify`}
                                  >
                                    <i className='fa fa-check'></i> Verify
                                    Details
                                  </Link>
                                  <Link
                                    className='dropdown-item '
                                    to={`/employee/${item._id}/edit`}
                                  >
                                    <i className='fa fa-edit'></i> Edit
                                  </Link>
                                  <div
                                    className='dropdown-item '
                                    onClick={() =>
                                      sentEmail('welcome', item._id)
                                    }
                                  >
                                    <i className='fa fa-envelope'></i> Send
                                    welcome email
                                  </div>
                                  <div
                                    className='dropdown-item '
                                    onClick={() => sentEmail('form', item._id)}
                                  >
                                    <i className='fa fa-envelope'></i> Send
                                    onboarding form
                                  </div>
                                  <div
                                    className='dropdown-item '
                                    onClick={() =>
                                      sentEmail('followup', item._id)
                                    }
                                  >
                                    <i className='fa fa-envelope'></i> Send
                                    FollowUp Email
                                  </div>

                                  <a
                                    className='dropdown-item text-danger border-top'
                                    onClick={() =>
                                      setDeleteEmployeeID(item._id)
                                    }
                                  >
                                    <i className='fa fa-trash'></i> Delete
                                  </a>
                                </div>
                              </div>
                            </td>

                            <td>
                              {(page - 1) *
                                (queryParams.page_size
                                  ? Number(queryParams?.page_size)
                                  : 20) +
                                j +
                                1}
                            </td>
                            <td style={{ minWidth: '100px' }}>
                              <p>
                                Pending :
                                {
                                  Object.values(item).filter(
                                    (item) =>
                                      item?.document_status === 'pending'
                                  ).length
                                }{' '}
                              </p>
                              <p>
                                Accepted :{' '}
                                {
                                  Object.values(item).filter(
                                    (item) =>
                                      item?.document_status === 'approved'
                                  ).length
                                }
                              </p>
                              <p>
                                Rejected :{' '}
                                {
                                  Object.values(item).filter(
                                    (item) =>
                                      item?.document_status === 'rejected'
                                  ).length
                                }
                              </p>
                            </td>

                            <td>
                              <TableFieldComponent
                                label={'Employee Number'}
                                field={'employee_number'}
                                value={item.employee_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employee Title'}
                                field={'employee_title'}
                                value={item.employee_title}
                                required={true}
                                inputType='input'
                              />
                            </td>

                            <td>
                              <TableFieldComponent
                                label={'Employee Name'}
                                field={'employee_name'}
                                value={item.employee_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={'Joining Date'}
                                field={'joining_date'}
                                value={
                                  item.joining_date &&
                                  moment(item.joining_date).format(
                                    'DD-MMM-YYYY'
                                  )
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={'Date of Birth'}
                                field={'date_of_birth'}
                                value={
                                  item.date_of_birth &&
                                  moment(item.date_of_birth).format(
                                    'DD-MMM-YYYY'
                                  )
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Gender'}
                                field={'gender'}
                                value={item.gender}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Pan Number'}
                                field={'pan_number'}
                                value={item.pan_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Marital Status'}
                                field={'marital_status'}
                                value={item.marital_status}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td class='status-ic'>
                              <TableFieldComponent
                                label={'Blood Group'}
                                field={'blood_group'}
                                value={item.blood_group}
                                required={true}
                                inputType='input'
                              />
                              {/* <a class="badge badge inclass">{value}</a> */}
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Father Name'}
                                field={'father_name'}
                                value={item.father_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Emergency Contact Name'}
                                field={'emergency_contact_name'}
                                value={item.emergency_contact_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Emergency Contact Number'}
                                field={'emergency_contact_number'}
                                value={item.emergency_contact_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Bank Account Number'}
                                field={'bank_account_number'}
                                value={item.bank_account_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'IFSC Code'}
                                field={'ifsc_code'}
                                value={item.ifsc_code}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={'Bank Name'}
                                field={'bank_name'}
                                value={item.bank_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Salary Payment Mode'}
                                field={'salary_payment_mode'}
                                value={item.salary_payment_mode}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Consolidated Fee'}
                                field={'consolidated_salary'}
                                value={item.consolidated_salary}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Name as on Aadhar Card'}
                                field={'name_as_on_aadhaar_card'}
                                value={item.name_as_on_aadhaar_card}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Aadhar Card Number'}
                                field={'aadhaar_card_number'}
                                value={item.aadhaar_card_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Mobile Number'}
                                field={'mobile_number'}
                                value={item.mobile_number}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={' Designation'}
                                field={'designation'}
                                value={item.designation}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='address'>
                              {' '}
                              <TableFieldComponent
                                label={' Address'}
                                field={'employee_address'}
                                value={item.employee_address}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Location'}
                                field={'location'}
                                value={item.location}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='address'>
                              {' '}
                              <TableFieldComponent
                                label={' Client Name'}
                                field={'client_name'}
                                value={item.client_name?.name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' State'}
                                field={'state'}
                                value={item.state}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' District'}
                                field={'district'}
                                value={item.district}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' E Clinic Code'}
                                field={'e_clinic_code'}
                                value={item.e_clinic_code}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Partner Name'}
                                field={'partner_name'}
                                value={item.partner_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Job Location'}
                                field={'job_location'}
                                value={item.job_location}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Field OPS Manager Name'}
                                field={'field_ops_manager_name'}
                                value={item.field_ops_manager_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Field OPS Manager Contact Number'}
                                field={'field_ops_manager_contact_no'}
                                value={item.field_ops_manager_contact_no}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Field OPS Manager Contact Email ID'}
                                field={'field_ops_manager_email_id'}
                                value={item.field_ops_manager_email_id}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={' Branch Name'}
                                field={'branch_name'}
                                value={item.branch_name}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={' Emp Csd'}
                                field={'emp_csd'}
                                value={
                                  item.emp_csd &&
                                  moment(item.emp_csd).format('DD-MMM-YYYY')
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={' Emp Ced'}
                                field={'emp_ced'}
                                value={
                                  item.emp_ced &&
                                  moment(item.emp_ced).format('DD-MMM-YYYY')
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Reporting Head'}
                                field={'reporting_head'}
                                value={item.reporting_head}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Reporting Head Email ID'}
                                field={'reporting_head_email_id'}
                                value={item.reporting_head_email_id}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={'Client Old DOJ'}
                                field={'client_old_doj'}
                                value={
                                  item.client_old_doj &&
                                  moment(item.client_old_doj).format(
                                    'DD-MMM-YYYY'
                                  )
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Communication Email '}
                                field={'communication_email'}
                                value={item.communication_email}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td className='date'>
                              {' '}
                              <TableFieldComponent
                                label={'Client Date OF Joining '}
                                field={'client_date_of_joining'}
                                value={
                                  item.client_date_of_joining &&
                                  moment(item.client_date_of_joining).format(
                                    'DD-MMM-YYYY'
                                  )
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Insurance Number '}
                                field={'insurance_no'}
                                value={item.insurance_no}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              {' '}
                              <TableFieldComponent
                                label={'Aadhaar Link Mobile '}
                                field={'aadhar_link_mobile'}
                                value={item.aadhar_link_mobile}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Status pan and Aadhaar Linked  '}
                                field={'status_pan_and_aadhar'}
                                value={item.status_pan_and_aadhar}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Created By  '}
                                field={'created_by'}
                                value={
                                  item.created_by ? item.created_by.name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Updated At'}
                                field={'updated_by'}
                                value={
                                  item.updates &&
                                  item.updates.length > 0 &&
                                  item.updates[item.updates.length - 1] &&
                                  item.updates[item.updates.length - 1]
                                    .updated_by
                                    ? item.updates[item.updates.length - 1]
                                        .updated_by.name
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'PF Number '}
                                field={'pf_number'}
                                value={item.pf_number ? item.pf_number : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Sourced By '}
                                field={'sourced_by'}
                                value={item.sourced_by ? item.sourced_by : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'ESI Number '}
                                field={'esi_number'}
                                value={item.esi_number ? item.esi_number : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Basic'}
                                field={'full_basic'}
                                value={item.full_basic ? item.full_basic : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full HRA'}
                                field={'full_hra'}
                                value={item.full_hra ? item.full_hra : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Conveyance'}
                                field={'full_conveyance'}
                                value={
                                  item.full_conveyance
                                    ? item.full_conveyance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={' Full Consolidate Fee '}
                                field={'full_consolidated_fee'}
                                value={
                                  item.full_consolidated_fee
                                    ? item.full_consolidated_fee
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Statutory Bonus'}
                                field={'full_statutory_bonus'}
                                value={
                                  item.full_statutory_bonus
                                    ? item.full_statutory_bonus
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full CCA'}
                                field={'full_cca'}
                                value={item.full_cca ? item.full_cca : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Work Allowance'}
                                field={'full_work_allowance'}
                                value={
                                  item.full_work_allowance
                                    ? item.full_work_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Other Allowance'}
                                field={'full_other_allowance'}
                                value={
                                  item.full_other_allowance
                                    ? item.full_other_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Medical Reimbursement'}
                                field={'full_medical_reimbursement'}
                                value={
                                  item.full_medical_reimbursement
                                    ? item.full_medical_reimbursement
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Leave Travel Allowance'}
                                field={'full_leave_travel_allowance'}
                                value={
                                  item.full_leave_travel_allowance
                                    ? item.full_leave_travel_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Mobile Allowance'}
                                field={'full_mobile_allowance'}
                                value={
                                  item.full_mobile_allowance
                                    ? item.full_mobile_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Full Child Education Allowance'}
                                field={'full_child_eduction_allowance'}
                                value={
                                  item.full_child_eduction_allowance
                                    ? item.full_child_eduction_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            {/* <td>
                              <TableFieldComponent
                                label={'Employer Insurance'}
                                field={'employer_insurance'}
                                value={
                                  item.employer_insurance
                                    ? item.employer_insurance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td> */}
                            <td>
                              {/* {item?.monthly_ctc && <TableFieldComponent
                                label={'Monthly Ctc'}
                                field={'monthly_ctc'}
                                value={
                                  item?.monthly_ctc
                                    ? monthly_ctc
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              /> } */}
                              <TableFieldComponent
                                label={'Monthly Ctc'}
                                field={'monthly_ctc'}
                                value={
                                  item.monthly_ctc ? item.monthly_ctc : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Annual Ctc'}
                                field={'annual_ctc'}
                                value={item.annual_ctc ? item.annual_ctc : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Special allowance'}
                                field={'special_allowance'}
                                value={
                                  item.special_allowance
                                    ? item.special_allowance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Gross Salary'}
                                field={'gross_salary'}
                                value={
                                  item.gross_salary ? item.gross_salary : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'In Hand Salary'}
                                field={'in_hand_salary'}
                                value={
                                  item.in_hand_salary
                                    ? item.in_hand_salary
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employer Pf'}
                                field={'employer_pf'}
                                value={
                                  item.employer_pf ? item.employer_pf : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employee Pf'}
                                field={'employee_pf'}
                                value={
                                  item.employee_pf ? item.employee_pf : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employer Esic'}
                                field={'employer_esic'}
                                value={
                                  item.employer_esic ? item.employer_esic : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employee Esic'}
                                field={'employee_esic'}
                                value={
                                  item.employee_esic ? item.employee_esic : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employer Insurance'}
                                field={'employer_insurance'}
                                value={
                                  item.employer_insurance
                                    ? item.employer_insurance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'Employee Insurance'}
                                field={'employee_insurance'}
                                value={
                                  item.employee_insurance
                                    ? item.employee_insurance
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'LWF'}
                                field={'lwf'}
                                value={item.lwf ? item.lwf : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'PT'}
                                field={'pt'}
                                value={item.pt ? item.pt : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'TA'}
                                field={'ta'}
                                value={item.ta ? item.ta : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'DA'}
                                field={'da'}
                                value={item.da ? item.da : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'father_dob'}
                                field={'father_dob'}
                                value={item.father_dob ? item.father_dob : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'mother_name'}
                                field={'mother_name'}
                                value={
                                  item.mother_name ? item.mother_name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'mother_dob'}
                                field={'mother_dob'}
                                value={item.mother_dob ? item.mother_dob : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'spouse_name'}
                                field={'spouse_name'}
                                value={
                                  item.spouse_name ? item.spouse_name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'spouse_dob'}
                                field={'spouse_dob'}
                                value={item.spouse_dob ? item.spouse_dob : 'NA'}
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'child_1_name'}
                                field={'child_1_name'}
                                value={
                                  item.child_1_name ? item.child_1_name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'child_1_dob'}
                                field={'child_1_dob'}
                                value={
                                  item.child_1_dob ? item.child_1_dob : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'child_2_name'}
                                field={'child_2_name'}
                                value={
                                  item.child_2_name ? item.child_2_name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'child_2_dob'}
                                field={'child_2_dob'}
                                value={
                                  item.child_2_dob ? item.child_2_dob : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_1_name'}
                                field={'reference_1_name'}
                                value={
                                  item.reference_1_name
                                    ? item.reference_1_name
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_1_employee_code'}
                                field={'reference_1_employee_code'}
                                value={
                                  item.reference_1_employee_code
                                    ? item.reference_1_employee_code
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_1_designation'}
                                field={'reference_1_designation'}
                                value={
                                  item.reference_1_designation
                                    ? item.reference_1_designation
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_1_contact_number'}
                                field={'reference_1_contact_number'}
                                value={
                                  item.reference_1_contact_number
                                    ? item.reference_1_contact_number
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_2_name'}
                                field={'reference_2_name'}
                                value={
                                  item.reference_2_name
                                    ? item.reference_2_name
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_2_employee_code'}
                                field={'reference_2_employee_code'}
                                value={
                                  item.reference_2_employee_code
                                    ? item.reference_2_employee_code
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_2_designation'}
                                field={'reference_2_designation'}
                                value={
                                  item.reference_2_designation
                                    ? item.reference_2_designation
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'reference_2_contact_number'}
                                field={'reference_2_contact_number'}
                                value={
                                  item.reference_2_contact_number
                                    ? item.reference_2_contact_number
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'nominee_name'}
                                field={'nominee_name'}
                                value={
                                  item.nominee_name ? item.nominee_name : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'nominee_relation'}
                                field={'nominee_relation'}
                                value={
                                  item.nominee_relation
                                    ? item.nominee_relation
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'nominee_phone'}
                                field={'nominee_phone'}
                                value={
                                  item.nominee_phone ? item.nominee_phone : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                            <td>
                              <TableFieldComponent
                                label={'nominee_address'}
                                field={'nominee_address'}
                                value={
                                  item.nominee_address
                                    ? item.nominee_address
                                    : 'NA'
                                }
                                required={true}
                                inputType='input'
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <Pagination
                count={total_employees}
                pages={pages}
                loading={employees_loading}
              />
            </div>
          </div>
        </div>
      </section>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setsidebarOpen={setsidebarOpen}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
      />

      {deleteEmployeeID && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setDeleteEmployeeID(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setDeleteEmployeeID(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Alerts />
    </>
  );
};
