import React from 'react';

export const Form17 = ({ formik }) => {
  return (
    <div style={{ marginBottom: '50px' }}>
      <div
        className='text-center text-black font-bold'
        style={{ fontSize: '22px' }}
      >
        Statement
      </div>
      <div>
        <div>
          1. Name of employee in full{' '}
          <input type='text' className='border-bottom' />
        </div>
        <div>
          2. Sex
          <input type='text' className='border-bottom' />
        </div>
        <div>
          3. Religion
          <input type='text' className='border-bottom' />
        </div>
        <div>
          4. Whether unmarried/married/widow/widower
          <input type='text' className='border-bottom' />
        </div>
        <div>
          5. Department/Branch/Section where employed
          <input type='text' className='border-bottom' />
        </div>
        <div>
          6. Post held with Ticket No. or Serial No., if any
          <input type='text' className='border-bottom' />
        </div>
        <div>
          7. Date of appointment
          <input type='text' className='border-bottom' />
        </div>
        <div>8. Permanent address:</div>
        <div>
          Village <input type='text' className='border-bottom' /> Thana{' '}
          <input type='text' className='border-bottom' /> Sub-division{' '}
          <input type='text' className='border-bottom' /> Post Office{' '}
          <input type='text' className='border-bottom' /> District
          <input type='text' className='border-bottom' /> State
          <input type='text' className='border-bottom' />
        </div>
        <div style={{ border: '2px solid #000' }}></div>
        <div className='d-flex justify-content-between mt-3 mb-3'>
          <div>
            <div>
              Place <input type='text' className='border-bottom' />
            </div>
            <div>
              Date: <input type='date' className='border-bottom' />
            </div>
          </div>
          <div>Signature of the Employee</div>
        </div>
        <div style={{ border: '2px solid #000' }}></div>
        <div
          className='text-center text-black font-bold'
          style={{ fontSize: '22px' }}
        >
          Declaration by Witnesses
        </div>
        <div>Nomination signed/thumb-impressed before me</div>
        <div>Name in full and full address of witnesses.</div>
      </div>
    </div>
  );
};
