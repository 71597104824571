import React from 'react';

export const Form12 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-2'>
        <div className='row'>
          <div className='col-md-12'>
            <div>
              <ul class=' list-group-numbered border-bottom border-dark border-2 pb-3'>
                <li class='list-group-item'>
                  Submission of Form-1 is governed by regulations 11 & 12 of ESI
                  (General) Regulations, 1950.
                </li>
                <li class='list-group-item'>
                  “Family” means all or any of the following relatives of an
                  Insured Person namely:-
                </li>
                <li class=''>
                  (i) A spouse (ii) a minor legitimate or adopted child
                  dependant upon the I.P;(iii) a child who is wholly dependant
                  on the earnings of the I.P. and who is (a)receiving education,
                  till he or she attains the age of 21 years (b)an un married
                  daughter; (iv) a child who is infirm by reason of any physical
                  or mental abnormity or injury and is wholly dependant on the
                  earnings of the I.P. so long as the infirmity continues; (v)
                  dependant parents (Please see Section 2 clause 11 of the ESI
                  Act 1948 for details).
                </li>
                <li class='list-group-item'>
                  Identity Card is Non-transferable.
                </li>
                <li class='list-group-item'>
                  Loss of Identity Card be reported to Employer/Branch Manager
                  immediately.
                </li>
                <li class='list-group-item'>
                  Submission of false information attracts penal action under
                  Section 84 of ESI Act, 1948.
                </li>
                <li class='list-group-item'>
                  This form duly filled in must reach the concerned Branch
                  office within 10 days of appointment of an Employee. Delay
                  attracts penal action under Section 85 of the Act, against
                  employer.
                </li>
                <li class='list-group-item'>
                  As an Insured person you and your dependent family members are
                  entitled to full medical care. The other benefits in cash
                  include (1) sickness Benefit (2) Temporary Disablement benefit
                  (3) Permanent disablement Benefit (4) Dependents benefit and
                  (5) Maternity Benefit (incase of women employees subject to
                  fulfillment of contributory conditions.
                </li>
                <li class='list-group-item'>
                  For more details Please Visit website of ESIC at
                  <a href=''> www.esic.nic.in</a> or{' '}
                  <a href=''>www.esickar.gov.in</a> contact Regional office or
                  Branch Office.
                </li>
              </ul>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div style={{ borderBottom: '2px dashed #000' }} className='pb-4'>
              <div className='d-flex justify-content-center'>
                <h6>
                  <u>FOR BRANCH OFFICE USE ONLY</u>
                </h6>
              </div>
              <ul class=' list-group-numbered mt-3'>
                <li class='list-group-item mb-1'>
                  Date of Allotment of Ins. No.
                </li>
                <li class='list-group-item mb-1'>Date of issue of TIC : </li>
                <li class='list-group-item mb-1'>Name/ No. of Disp :</li>
                <li class='list-group-item mb-1'>
                  Whether reciprocal Medical arrangements involved? If yes,
                  please indicate :
                </li>
              </ul>
              <div className='d-flex justify-content-end mt-5'>
                <p>Signature of Branch Manager</p>
              </div>
            </div>
          </div>
          <div className='col-md-12 mt-5'>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark '>S.No.</td>
                    <td className='border border-dark'>Name</td>
                    <td className='border border-dark text-center'>
                      Date of Birth/ Age as on date of filling form
                    </td>
                    <td className='border border-dark text-center'>
                      Relationship with the Employee{' '}
                    </td>
                    <td className='border border-dark text-center' colSpan={2}>
                      Whether residing with him/her?
                    </td>
                    <td className='border border-dark text-center ' colSpan={2}>
                      If’No’, state place of Residence{' '}
                    </td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>1</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>2</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>3</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                  <tr>
                    <td className='border border-dark '>4</td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'>Yes</td>
                    <td className='border border-dark'>NO</td>
                    <td className='border border-dark'>Town</td>
                    <td className='border border-dark'>State</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
