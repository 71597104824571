import { useState } from 'react';
import { api } from '../../domain/api';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../store/actions/alert_action';

export const useCreateClient = () => {
  const dispatch = useDispatch()
  const createClient = async (formData) => {
    try {
      const { data } = await api.post('/clients/add', formData);
      dispatch(setAlert('Client Added Successfully', 'success'));
    } catch (error) {}
  };
  return { createClient };
};

export const useGetAllClients = () => {
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(false);
  const getClients = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/clients');
      setClients(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { getClients, clients, loading };
};

export const useDeleteClient = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const handleDeleteClient = async (id) => {
    setLoading(true);
    try {
      const  {data}  = await api.delete(`/clients/${id}`);
      dispatch(setAlert(`Client ${data.name} Removed Successfully`, 'success'));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { handleDeleteClient, loading };
};

export const useSingleClient = () => {
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const getSingleClient = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/clients/${id}`);
      setClient(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { getSingleClient, client, loading };
};

export const useUpdateClient = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const handleUpdateClient = async (id, formData) => {
    setLoading(true);
    try {
      const { data } = await api.put(`/clients/${id}`, formData);
      dispatch(setAlert(`Client ${data.name} Updated Successfully`, 'success'));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return { handleUpdateClient, loading };
};
