import { Formik } from 'formik';
import React, { useState } from 'react';
import { EmployeeOnboardingForm } from '../../components/clients/EmployeeOnboardingForm';
import * as Yup from 'yup';
import {
  useOnboardEmployee,
  useSingleEmployee,
} from '../../shared/hooks/UseEmployee';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';

export const OnboardingTool = () => {
  const loggedInUser = useCheckAuthenticated();
  console.log('loggedInUser', loggedInUser);
  const params = useParams();
  const [data] = useSingleEmployee(params.id);

  const [updateData] = useOnboardEmployee();
  const { employee } = data;

  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);

  return (
    <div>
      <section className='main-table-form mt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <div className='logo'>
                <Link to='/' className='navbar-brand logo' href='#'>
                  <img src='/logo.png' />
                </Link>
              </div>
              {loggedInUser?.isAuthenticated && (
                <button onClick={() => navigate(-1)}>Go back</button>
              )}

              <div className='d-flex justify-content-between'>
                <h2> User Onboarding </h2>
              </div>
              <h3>
                Joining Date :{' '}
                {moment(employee?.joining_date).format('DD/MMM/YYYY')}
              </h3>
              <h3>Job Location : {employee?.job_location}</h3>
              <hr />
              <h1>
                {' '}
                {employee?.employee_name} (Employee ID :{' '}
                {employee?.employee_number}){' '}
              </h1>
              {employee && (
                <Formik
                  initialValues={{
                    father_name: employee.father_name
                      ? employee.father_name
                      : '',
                    father_dob: employee.father_dob ? employee.father_dob : '',
                    mother_name: employee.mother_name
                      ? employee.mother_name
                      : '',
                    mother_dob: employee.mother_dob ? employee.mother_dob : '',
                    spouse_name: employee.spouse_name
                      ? employee.spouse_name
                      : '',
                    spouse_dob: employee.spouse_dob ? employee.spouse_dob : '',
                    child_1_name: employee.child_1_name
                      ? employee.child_1_name
                      : '',
                    child_1_dob: employee.child_1_dob
                      ? employee.child_1_dob
                      : '',
                    child_2_name: employee.child_2_name
                      ? employee.child_2_name
                      : '',
                    child_2_dob: employee.child_2_dob
                      ? employee.child_2_dob
                      : '',
                    reference_1_name: employee.reference_1_name
                      ? employee.reference_1_name
                      : '',
                    reference_1_employee_code:
                      employee.reference_1_employee_code
                        ? employee.reference_1_employee_code
                        : '',
                    reference_1_designation: employee.reference_1_designation
                      ? employee.reference_1_designation
                      : '',
                    reference_1_contact_number:
                      employee.reference_1_contact_number
                        ? employee.reference_1_contact_number
                        : '',
                    reference_2_name: employee.reference_2_name
                      ? employee.reference_2_name
                      : '',
                    reference_2_employee_code:
                      employee.reference_2_employee_code
                        ? employee.reference_2_employee_code
                        : '',
                    reference_2_designation: employee.reference_2_designation
                      ? employee.reference_2_designation
                      : '',
                    reference_2_contact_number:
                      employee.reference_2_contact_number
                        ? employee.reference_2_contact_number
                        : '',
                    nominee_name: employee.nominee_name
                      ? employee.nominee_name
                      : '',
                    nominee_relation: employee.nominee_relation
                      ? employee.nominee_relation
                      : '',
                    nominee_phone: employee.nominee_phone
                      ? employee.nominee_phone
                      : '',
                    nominee_address: employee.nominee_address
                      ? employee.nominee_address
                      : '',

                    pan_number: employee.pan_number ? employee.pan_number : '',
                    bank_account_number: employee.bank_account_number
                      ? employee.bank_account_number
                      : '',
                    ifsc_code: employee.ifsc_code ? employee.ifsc_code : '',
                    aadhaar_card_number: employee.aadhaar_card_number
                      ? employee.aadhaar_card_number
                      : '',
                    passport_size_photo: employee.passport_size_photo
                      ? employee.passport_size_photo
                      : '',
                    signed_appointment_letter:
                      employee.signed_appointment_letter
                        ? employee.signed_appointment_letter
                        : '',
                    bank_passbook_or_cancelled_cheque:
                      employee.bank_passbook_or_cancelled_cheque
                        ? employee.bank_passbook_or_cancelled_cheque
                        : '',
                    pan_picture: employee.pan_picture
                      ? employee.pan_picture
                      : '',
                    adhar_card_front: employee.adhar_card_front
                      ? employee.adhar_card_front
                      : '',
                    adhar_card_back: employee.adhar_card_back
                      ? employee.adhar_card_back
                      : '',
                    signed_scanned_signature: employee.signed_scanned_signature
                      ? employee.signed_scanned_signature
                      : '',
                    driving_license: employee.driving_license
                      ? employee.driving_license
                      : '',
                    tenth_certificate: employee.tenth_certificate
                      ? employee.tenth_certificate
                      : '',
                    twelfth_certificate: employee.twelfth_certificate
                      ? employee.twelfth_certificate
                      : '',
                    graduation_certificate: employee.graduation_certificate
                      ? employee.graduation_certificate
                      : '',
                    post_graduation_certificate:
                      employee.post_graduation_certificate
                        ? employee.post_graduation_certificate
                        : '',
                    previous_company_experience_letter:
                      employee.previous_company_experience_letter
                        ? employee.previous_company_experience_letter
                        : '',
                    last_three_month_salary_slip:
                      employee.last_three_month_salary_slip
                        ? employee.last_three_month_salary_slip
                        : '',
                    other_client_specific_form_upload:
                      employee.other_client_specific_form_upload
                        ? employee.pother_client_specific_form_upload
                        : '',
                    other_client_specific_form_upload_2:
                      employee.other_client_specific_form_upload_2
                        ? employee.other_client_specific_form_upload_2
                        : '',
                  }}
                  validationSchema={Yup.object({
                    // passport_size_photo: Yup.string().required('Required'),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await updateData(employee._id, values);
                    resetForm();
                    if (redirect) {
                      window.history.back();
                    } else {
                      window.location.href = `/onboarding/employee/${employee._id}/thank-you`;
                    }

                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log('Formik', formik);
                    return (
                      <EmployeeOnboardingForm
                        bank_account_number={employee?.bank_account_number}
                        bank_name={employee?.bank_name}
                        ifsc_code={employee?.ifsc_code}
                        formik={formik}
                        employee={employee}
                        isAuthenticated={loggedInUser?.isAuthenticated}
                        setRedirect={setRedirect}
                      />
                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
