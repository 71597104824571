import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../Form/Form';
import * as Yup from 'yup';
import { useEffect } from 'react';

function TableFieldComponent({ field, value, required, label, inputType }) {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [requiredValidation, setRequiredValidation] = useState(null);

  useEffect(() => {
    if (field) {
      let newValues = {};
      newValues[field] = value;
      setInitialValues(newValues);
    }
  }, [value]);
  useEffect(() => {
    if (required) {
      let requiredValue = {};
      requiredValue[field] = Yup.string().required('Required');
      setRequiredValidation(requiredValue);
    }
  }, [required]);

  return (
    <div>
      {isEditing ? (
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object(requiredValidation)}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              values.email = values.email.toLowerCase();
              setSubmitting(true);

              resetForm();

              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form>
                  <div>
                    <TextInput label={label} name={field} type='text' />
                  </div>

                  <div className='sav'>
                    <i className='fa fa-save save'></i>
                    <i className='fa fa-close dele'></i>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <div
        // onClick={() => setIsEditing(true)}
        >
          {value}
          {/* <i className='fa fa-edit'></i>{' '} */}
        </div>
      )}
    </div>
  );
}

export default TableFieldComponent;
