import React, { useState } from 'react';

function Sidebar({
  sidebarOpen,
  setsidebarOpen,
  selectedEmployee,
  setSelectedEmployee,
}) {
  const ToggleSidebar = () => {
    if (sidebarOpen) {
      setsidebarOpen(false);
      setSelectedEmployee(null);
    } else {
      setsidebarOpen(true);
    }
  };
  return (
    <div
      className={
        sidebarOpen
          ? 'offcanvas offcanvas-end show'
          : 'offcanvas offcanvas-end '
      }
      tabindex='-1'
      id='offcanvasRight'
      aria-labelledby='offcanvasRightLabel'
    >
      {selectedEmployee && (
        <>
          <div className='offcanvas-header'>
            <h5 id='offcanvasRightLabel'>
              Employee: {selectedEmployee.employee_number}
            </h5>
            <button
              onClick={ToggleSidebar}
              type='button'
              className='btn-close text-reset'
              data-bs-dismiss='offcanvas'
              aria-label='Close'
            ></button>
          </div>
          <div className='offcanvas-body'>
            <div className='data-show'>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Employee Number</h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.employee_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Employee Name</h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.employee_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Joining Date</h3>
                </div>

                <div className='data-valus'>
                  <h3> {selectedEmployee.joining_date} </h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3> Date Of Birth</h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.joining_date}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Gender</h3>
                </div>

                <div className='data-valus'>
                  <h3> {selectedEmployee.gender} </h3>
                </div>
              </div>{' '}
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>PAN Number </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.pan_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Marital Status </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.marital_status}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Blood Group </h3>
                </div>

                <div className='data-valus'>
                  <h3> {selectedEmployee.blood_group} </h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Father's Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.father_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Emergency Contact Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.emergency_contact_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Emergency Contact Number </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.emergency_contact_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Bank Account Number </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.bank_account_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>IFSC Code </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.ifsc_code}</h3>
                </div>
              </div>{' '}
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Bank Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.bank_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Salary Payment Mode </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.salary_payment_mode}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Name (As on Aadhaar Card) </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.name_as_on_aadhaar_card}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Aadhaar Card Number </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.aadhaar_card_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Mobile Number </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.mobile_number}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Designation </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.designation}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Location </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.location}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Client Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.client_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>State </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.state}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>District </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.district}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>E Clinic Code </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.e_clinic_code}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Partner Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.partner_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Job Location </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.job_location}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Field Ops Manager Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.field_ops_manager_name}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Field Ops Manager Contact No </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.field_ops_manager_contact_no}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Field Ops Manager Email ID </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.field_ops_manager_email_id}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Branch Name </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.branch_name}</h3>
                </div>
              </div>{' '}
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Emp CSD </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.emp_csd}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Emp CED </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.emp_ced}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Reporting Head </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.reporting_head}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Reporting Head Email ID </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.reporting_head_email_id}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Client Old DOJ </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.client_old_doj}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Communication Email </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.communication_email}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Client Date Of Joining </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.client_date_of_joining}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Zone </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.zone}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Aadhar Link Mobile </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.aadhar_link_mobile}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Status (PAN & AADHAR) Linked </h3>
                </div>

                <div className='data-valus'>
                  <h3>{selectedEmployee.status_pan_and_aadhar}</h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Created By </h3>
                </div>

                <div className='data-valus'>
                  <h3>
                    {selectedEmployee.createdBy
                      ? selectedEmployee.createdBy.name
                      : 'NA'}
                  </h3>
                </div>
              </div>
              <div className='data-show-flex'>
                <div className='data-heading'>
                  <h3>Updated By </h3>
                </div>

                <div className='data-valus'>
                  {/* <h3>
                    {' '}
                    {selectedEmployee.updates &&
                    selectedEmployee.updates.length > 0
                      ? selectedEmployee.createdBy.name
                      : 'NA'}
                  </h3> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Sidebar;
