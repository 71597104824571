import React from 'react';

export const Form6 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center text-center '>
              <h4 className='border border-2 border-dark w-25'>
                FORM 2 (Revised)
              </h4>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <div className='d-flex justify-content-center text-center '>
              <h5 className=' w-75'>
                NOMINATION AND DECLARATION FORM FOR UNEXEMPTED/ EXEMPTED
                ESTABLISHMENTS
              </h5>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <div className='d-flex justify-content-center text-center '>
              <h6 className=' w-50'>
                Declaration and Nomination Form under the Employees’ Provident
                Funds and Employees’ Pension Scheme
              </h6>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
            <p>
              (Paragraphs 33 & 61 (1) of the Employees Provident Fund Scheme,
              1952 and Paragraph 18 of the Employees’ Pension scheme, 1995)
            </p>
          </div>
          <div className='col-md-10'>
            <div className='d-flex mb-3'>
              <label>1. Name (in Block letters) :</label>
              <input
                type='text'
                name='name'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3 '>
              <label>2. Father’s/Husband’s Name :</label>
              <input
                type='text'
                name='husband-name'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3'>
              <label>3. Date of Birth :</label>
              <input
                type='text'
                name='dob'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3'>
              <label>4. Sex :</label>
              <input
                type='text'
                name='dob'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3'>
              <label>5. Marital Status :</label>
              <input
                type='text'
                name='marital'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3'>
              <label>6. Account No. :</label>
              <input
                type='text'
                name='account-number'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
            <div className='d-flex mb-3 '>
              <div>
                <label>7. Address</label>
              </div>
              <div className='ms-5 d-flex justify-content-between w-75'>
                <div className='d-flex '>
                  <label> Permanent :</label>
                  <input
                    type='text'
                    name='address'
                    className='border-bottom border-dotted border-dark border-1 '
                  />
                </div>
                <div className='d-flex '>
                  <label>Temporary :</label>
                  <input
                    type='text'
                    name='address'
                    className='border-bottom border-dotted border-dark border-1 '
                  />
                </div>
              </div>
            </div>
            <div className='d-flex mb-3'>
              <label>8. Date of joining :</label>
              <input
                type='text'
                name='date'
                className='border-bottom border-dotted border-dark border-1 w-75'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-center text-center mb-3'>
              <h4 className=''>PART – A (EPF)</h4>
            </div>
            <div>
              <p>
                I hereby nominate the person(s) /cancel the nomination made by
                me previously and nominate the person(s) mentioned below to
                receive the amount standing to my credit in the Employees’
                Provident Fund in the event of my death :
              </p>
            </div>
            <div className='table-responsive'>
              <table className='table mt-3 border-top border-bottom border-dark'>
                <thead className=''>
                  <tr className=''>
                    <th scope='col' className=''>
                      Name of nominee/nominees
                    </th>
                    <th scope='col' className=''>
                      Address
                    </th>
                    <th scope='col' className=''>
                      Nominee’s relation- ship with the member
                    </th>
                    <th scope='col' className=''>
                      Date of Birth
                    </th>
                    <th scope='col' className=''>
                      Total amount of share of Accumulations in Provident Fund
                      to be paid to each nominee
                    </th>
                    <th scope='col' className=''>
                      If the nominee is a minor,name & relationship & address of
                      the guardian who may recieve the amount during the
                      minority of nominee.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className=''>1</td>
                    <td className=''>2</td>
                    <td className=''>3</td>
                    <td className=''>4</td>
                    <td className=''>5</td>
                    <td className=''>6</td>
                  </tr>
                  <tr>
                    <td className=''>
                      <input type='text' name='nominee-name' />
                    </td>
                    <td className=''>
                      <input type='text' name='address' />
                    </td>
                    <td className=''>
                      <input type='text' name='nominee-relation' />
                    </td>
                    <td className=''>
                      <input type='text' name='dob' />
                    </td>
                    <td className=''>
                      <input type='text' name='shares' />
                    </td>
                    <td className=''>
                      <input type='text' name='minority' />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='mt-3'>
              <ul className='list-group-numbered'>
                <li class='list-group-item'>
                  * Certified that I have no family as defined in para 2(g) of
                  the Employees’ Provident Fund Scheme, 1952 and should I
                  acquire a Family hereafter, the above nomination should be
                  deemed as cancelled.
                </li>
                <li class='list-group-item'>
                  * Certified that my father/mother is/are dependent upon me.
                </li>
              </ul>
            </div>
            <div className='mt-5 d-flex justify-content-end'>
              <h6>Signature or thumb impression of the subscriber </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
