import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from "../Form/Form";
import * as Yup from "yup";
import { UserForm } from "./UserForm";
import { useUpdateCRMUser } from "../../shared/hooks/UseCRMUser";
import { api } from "../../domain/api";

function EditUserComponent({ crmuser }) {
  const [clients,setClients] = useState([])

  const getClients = async () => {
    try {
      const res = await api.get('/clients')
      setClients(res?.data?.clients)
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    getClients()
  },[])
  

  const { updateData } = useUpdateCRMUser();
  return (
    <div>
      <div className="personal-information-box clearfix">
        <div className="main-sec-heading">
          <h1>Edit User</h1>
        </div>

        <Formik
          initialValues={{
            name: crmuser.name,
            phone: crmuser.phone,
            email: crmuser.email,
            role: crmuser.role,
            clients: crmuser?.clients?.map((item) => item.id) || [],
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
            phone: Yup.string().max(10).min(10).required('Required'),

            email: Yup.string().email().required("Required"),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            values.email = values.email.toLowerCase();
            setSubmitting(true);
            await updateData(crmuser._id, values);
            resetForm();

            setSubmitting(false);
          }}
          
        >
          {(formik) => {
            return (
              <UserForm
                hidepassword={true}
                formik={formik}
                clients={clients}
              />
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default EditUserComponent;
