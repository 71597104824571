import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/css/custom.css';
import './assets/css/animate.min.css';

import 'react-tooltip/dist/react-tooltip.css';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';

import 'react-loading-skeleton/dist/skeleton.css';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';

import { Login } from './containers/auth/Login';
import { Home } from './containers/home/Home';
import { links } from './domain/links.enum';
import { Bulk } from './containers/home/Bulk';
import BeforeLoginRoute from './shared/routes/BeforeLoginRoutes';
import { useEffect } from 'react';
import setAuthToken from './domain/setAuthToken';
import { currentUser } from './store/actions/auth_action';
import PrivateRoutes from './shared/routes/PrivateRoutes';
import User from './containers/users/User';
import AddUser from './containers/users/AddUser';
import ViewUser from './containers/users/ViewUser';
import EditUser from './containers/users/EditUser';
import AddEmployee from './containers/employee/AddEmployee';
import EditEmployee from './containers/employee/EditEmployee';
import AddClient from './containers/clients/AddClient';
import { AllClients } from './containers/clients/AllClients';
import EditClient from './containers/clients/EditClient';
import { OnboardingTool } from './containers/onboarding-tool/OnboardingTool';
import { MainEmployeeForm } from './containers/employee/MainEmployeeForm';
import { ThankYou } from './containers/onboarding-tool/ThankYou';
import { VerifyDetails } from './containers/onboarding-tool/VerifyDetails';
import { BulkDelete } from './containers/home/BulkDelete';
import { OnboardingError } from './containers/onboarding-tool/OnboardingError';

function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');

    setAuthToken(token);
    store.dispatch(currentUser());
  }, []);

  return (
    <Provider store={store}>
      <Routes>
        <Route
          path={links.homePage}
          element={
            <BeforeLoginRoute>
              <Login />
            </BeforeLoginRoute>
          }
        />
        <Route
          path={links.login}
          element={
            <BeforeLoginRoute>
              <Login />
            </BeforeLoginRoute>
          }
        />
        <Route
          path={links.bulk}
          element={
            <PrivateRoutes>
              <Bulk />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.bulkDelete}
          element={
            <PrivateRoutes>
              <BulkDelete />
            </PrivateRoutes>
          }
        />
        {/* <Route path={links.login} element={<Login />} /> */}
        <Route
          path={links.dashboard}
          element={
            <PrivateRoutes>
              <Home />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.clients}
          element={
            <PrivateRoutes>
              <AllClients />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.addClient}
          element={
            <PrivateRoutes>
              <AddClient />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.editClient}
          element={
            <PrivateRoutes>
              <EditClient />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.users}
          element={
            <PrivateRoutes>
              <User />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.addUser}
          element={
            <PrivateRoutes>
              <AddUser />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.singleUser}
          element={
            <PrivateRoutes>
              <ViewUser />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.editUser}
          element={
            <PrivateRoutes>
              <EditUser />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.addEmployee}
          element={
            <PrivateRoutes>
              <AddEmployee />
            </PrivateRoutes>
          }
        />
        <Route
          path={links.editEmployee}
          element={
            <PrivateRoutes>
              <EditEmployee />
            </PrivateRoutes>
          }
        />
        <Route path={links.uploadform} element={<MainEmployeeForm />} />
        <Route path={links.onboardingTool} element={<OnboardingTool />} />
        <Route path={links.onboardingError} element={<OnboardingError />} />
        <Route path={links.onboardingToolVerify} element={<VerifyDetails />} />
        <Route path={links.thankYouPage} element={<ThankYou />} />
        {/* <Route component={PageNotFound} /> */}
      </Routes>
    </Provider>
  );
}

export default App;
