import React, { useState } from "react";
import { useCheckAuthenticated, useLogout } from "../../shared/hooks/UseAuth";
import { links } from "../../domain/links.enum";
import { Link } from "react-router-dom";
function Header() {
  const [menuDropdown, setmenuDropdown] = useState(false);
  const { logoutUser } = useLogout();
  const userData = useCheckAuthenticated();
  return (
    <div className="header light">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="header-flex">
              <div className="logo">
                <Link to="/" className="navbar-brand logo" href="#">
                  <img src="/logo.png" />
                </Link>
              </div>
              <div className="menu-dropdown">
                <div className="menu-bar-flex">
                  <div className="menu-team">
                    <ul className="d-flex" style={{ gap: "25px" }}>
                      {userData &&
                        userData.user &&
                        userData.user.role &&
                        userData.user.role === "ADMIN" && (
                          <li>
                            <Link to={links.clients}>Clients</Link>
                          </li>
                        )}
                      {userData &&
                        userData.user &&
                        userData.user.role &&
                        userData.user.role === "ADMIN" && (
                          <li>
                            <Link to={links.users}>Users</Link>
                          </li>
                        )}
                      <li>
                        <Link to={links.addEmployee}>Add Employee</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="" style={{ position: "relative" }}>
                    <div
                      className="user-circle main "
                      onClick={() => {
                        setmenuDropdown(!menuDropdown);
                      }}
                    >
                      SA
                    </div>
                    <div
                      className={
                        menuDropdown ? "dropdown-user show " : "dropdown-user "
                      }
                    >
                      <ul>
                        <li onClick={() => logoutUser()}>
                          <a href="#">
                            <i className="fa fa-sign-out"></i> Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <nav className="navbar navbar-dark   navbar-expand-sm ">
            <a className="navbar-brand logo" href="#">
              <img src="/assets/images/logo.jpg" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar-list-4"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <ul>
              <li>
                <a href="#">homne</a>
              </li>
            </ul>
            <div className="collapse navbar-collapse" id="navbar-list-4">
              <ul className="navbar-nav">
                <li
                  className="nav-item dropdown text-center "
                  style={{ float: 'left' }}
                  onClick={() => {
                    setmenuDropdown(!menuDropdown);
                  }}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <div className="user-circle">hello</div>
                  </a>
                  <div
                    className={
                      menuDropdown
                        ? 'dropdown-menu '
                        : 'dropdown-menu show '
                    }
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <a className="dropdown-item" href="#">
                      Dashboard
                    </a>
                    <a className="dropdown-item" href="#">
                      Edit Profile
                    </a>
                    <a className="dropdown-item" href="#">
                      Log Out
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
