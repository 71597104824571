import React, { useEffect, useState } from 'react';
import Header from '../../components/common/Header';
import {
  useDeleteClient,
  useGetAllClients,
} from '../../shared/hooks/UseClient';
import { Link } from 'react-router-dom';
import Pagination from '../../components/common/Pagination';
import { useCheckAuthenticated } from '../../shared/hooks/UseAuth';
import { Alerts } from '../../components/common/Alerts';

export const AllClients = () => {
  const { getClients, clients, loading } = useGetAllClients();
  const { handleDeleteClient } = useDeleteClient();
  const userData = useCheckAuthenticated()
 

  useEffect(() => {
    getClients();
  }, []);
  
  const [deleteClientID, setDeleteClientID] = useState(null);

  const deleteBtnClicked = () => {
    handleDeleteClient(deleteClientID);
    getClients();
    setDeleteClientID(null);
  };
 

  return (
    <div>
      <Header />
      <section className='main-table-form mt-3'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='d-flex justify-content-between mt-2 mb-3'>
                <h3> All Clients ({clients?.total_clients}) </h3>
                {userData && userData.user && userData.user.role && userData.user.role === "ADMIN" && (
                <div>
                  <Link to='/clients/add' className='btn btn-primary'>
                    Add Client
                  </Link>
                </div>
                )}
              </div>
              <table className='table table-responsive table-sm  table-bordered table-striped  '>
                <thead>
                  <tr>
                    <th> Name </th>
                    {userData && userData.user && userData.user.role && userData.user.role === "ADMIN" && (
                      <th> Action </th>
                    )}
                    
                  </tr>
                </thead>
                <tbody>
                  {clients?.clients?.map((client) => (
                    <tr key={client._id}>
                      <td>{client?.name}</td>
                    {userData && userData.user && userData.user.role && userData.user.role === "ADMIN" && (

                      <td>
                        <Link
                          to={`/clients/${client?._id}/edit`}
                          className='btn btn-sm btn-primary m-1'
                        >
                          <i className='fa fa-eye'></i>
                        </Link>
                        <a
                          className='btn btn-sm btn-danger'
                          onClick={() => setDeleteClientID(client?._id)}
                        >
                          <i className='fa fa-trash'></i>{' '}
                        </a>
                      </td>)
}
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                count={clients?.total_clients}
                pages={clients?.pages}
                loading={clients?.loading}
              />
            </div>
          </div>
        </div>
      </section>
      {deleteClientID && (
        <div className='main-rel-bg'>
          <div class='really-fixed'>
            <div class='really-fixed-child modal-confirm'>
              <div className='modal-content'>
                <div className='modal-header flex-column'>
                  <div className='icon-box'>
                    <i className='fa fa-trash'></i>
                  </div>
                  <h4 className='modal-title w-100'>Are you sure?</h4>
                  <button
                    type='button'
                    className='close'
                    onClick={() => setDeleteClientID(false)}
                  >
                    ×
                  </button>
                </div>
                <div className='modal-body'>
                  <p>
                    Do you really want to delete this record? This process
                    cannot be undone.
                  </p>
                </div>
                <div className='modal-footer justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-secondary'
                    onClick={() => setDeleteClientID(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => deleteBtnClicked()}
                    className='btn btn-danger'
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
         <Alerts />
    </div>
    
  );
};
