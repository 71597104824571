import React from 'react';
import { Link } from 'react-router-dom';
import { links } from '../../domain/links.enum';
export const AuthBreadCrum = ({ title, subtitle }) => {
  return (
    <>
      <section className="breadcrumb-profile" style={{ background: '#f6f9f9' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={links.homePage}>Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item breadcrumb-item-active "
                    aria-current="page"
                  >
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
