import React, { useEffect, useState } from 'react';

import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from '../Form/Form';
import * as Yup from 'yup';
import { UserForm } from './UserForm';
import { useCreateCRMUser } from '../../shared/hooks/UseCRMUser';
import { api } from '../../domain/api';

 function AddUserComponent() {
  const { data, addData } = useCreateCRMUser();
  const [clients,setClients] = useState([])

  const getClients = async () => {
    try {
      const res = await api.get('/clients')
      setClients(res?.data?.clients)
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    getClients()
  },[])

  
  return (
    <div>
      <div className="personal-information-box clearfix">
        <div className="main-sec-heading">
          <h1>Add User</h1>
        </div>

        <Formik
          initialValues={{
            name: '',
            phone: '',
            email: '',
            password: '',
            role:"",
            clients:[],
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
            phone: Yup.string().max(10).min(10).required('Required'),

            email: Yup.string().email().required('Required'),

            password: Yup.string().required('Required'),
            role:Yup.string().required('Required'),
            clients:Yup.array().required('Required')
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            
            values.email = values.email.toLowerCase();
            
            setSubmitting(true);
            await addData(values);
            resetForm();

            setSubmitting(false);
          }}
        >
          {(formik) => {
            
            return <UserForm formik={formik} clients={clients}/>;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddUserComponent;
