import React from 'react';
import { ClientForm } from './ClientForm';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useCreateClient } from '../../shared/hooks/UseClient';
import { useNavigate, useNavigation } from 'react-router-dom';

export const AddClientComponent = () => {
  const navigate = useNavigate();
  const { createClient } = useCreateClient();
  return (
    <div>
      <div className='personal-information-box clearfix'>
        <div className='main-sec-heading'>
          <h1>Add Client</h1>
        </div>
        <Formik
          initialValues={{
            name: '',
            agreement_start_date: '',
            agreement_end_date: '',
            service_type: '',
            document_name: '',
            mark_up_for_additional_invoice: '',
            agreement_attachment: [],
            agreement_signed_by: {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            poc_1: {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            poc_2: {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            poc_3: {
              name: '',
              designation: '',
              email: '',
              phone: '',
            },
            onboarding_fields: {
              pan_number: true,
              bank_account_number: true,
              ifsc_code: true,
              aadhaar_card_number: true,
              passport_size_photo: true,
              signed_appointment_letter: true,
              bank_passbook_or_cancelled_cheque: true,
              pan_picture: true,
              adhar_card_front: true,
              adhar_card_back: true,
              signed_scanned_signature: true,
              driving_license: true,
              tenth_certificate: true,
              twelfth_certificate: true,
              graduation_certificate: true,
              post_graduation_certificate: true,
              previous_company_experience_letter: true,
              last_three_month_salary_slip: true,
              other_client_specific_form_upload: true,
              other_client_specific_form_upload_2: true,
            },
            staffing_mark_up: '',
            one_time_setup_fee: '',
            id_cards: '',
            insurance: '',
            reimbursement_fee: '',
            temp_sourcing_markup: '',
            temp_replacement_period: '',
            temp_payment_terms: '',
            absorption_fee: '',
            perm_sourcing_markup: '',
            perm_replacement_period: '',
            perm_payment_terms: '',
            bgv_crc: '',
            bgv_employment: '',
            bgv_address: '',
            attendance_cycle: '',
            payout_cycle: '',
            inputs_from_client: '',
            draft_invoice_by_teamup_hr: '',
            draft_invoice_approval_by_client: '',
            payment_from_client: '',
            salary_release: '',
            collect_pay_or_funding: '',
            notice_period_client: '',
            notice_period_employee: '',
            associate_contract_duration: '',
            other_1: '',
            other_2: '',
            other_3: '',
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values, 'values');
            setSubmitting(true);
            await createClient(values);
            navigate('/clients');
            resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            console.log('Formik', formik);
            return <ClientForm formik={formik} />;
          }}
        </Formik>
      </div>
    </div>
  );
};
