import React from 'react';

export const TableErrorFiled = ({ value, field, errors }) => {
  return (
    <div
      // className=' badge-trash'
      className={errors && errors.includes(field) ? 'badge-trash' : 'inclass'}
    >
      {value}
    </div>
  );
};
