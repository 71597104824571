import { api } from '../../domain/api';
import {
  GET_EMPLOYEES_STATED,
  GET_EMPLOYEES,
  GET_EMPLOYEES_ENDED,
  ADD_EMPLOYEE_STATED,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_ENDED,
  EDIT_EMPLOYEE_STATED,
  EDIT_EMPLOYEE,
  EDIT_EMPLOYEE_ENDED,
  GET_EMPLOYEE_STATED,
  GET_EMPLOYEE,
  GET_EMPLOYEE_ENDED,
  GET_ALL_EMPLOYEES_STATED,
  GET_ALL_EMPLOYEES,
  GET_ALL_EMPLOYEES_ENDED,
} from '../types/employee_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

export const addEmployee = (formData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_EMPLOYEE_STATED,
    });
    const { data } = await api.post(`/employees/add`, formData);
    dispatch({
      type: ADD_EMPLOYEE,
      payload: data,
    });
    dispatch({
      type: ADD_EMPLOYEE_ENDED,
    });
    dispatch(setAlert('Employee Added Successfully', 'success'));
    window.history.back();
  } catch (error) {
    dispatch({
      type: ADD_EMPLOYEE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getEmployees =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_EMPLOYEES_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(`/employees?&${query}`);

      dispatch({
        type: GET_EMPLOYEES,
        payload: data,
      });
      dispatch({
        type: GET_EMPLOYEES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEES_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getEmployee = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_EMPLOYEE_STATED,
    });
    const { data } = await api.get(`/employees/${id}`);

    dispatch({
      type: GET_EMPLOYEE,
      payload: data,
    });
    dispatch({
      type: GET_EMPLOYEE_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEE_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editEmployee = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_EMPLOYEE_STATED,
    });
    const { data } = await api.put(`/employees/${id}`, formData);
    dispatch({
      type: EDIT_EMPLOYEE,
      payload: data,
    });
    dispatch({
      type: EDIT_EMPLOYEE_ENDED,
    });
    dispatch(setAlert('Employee Updated Successfully', 'success'));
    window.history.back();
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
// export const onboardEmployeeSingleField = (id, formData) => async (dispatch) => {
//   try {
//     dispatch({
//       type: EDIT_EMPLOYEE_STATED,
//     });
//     const { data } = await api.patch(`/employees/${id}`, formData);
//     dispatch({
//       type: EDIT_EMPLOYEE,
//       payload: data,
//     });

//     dispatch({
//       type: EDIT_EMPLOYEE_ENDED,
//     });
//     dispatch(setAlert('Document Successfully submitted', 'success'));
//   } catch (error) {
//     dispatch({
//       type: EDIT_EMPLOYEE_ENDED,
//     });
//     dispatch(handleErrorLocal(error));
//     dispatch(handleError(error));
//   }
// };

export const onboardEmployee = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_EMPLOYEE_STATED,
    });
    const { data } = await api.put(`/employees/${id}/onboarding`, formData);
    dispatch({
      type: EDIT_EMPLOYEE,
      payload: data,
    });

    dispatch({
      type: EDIT_EMPLOYEE_ENDED,
    });
    dispatch(setAlert('Document Successfully submitted', 'success'));
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};

export const changeEmployeeDocumentStatus =
  (id, formData) => async (dispatch) => {
    try {
      const { data } = await api.put(
        `/employees/${id}/verify-documents`,
        formData
      );
      dispatch(getEmployee(id));
      dispatch(setAlert('Document Successfully submitted', 'success'));
    } catch (error) {
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const deleteEmployee = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/employees/${id}`);
    dispatch(setAlert('Employee Deleted Successfully', 'success'));
    dispatch(getEmployees({}));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteBulkEmployee = (formData) => async (dispatch) => {
  try {
    const { data } = await api.post(`/employees/bulk-delete`, formData);
    dispatch(setAlert('Employee Deleted Successfully', 'success'));
    dispatch(getEmployees({}));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllEmployees =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_EMPLOYEES_STATED,
      });
      const { data } = await api.get(
        `/employees/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_EMPLOYEES,
        payload: data,
      });
      dispatch({
        type: GET_ALL_EMPLOYEES_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_EMPLOYEES_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
