import { combineReducers } from 'redux';
import auth_reducer from './auth_reducer';

import alert_reducer from './alert_reducer';
import { bulk_reducer } from './bulk_reducer';
import { employee_reducer } from './employee_reducer';
import { crmuser_reducer } from './crmuser_reducer';
export default combineReducers({
  auth_reducer: auth_reducer,
  alert_reducer: alert_reducer,
  bulk: bulk_reducer,
  employee: employee_reducer,
  crmuser: crmuser_reducer,
});
