import React from 'react';

export const Form10 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 mt-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <div>
                <img
                  src='/assets/images/esic-logo.png'
                  alt='esic-logo'
                  style={{ height: '70px' }}
                />
              </div>
              <div>
                <h5>
                  <u>EMPLOYEES’ STATE INSURANCE CORPORATION </u>
                </h5>
              </div>
              <div>
                <h5>FORM-1</h5>
              </div>
            </div>
            <div className='d-flex justify-content-center text-center'>
              <p className='w-75'>
                To be filled in by the employee after reading instructions
                overleaf. Two Postcard Size photographs are to be attached with
                this form. This form is free of cost.
              </p>
            </div>
          </div>
          <div className='col-md-6 mt-4'>
            <div className='text-center'>
              <p> (A) INSURED PERSON’S PARTICULARS</p>
            </div>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark w-50'>
                      1. Insurance No.
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>
                      2 Name (in block letters)
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>
                      Father’s/Husband’s Name
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>Date of Birth</td>
                    <td className=''>D</td>
                    <td className='border border-dark'>M</td>
                    <td className='border border-dark'>Y</td>
                    <td className='border border-dark text-center'>
                      Marital Status
                    </td>
                    <td className='border border-dark'>M/U/W</td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'></td>
                    <td className=''></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark'></td>
                    <td className='border border-dark text-center'>6.Sex</td>
                    <td className='border border-dark'>M/F</td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>
                      7. Present Address
                      <br />
                      <br />
                      <br />
                      <br />
                      Pin Code ……………………………
                      <br />
                      e-mail address
                    </td>
                    <td className='border border-dark ' colSpan={5}>
                      8. Permanent Address
                      <br />
                      <br />
                      <br />
                      <br />
                      Pin Code ……………………………
                      <br />
                      e-mail address
                    </td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>Branch Office</td>
                    <td className='border border-dark ' colSpan={5}>
                      Dispensary
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='col-md-6 mt-4'>
            <div className='text-center'>
              <p> (b) EMPLOYER’S PARTICULARS</p>
            </div>
            <div>
              <table class='table border border-dark'>
                <tbody>
                  <tr>
                    <td className='border border-dark w-50'>
                      Employer’s Code No.
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>

                  <tr>
                    <td className='border border-dark w-50'>
                      10. Date of Appointment
                    </td>
                    <td className=''>Day</td>
                    <td className='border border-dark'>Month</td>
                    <td className='border border-dark'>Year</td>
                  </tr>
                  <tr>
                    <td className='border border-dark ' colSpan={5}>
                      11. Name & Address of the Employer
                      <br />
                      <br />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className='border border-dark ' colSpan={5}>
                      12. In case of any previous employment please fill up the
                      details as under:-
                    </td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>
                      (a) Previous Ins.No.
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark w-50'>
                      (b) Emplr’s Code No.
                    </td>
                    <td className='border border-dark' colSpan={5}></td>
                  </tr>
                  <tr>
                    <td className='border border-dark ' colSpan={5}>
                      (C) Name & address of the Employer
                      <br />
                      <br />
                      <br />
                      e-mail address
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
