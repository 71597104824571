import React from "react";

import { Formik, Form } from "formik";
import {
  TextArea,
  TextInput,
  SelectBox,
  CheckBox,
  PasswordInput,
} from "../Form/Form";
import * as Yup from "yup";
import { EmployeeFormComponent } from "./EmployeeFormComponent";
import { useCreateEmployee } from "../../shared/hooks/UseEmployee";
import { employee_reducer } from "../../store/reducers/employee_reducer";

function AddEmployeeComponent() {
  const { addData } = useCreateEmployee();
  return (
    <div>
      <div className="personal-information-box clearfix">
        <div className="main-sec-heading">
          <h1>Add Employee</h1>
        </div>

        <Formik
          initialValues={{
            employee_title: "",
            employee_name: "",
            employee_number: "",
            joining_date: "",
            date_of_birth: "",
            gender: "",
            pan_number: "",
            marital_status: "",
            blood_group: "",
            father_name: "",
            emergency_contact_name: "",
            emergency_contact_number: "",
            bank_account_number: "",
            ifsc_code: "",
            bank_name: "",
            salary_payment_mode: "",
            name_as_on_aadhaar_card: "",
            aadhaar_card_number: "",
            mobile_number: "",
            designation: "",
            location: "",
            client_name: "",
            state: "",
            district: "",
            e_clinic_code: "",
            partner_name: "",
            job_location: "",
            field_ops_manager_name: "",
            field_ops_manager_contact_no: "",
            field_ops_manager_email_id: "",
            branch_name: "",
            emp_csd: "",
            emp_ced: "",
            reporting_head: "",
            reporting_head_email_id: "",
            client_old_doj: "",
            communication_email: "",
            client_date_of_joining: "",
            insurance_number: "",
            aadhar_link_mobile: "",
            status_pan_and_aadhar: "",
            pf_number: "",
            sourced_by: "",
            esi_number: "",
            full_basic: "",
            full_hra: "",
            full_conveyance: "",
            full_consolidated_fee: "",
            full_statutory_bonus: "",
            full_cca: "",
            full_work_allowance: "",
            full_other_allowance: "",
            full_medical_reimbursement: "",
            full_leave_travel_allowance: "",
            full_mobile_allowance: "",
            full_child_eduction_allowance: "",
          }}
          validationSchema={Yup.object({
            employee_number: Yup.string().required("Required"),
            da: Yup.number().default(0).optional(),
            pt: Yup.number().default(0).optional(),
            employer_esic: Yup.number().default(0).optional(),
            ta: Yup.number().default(0).optional(),
            lwf: Yup.number().default(0).optional(),
            employee_esic: Yup.number().default(0).optional(),
            employee_pf: Yup.number().default(0).optional(),
            employer_pf: Yup.number().default(0).optional(),
            in_hand_salary: Yup.number().default(0).optional(),
            gross_salary: Yup.number().default(0).optional(),
            special_allowance: Yup.number().default(0).optional(),
            annual_ctc: Yup.number().default(0).optional(),
            employee_insurance: Yup.number().default(0).optional(),
            employer_insurance: Yup.number().default(0).optional(),
            monthly_ctc: Yup.number().default(0).optional(),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // values.email = values.email.toLowerCase();
            
            setSubmitting(true);
            await addData(values);
            // resetForm();
            setSubmitting(false);
          }}
        >
          {(formik) => {
            return <EmployeeFormComponent />;
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AddEmployeeComponent;
