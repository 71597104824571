import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  TextArea,
  TextInput,
  SelectBox,
  PasswordInput,
  FileUpload,
} from '../../components/Form/Form';

import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AddFormInput from '../../components/AddFormInput';
import TableFieldComponent from '../../components/table_field/TableFieldComponent';
import {
  useCreateBulk,
  useDeleteBulk,
  useSaveBulk,
} from '../../shared/hooks/UseBulk';
import { TableErrorFiled } from '../../components/table_field/TableErrorFiled';
import AsyncSelect from 'react-select/async';
export const BulkDelete = (props) => {
  const navigate = useNavigate();
  const { bulk_data, deleteBulkData } = useDeleteBulk();

  return (
    <>
      <section className='main-table-form mt-3'>
        <div className='container-fluid'>
          <div className='row'>
            {(bulk_data && bulk_data.add_bulk_loading) ||
            bulk_data.edit_bulk_loading ? (
              <>
                <div className='col-md-12'>
                  <div className='text-center'>
                    <img
                      src='/loading.gif'
                      style={{ width: '100px', height: '100px' }}
                    />
                    <p>
                      Processing Please wait. It will take 1-2 mins to proceed
                      the data.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <div className='col-md-12'>
                <div>
                  <Formik
                    initialValues={{
                      file: '',
                    }}
                    validationSchema={Yup.object({
                      file: Yup.string().required('Required'),
                    })}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      // loginUser(values);
                      deleteBulkData(values);
                      resetForm();
                      setSubmitting(false);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <div className='login-box'>
                            <div className='logo'>
                              <Link to='/'>
                                <img src='/assets/images/logo.jpg' />
                              </Link>
                            </div>
                            {/* <h3>Log In</h3> */}
                            <p>Please select the file </p>
                            <FileUpload
                              item='file'
                              formik={formik}
                              value={formik.values.file}
                              hideView={true}
                            />

                            <div className='create-button'>
                              <button
                                className='btn primary-btn w-100'
                                type='submit'
                              >
                                Bulk Delete
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>

                {/* <p> {total_students} Records Found </p> */}

                {bulk_data && bulk_data.bulk_message && (
                  <div>
                    <h3> Congratulations </h3>
                    <div>
                      {bulk_data?.bulk_message?.success_data?.length} Deleted.{' '}
                      {bulk_data?.bulk_message?.failed_data?.length} Failed to
                      delete
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
