import React from 'react';

export const Form2 = ({ formik }) => {
  return (
    <div>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='heading bg-dark-subtle px-2'>
              <h6> Employee Demographics </h6>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='mt-3'>
              <h6>Personal Information </h6>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex flex-column mt-2 mb-4'>
              <label htmlFor='name'>Employee Name:</label>
              <div className='d-flex justify-content-around align-items-center'>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='name'
                    className='border-bottom border-dotted border-dark border-1 '
                  />
                  <label>First Name</label>
                </div>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='name'
                    className='border-bottom border-dotted border-dark border-1 '
                  />
                  <label>Middle Name</label>
                </div>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='name'
                    className='border-bottom border-dotted border-dark border-1 '
                  />
                  <label>Last Name</label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Gender:</label>
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='gender'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                  <label>Male / Female</label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Marital Status :</label>
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='marital'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                  <label>Married / Single</label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Date of Birth :</label>
              <div className='d-flex align-items-center'>
                <div className='d-flex flex-column text-center'>
                  <input
                    type='text'
                    name='dob'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                  <label>DD/MM/YYYY</label>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'> Blood Group :</label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='blood-group'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Email ID :</label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='email'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Nationality</label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='nationality'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Emergency Contact Name :</label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='emergency-name'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Emergency Contact No. </label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='emergency-number'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Emergency Contact Relationship:</label>
              <div className='d-flex align-items-center'>
                <div className=''>
                  <input
                    type='text'
                    name='emergency-relationship'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>
                Present / Communication Address with Pin code :
              </label>
              <div className='d-flex align-items-center'>
                <div className='d-flex'>
                  <input
                    type='text'
                    name='address'
                    className='border-bottom border-dotted border-dark border-1 w-100'
                  />
                  <div className='d-flex'>
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='d-flex flex-row mt-2 mb-4'>
              <label htmlFor='name'>Permanent Address with Pin code : </label>
              <div className='d-flex align-items-center'>
                <div className='d-flex '>
                  <input
                    type='text'
                    name='permanent-address'
                    className='border-bottom  border-dark border-1 w-100'
                  />
                  <div className='d-flex'>
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                    <input
                      type='text'
                      name='permanent-address'
                      className='border border-dark border-1'
                      style={{ width: '20px', height: '25px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-md-12 mt-5'>
            <div className='heading '>
              <h6>Family Details :</h6>
            </div>
            <table className='table mt-3 border border-dark'>
              <thead>
                <tr className='bg-dark-subtle'>
                  <th scope='col' className='border border-dark'>
                    Family Member Name
                  </th>
                  <th scope='col' className='border border-dark'>
                    Relationship
                  </th>
                  <th scope='col' className='border border-dark'>
                    Date of birth (DD/MM/YYYY)
                  </th>
                  <th scope='col' className='border border-dark'>
                    Dependent (Yes/No)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='family-name' />
                  </td>
                  <td className='border border-dark'>Father</td>
                  <td className='border border-dark'>
                    <input type='text' name='dob' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='dependent' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='family-name' />
                  </td>
                  <td className='border border-dark'>Mother</td>
                  <td className='border border-dark'>
                    <input type='text' name='dob' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='dependent' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='family-name' />
                  </td>
                  <td className='border border-dark'>Husband / Wife</td>
                  <td className='border border-dark'>
                    <input type='text' name='dob' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='dependent' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='family-name' />
                  </td>
                  <td className='border border-dark'>Child 1</td>
                  <td className='border border-dark'>
                    <input type='text' name='dob' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='dependent' />
                  </td>
                </tr>
                <tr>
                  <td className='border border-dark'>
                    <input type='text' name='family-name' />
                  </td>
                  <td className='border border-dark'>Child 2</td>
                  <td className='border border-dark'>
                    <input type='text' name='dob' />
                  </td>
                  <td className='border border-dark'>
                    <input type='text' name='dependent' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
