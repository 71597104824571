import React from "react";
import { Field, Form } from "formik";
import { TextInput, SelectBox, MultiSelect } from "../Form/Form";

export const UserForm = ({ hidepassword, clients, formik }) => {
  
  return (
    <Form>
      <TextInput label="Name" name="name" type="text" />
      <TextInput label="Phone" name="phone" type="number" />
      <TextInput label="Email" name="email" type="email" />
      {!hidepassword && (
        <TextInput label="password" name="password" type="password" />
      )}
      <SelectBox
        label="Role"
        name="role"
        type="text"
        onBlur={formik.handleBlur}
        value={formik.values.role}
        onChange={formik.handleChange}
      >
        <option value=""></option>
        <option value="ADMIN">Admin</option>
        <option value="USER">User</option>
      </SelectBox>

      {formik.values.role === "USER" && (
        <>
          <label htmlFor="Clients">Clients</label>

          <Field
            name="clients"
            id="clients"
            placeholder="Select clients"
            isMulti={true}
            component={MultiSelect}
            options={clients.map((item) => ({ value: item.id, label: item.name }))}
          />
        </>
      )}

      <div className="save-button">
        <button type="submit" className="btn primary-btn w-100">
          Save & Continue
        </button>
      </div>
    </Form>
  );
};
