import React from 'react';
import { Form } from 'formik';
import { FileUpload, GalleryUpload, SelectBox, TextInput } from '../Form/Form';
export const EmployeeOnboardingForm = ({
  formik,
  bank_account_number,
  bank_name,
  ifsc_code,
  employee,
  isAuthenticated,
  setRedirect,
}) => {
  console.log(employee, 'employee');
  return (
    <Form>
      <div className='row'>
        <div className='card'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-12'>
                <strong>Father Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput label='Father Name' name='father_name' type='text' />
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Father Date of Birth'
                  name='father_dob'
                  type='date'
                />
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-12'>
                <strong>Mother Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput label='Mother Name' name='mother_name' type='text' />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Mother Date of Birth'
                  name='mother_dob'
                  type='date'
                />
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-12'>
                <strong>Spouse Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput label='Spouse Name' name='spouse_name' type='text' />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Spouse Date of Birth'
                  name='spouse_dob'
                  type='date'
                />
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-12'>
                <strong>Child 1 Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Child 1 Name'
                  name='child_1_name'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Child 1 Date of Birth'
                  name='child_1_dob'
                  type='date'
                />
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-12'>
                <strong>Child 2 Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Child 2 Name'
                  name='child_2_name'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Child 2 Date of Birth'
                  name='child_2_dob'
                  type='date'
                />
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-12'>
                <strong>Reference 1 Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Reference 1 Name'
                  name='reference_1_name'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 1 Employee Code'
                  name='reference_1_employee_code'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 1 Designation'
                  name='reference_1_designation'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 1 Contact Number'
                  name='reference_1_contact_number'
                  type='text'
                />
              </div>
              <div className='col-md-8'></div>
              <div className='col-md-12'>
                <strong>Reference 2 Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Reference 2 Name'
                  name='reference_2_name'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 2 Employee Code'
                  name='reference_2_employee_code'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 2 Designation'
                  name='reference_2_designation'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Reference 2 Contact Number'
                  name='reference_2_contact_number'
                  type='text'
                />
              </div>
              <div className='col-md-8'></div>
              <div className='col-md-12'>
                <strong>Nominee Details</strong>
              </div>
              <div className='col-md-4'>
                <TextInput
                  label='Nominee Name'
                  name='nominee_name'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Nominee Relation'
                  name='nominee_relation'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Nominee Phone'
                  name='nominee_phone'
                  type='text'
                />
              </div>

              <div className='col-md-4'>
                <TextInput
                  label='Nominee Address'
                  name='nominee_address'
                  type='text'
                />
              </div>
              <div className='col-md-8'></div>
            </div>
          </div>
        </div>
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.signed_appointment_letter && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Signed Appointment Letter'
                name='signed_appointment_letter'
                formik={formik}
                value={formik.values.signed_appointment_letter}
                item={'signed_appointment_letter'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.passport_size_photo && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title=' Passport size photo'
                name='passport_size_photo'
                formik={formik}
                value={formik.values.passport_size_photo}
                item={'passport_size_photo'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.bank_passbook_or_cancelled_cheque && (
            <div className='col-md-12 border-bottom p-2'>
              <div className='d-flex gap-3'>
                <p className='mb-0 font-weight-bold'>
                  Bank : <span>{bank_name}</span>
                </p>
                <p className='mb-0 font-weight-bold'>
                  IFC CODE : <span>{ifsc_code}</span>
                </p>
                <p className='mb-0 font-weight-bold'>
                  Account No : <span>{bank_account_number}</span>
                </p>
              </div>
              <FileUpload
                title='Bank Passbook / Cancelled Cheque Leaf '
                name='bank_passbook_or_cancelled_cheque'
                formik={formik}
                value={formik.values.bank_passbook_or_cancelled_cheque}
                item={'bank_passbook_or_cancelled_cheque'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.pan_picture && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='PAN'
                name='pan_picture'
                formik={formik}
                value={formik.values.pan_picture}
                item={'pan_picture'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.adhar_card_front && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Aadhar Card Front Side'
                name='adhar_card_front'
                formik={formik}
                value={formik.values.adhar_card_front}
                item={'adhar_card_front'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.adhar_card_back && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Aadhar Card Back Side'
                name='adhar_card_back'
                formik={formik}
                value={formik.values.adhar_card_back}
                item={'adhar_card_back'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.signed_scanned_signature && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Signed scanned signature -'
                name='signed_scanned_signature'
                formik={formik}
                value={formik.values.signed_scanned_signature}
                item={'signed_scanned_signature'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.driving_license && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Driving License'
                name='driving_license'
                formik={formik}
                value={formik.values.driving_license}
                item={'driving_license'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.tenth_certificate && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='10th Certificate'
                name='tenth_certificate'
                formik={formik}
                value={formik.values.tenth_certificate}
                item={'tenth_certificate'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.twelfth_certificate && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='12th Certificate'
                name='twelfth_certificate'
                formik={formik}
                value={formik.values.twelfth_certificate}
                item={'twelfth_certificate'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields?.graduation_certificate && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Graduation Certificate'
                name='graduation_certificate'
                formik={formik}
                value={formik.values.graduation_certificate}
                item={'graduation_certificate'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.post_graduation_certificate && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Post-Graduation Certificate'
                name='post_graduation_certificate'
                formik={formik}
                value={formik.values.post_graduation_certificate}
                item={'post_graduation_certificate'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.previous_company_experience_letter && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Previous Company Experience Letter'
                name='previous_company_experience_letter'
                formik={formik}
                value={formik.values.previous_company_experience_letter}
                item={'previous_company_experience_letter'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.last_three_month_salary_slip && (
            <div className='col-md-12 border-bottom p-2'>
              <GalleryUpload
                title='Last 3 month Salary slip'
                name='last_three_month_salary_slip'
                formik={formik}
                value={formik.values.last_three_month_salary_slip}
                item={'last_three_month_salary_slip'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.other_client_specific_form_upload && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Other Client Specific form upload – 1'
                name='other_client_specific_form_upload'
                formik={formik}
                value={formik.values.other_client_specific_form_upload}
                item={'other_client_specific_form_upload'}
                hideView={true}
              />
            </div>
          )}
        {employee &&
          employee?.client_name &&
          employee?.client_name?.onboarding_fields &&
          employee?.client_name?.onboarding_fields
            ?.other_client_specific_form_upload_2 && (
            <div className='col-md-12 border-bottom p-2'>
              <FileUpload
                title='Other Client Specific form upload - 2'
                name='other_client_specific_form_upload_2'
                formik={formik}
                value={formik.values.other_client_specific_form_upload_2}
                item={'other_client_specific_form_upload_2'}
                hideView={true}
              />
            </div>
          )}
        <div className='col-md-12'>
          <button type='submit' className='btn btn-primary'>
            Save Details
          </button>
          {isAuthenticated && (
            <a
              onClick={() => {
                setRedirect(true);
                formik.handleSubmit();
              }}
              className='btn btn-success'
            >
              Save Details Go to Homepage
            </a>
          )}
        </div>
      </div>
    </Form>
  );
};
