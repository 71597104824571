import { api } from '../../domain/api';
import {
  GET_CRMUSERS_STATED,
  GET_CRMUSERS,
  GET_CRMUSERS_ENDED,
  ADD_CRMUSER_STATED,
  ADD_CRMUSER,
  ADD_CRMUSER_ENDED,
  EDIT_CRMUSER_STATED,
  EDIT_CRMUSER,
  EDIT_CRMUSER_ENDED,
  GET_CRMUSER_STATED,
  GET_CRMUSER,
  GET_CRMUSER_ENDED,
  GET_ALL_CRMUSERS_STATED,
  GET_ALL_CRMUSERS,
  GET_ALL_CRMUSERS_ENDED,
} from '../types/crmuser_type';
import * as qs from 'qs';
import { handleError } from '../../shared/handleError';
import { setAlert } from './alert_action';

export const addCRMUser = (formData) => async (dispatch, getState) => {
  
  try {
    dispatch({
      type: ADD_CRMUSER_STATED,
    });
    const { data } = await api.post(`/crmusers/add`, formData);
    dispatch({
      type: ADD_CRMUSER,
      payload: data,
    });
    dispatch({
      type: ADD_CRMUSER_ENDED,
    });
    dispatch(setAlert('CRM User Added Successfully', 'success'));
    window.location.href = '/users';
  } catch (error) {
    dispatch({
      type: ADD_CRMUSER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getCRMUsers =
  ({ pageNumber = '' }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_CRMUSERS_STATED,
      });
      const queryParams = qs.parse(window.location.search.replace('?', ''));
      const query = qs.stringify(queryParams, {
        encodeValuesOnly: true, // prettify url
      });

      const { data } = await api.get(
        `/crmusers?&pageNumber=${pageNumber}&${query}`
      );

      dispatch({
        type: GET_CRMUSERS,
        payload: data,
      });
      dispatch({
        type: GET_CRMUSERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_CRMUSERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };
export const getCRMUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRMUSER_STATED,
    });
    const { data } = await api.get(`/crmusers/${id}`);

    dispatch({
      type: GET_CRMUSER,
      payload: data,
    });
    dispatch({
      type: GET_CRMUSER_ENDED,
    });
  } catch (error) {
    dispatch({
      type: GET_CRMUSER_STATED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const editCRMUser = (id, formData) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_CRMUSER_STATED,
    });
    const { data } = await api.put(`/crmusers/${id}`, formData);
    dispatch({
      type: EDIT_CRMUSER,
      payload: data,
    });
    dispatch({
      type: EDIT_CRMUSER_ENDED,
    });
    dispatch(setAlert('CRM User Updated Successfully', 'success'));
    window.location.href = '/users';
  } catch (error) {
    dispatch({
      type: EDIT_CRMUSER_ENDED,
    });
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const deleteCRMUser = (id) => async (dispatch) => {
  try {
    const { data } = await api.delete(`/crmusers/${id}`);
    dispatch(setAlert('CRMUser Deleted Successfully', 'success'));
  } catch (error) {
    dispatch(handleErrorLocal(error));
    dispatch(handleError(error));
  }
};
export const getAllCRMUsers =
  ({ term, value }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_CRMUSERS_STATED,
      });
      const { data } = await api.get(
        `/crmusers/all?term=${term}&value=${value}`
      );

      dispatch({
        type: GET_ALL_CRMUSERS,
        payload: data,
      });
      dispatch({
        type: GET_ALL_CRMUSERS_ENDED,
      });
    } catch (error) {
      dispatch({
        type: GET_ALL_CRMUSERS_ENDED,
      });
      dispatch(handleErrorLocal(error));
      dispatch(handleError(error));
    }
  };

export const handleErrorLocal = () => async (dispatch) => {};
